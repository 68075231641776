import React, { useEffect, useState, useCallback } from "react";
import CodeEditorWindow from "./CodeEditorWindow";
import axios from "axios";
import { classnames } from "../../utils/general";
import { languageOptions } from "../../constants/languageOptions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { defineTheme } from "../../lib/defineTheme";
import useKeyPress from "../../hooks/useKeyPress";
import OutputWindow from "./OutputWindow";
import CustomInput from "../CustomInput";
import ThemeDropdown from "../ThemeDropdown";
import LanguagesDropdown from "./LanguagesDropdown";
import QuestionSection from "../QuestionSection";
import { useNavigate } from "react-router-dom";
import TimeUpModal from "../TimeUpModal";
import { FaCheckCircle, FaTimesCircle, FaSpinner,FaArrowLeft, FaArrowRight,FaChevronDown, FaChevronUp, FaArrowsAltH   } from "react-icons/fa";
import { VscLoading } from "react-icons/vsc";
import "../../css/Landing.css";
import FullScreenPrompt from "./FullScreenPrompt";
import BackBtnModal from "../BackBtnModal";
import DOMPurify from 'dompurify';

// Updated IOTabs component with split view for custom input
const IOTabs = ({ activeTab, setActiveTab, customInput, setCustomInput, outputDetails, testCaseResults }) => {
  const hasErrors = outputDetails?.stderr && outputDetails.stderr.trim().length > 0; // Check for stderr

  return (
    <div className="w-full">
      <div className="flex border-b border-gray-200 bg-gray-50">
        <button
          className={`px-4 py-2 font-medium ${
            activeTab === 'custom' ? 'border-b-2 border-blue-500 text-blue-600 bg-white' : 'text-gray-600 hover:bg-gray-100'
          }`}
          onClick={() => setActiveTab('custom')}
        >
          Custom Input/Output
        </button>
        <button
          className={`px-4 py-2 font-medium ${
            activeTab === 'testcases' ? 'border-b-2 border-blue-500 text-blue-600 bg-white' : 'text-gray-600 hover:bg-gray-100'
          }`}
          onClick={() => setActiveTab('testcases')}
        >
          Test Cases
        </button>
        {hasErrors && (
          <button
            className={`px-4 py-2 font-medium ${
              activeTab === 'errors' ? 'border-b-2 border-blue-500 text-blue-600 bg-white' : 'text-gray-600 hover:bg-gray-100'
            }`}
            onClick={() => setActiveTab('errors')}
          >
            Errors
          </button>
        )}
      </div>

      <div className="p-4">
        {activeTab === 'custom' && (
          <div className="flex space-x-4">
            <div className="w-1/2">
              <h3 className="font-medium mb-2">Input:</h3>
              <CustomInput customInput={customInput} setCustomInput={setCustomInput} />
            </div>
            <div className="w-1/2">
              <OutputWindow outputDetails={outputDetails} />
            </div>
          </div>
        )}
        {activeTab === 'testcases' && (
          <div className="test-case-results">
            <h3 className="font-medium mb-2">Test Case Results:</h3>
            <div className="grid grid-cols-2 gap-4">
              {Object.entries(testCaseResults).map(([index, result], idx) => (
                <div key={index} className="flex items-center space-x-2 bg-gray-50 p-3 rounded">
                  <span className="font-medium">{`Test Case ${idx + 1}:`}</span>
                  {result.passed ? (
                    <FaCheckCircle className="text-green-500" />
                  ) : (
                    <FaTimesCircle className="text-red-500" />
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
        {activeTab === 'errors' && hasErrors && (
          <div className="p-4 bg-red-50 border border-red-500 rounded text-red-700">
            <h3 className="font-medium mb-2">Error Output:</h3>
            <pre className="whitespace-pre-wrap">{outputDetails.stderr}</pre>
          </div>
        )}
      </div>
    </div>
  );
};



const pythonDefault = `#Before writing code first select language and remove this line`;

// const pythonDefault = `
// import java.util.Scanner;

// public class Main {
//     public static int factorial(int n) {
//         if (n == 0 || n == 1) {
//             return 1;
//         } else {
//             return n * factorial(n - 1);
//         }
//     }

//     public static void main(String[] args) {
//         Scanner sc = new Scanner(System.in);
//         int num = sc.nextInt();
//         System.out.println(factorial(num));
//     }
// }`;

// num = int(input())

// def factorial(n):
//     if n == 0 or n == 1:
//         return 1
//     else:
//         return n * factorial(n - 1)

// result = factorial(num)
// print(result)

const Landing = () => {
  const [code, setCode] = useState(pythonDefault);
  const [customInput, setCustomInput] = useState("");
  const [outputDetails, setOutputDetails] = useState(null);
  const [processing, setProcessing] = useState(null);
  const [theme, setTheme] = useState({ value: "cobalt", label: "Cobalt" });
  // const [language, setLanguage] = useState(languageOptions[0]);
  const [language, setLanguage] = useState(null);
  const [leftWidth, setLeftWidth] = useState("30%");
  const [testCaseResults, setTestCaseResults] = useState({});
  const [duration, setDuration] = useState(0);
  const [remainingTime, setRemainingTime] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [questionContent, setQuestionContent] = useState(null);
  const [questionTitle, setQuestionTitle] = useState("");

  const [questionId, setQuestionId] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [questionsPerPage] = useState(5); // Number of questions per page
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [totalPages, setTotalPages] = useState(0); // Total number of pages
  const [examTitle, setExamTitle] = useState("");
  const [warningCount, setWarningCount] = useState(0);
   // Add new state for panel controls
   const [isEditorCollapsed, setIsEditorCollapsed] = useState(false);
   const [isIOCollapsed, setIsIOCollapsed] = useState(false);
   const [splitPosition, setSplitPosition] = useState(50);
   const [isDragging, setIsDragging] = useState(false);
   const [activeTab, setActiveTab] = useState('input');
    // Modified split panel logic
  const MIN_SPLIT = 20; // Minimum percentage for either panel
  const MAX_SPLIT = 80; // Maximum percentage for either panel
   

  const navigate = useNavigate();
  // const testId = localStorage.getItem("test id");
  const testId = localStorage.getItem("testId");
  const email = localStorage.getItem("email");

  const getQuestionLanguage = async () => {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/get-language-for-question`,
        {
          test_id: testId,
          question_id: questionId,
        }
      );
      const language = response.data.language;
      const filtered = languageOptions.filter(
        (option) => option.name === language
      );
      setLanguage(filtered[0]);
      console.log("hello", language);
    } catch (error) {
      console.error("error getting language for question", error);
    }
  };

  // const endTest = async () => {
  //   await saveCode();
  //   localStorage.clear();
  //   setShowModal(true);
  // };


  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        alert("Switching tabs is not allowed during the test.");

        setWarningCount((prevCount) => {
          const newCount = prevCount + 1;
          console.log("newCount", newCount);
          if (newCount === 5) {
            // change this count to change no. of warnings
            submitTest();
          }
          return newCount;
        });
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  // code for preventing right click
  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
      // alert("right click not allowed");
    };

    document.addEventListener("contextmenu", handleContextMenu);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  // uncomment later
  // code for preventing cut, copy, paste
  useEffect(() => {
    const handleCopyPaste = (e) => {
      e.preventDefault();
      alert("Copy, cut, and paste actions are disabled during the test.");
    };

    document.addEventListener("copy", handleCopyPaste);
    document.addEventListener("cut", handleCopyPaste);
    document.addEventListener("paste", handleCopyPaste);

    return () => {
      document.removeEventListener("copy", handleCopyPaste);
      document.removeEventListener("cut", handleCopyPaste);
      document.removeEventListener("paste", handleCopyPaste);
    };
  }, []);

  // uncomment later
  // to disable keyboard shortcuts for copy, paste, switch tab
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (
        (e.ctrlKey || e.metaKey) &&
        (e.key === "c" || e.key === "v" || e.key === "x")
      ) {
        e.preventDefault();
      }

      // Disable Ctrl+Tab and Ctrl+Shift+Tab
      if (e.ctrlKey && (e.key === "Tab" || e.key === "Shift")) {
        e.preventDefault();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  // uncomment later
  // alert on some key
  const disableKeys = (e) => {
    const disabledKeys = ["Control", "Alt", "Tab"];

    if (disabledKeys.includes(e.key)) {
      e.preventDefault();
      alert(`The ${e.key} key is disabled during the exam.`);
      setWarningCount((prevCount) => {
        const newCount = prevCount + 1;
        console.log("newCount", newCount);
        // if (newCount === 2) {// change this count to set number of warnings, right now for my work i have set 100 warnings
        if (newCount === 10) {
          submitTest();
        }
        return newCount;
      });
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", disableKeys);

    return () => {
      window.removeEventListener("keydown", disableKeys);
    };
  }, []);
  //--------------------------------------

  // for full screen
  // Prompt the user to use full-screen mode on page mount
  // useEffect(() => {
  //   const handleFullScreenPrompt = () => {
  //     const result = window.confirm(
  //       "Use full screen for the test otherwise you can't attempt the test. Click OK to continue."
  //     );
  //     if (result) {
  //       requestFullScreen();
  //     } else {
  //       handleFullScreenPrompt();
  //     }
  //   };

  //   const requestFullScreen = () => {
  //     if (document.documentElement.requestFullscreen) {
  //       document.documentElement.requestFullscreen();
  //     } else if (document.documentElement.mozRequestFullScreen) {
  //       // Firefox
  //       document.documentElement.mozRequestFullScreen();
  //     } else if (document.documentElement.webkitRequestFullscreen) {
  //       // Chrome, Safari and Opera
  //       document.documentElement.webkitRequestFullscreen();
  //     } else if (document.documentElement.msRequestFullscreen) {
  //       // IE/Edge
  //       document.documentElement.msRequestFullscreen();
  //     }
  //   };

  //   handleFullScreenPrompt();
  // }, []);

  // below code works for other mindset like the test duration is fixed but we can only attempt the test in set time
  // eg. if start time - 10AM, end time - 11AM, duration - 60 min
  // even if user starts test on 10:50, he can take test for 60 minutes

  // useEffect(() => {
  //   axios
  //     .get(`${BASE_URL}/tests/${testId}`)
  //     .then((response) => {
  //       setExamTitle(response.data.test_title);
  //       setDuration(response.data.duration);
  //       const savedRemainingTime = localStorage.getItem("remainingTime");
  //       if (savedRemainingTime) {
  //         setRemainingTime(parseInt(savedRemainingTime));
  //       } else {
  //         setRemainingTime(response.data.duration * 60);
  //       }
  //       // setTotalQuestions(response.data.questions.length);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching test duration:", error);
  //     });
  // }, [testId]);

  // changes done for showing only remaining time to participant if he joins test late
  // eg. if start time - 10AM, end time - 11AM, duration - 60 min
  // if user starts test on 10:50AM then he can attempt the test for only 10 minutes i.e remaining time
  useEffect(() => {
    axios
      .get(`${import.meta.env.VITE_BASE_URL}/tests/${testId}`)
      .then((response) => {
        setExamTitle(response.data.test_title);
        setDuration(response.data.duration);

        const endTime = response.data.end_time;
        const currentTime = new Date().toLocaleTimeString("en-US", {
          hour12: false,
        });

        const [endHour, endMinute, endSecond] = endTime.split(":").map(Number);
        const [currentHour, currentMinute, currentSecond] = currentTime
          .split(":")
          .map(Number);

        // Convert times to seconds since calculations are easier
        const endTimeInSeconds = endHour * 3600 + endMinute * 60 + endSecond;
        const currentTimeInSeconds =
          currentHour * 3600 + currentMinute * 60 + currentSecond;

        let testTimeInSeconds = endTimeInSeconds - currentTimeInSeconds;

        const savedRemainingTime = localStorage.getItem("remainingTime");
        if (savedRemainingTime) {
          setRemainingTime(parseInt(savedRemainingTime));
        } else {
          setRemainingTime(testTimeInSeconds);
        }
        // setTotalQuestions(response.data.questions.length);
      })
      .catch((error) => {
        console.error("Error fetching test duration:", error);
      });
  }, [testId]);

  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTime((prevTime) => {
        if (prevTime === 0) {
          clearInterval(timer);
          submitTest();
          return 0;
        } else {
          const updatedTime = prevTime - 1;
          localStorage.setItem("remainingTime", updatedTime);
          return updatedTime;
        }
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const submitTest = () => {
    axios
      .post(`${import.meta.env.VITE_BASE_URL}/submit-test`, {
          email: localStorage.getItem("email"),
          testId: localStorage.getItem("testId")
       })
      .then((response) => {
        console.log("Test submitted automatically");
        localStorage.clear();
        setShowModal(true);
      });
  };

  const hours = Math.floor(remainingTime / 60 / 60);
  const minutes = Math.floor((remainingTime / 60) % 60);
  const seconds = remainingTime % 60;

  const closeModal = () => {
    setShowModal(false);
    navigate("/thank-you");
  };
  useEffect(() => {
    fetchAllQuestionsContent();
  }, []);

  useEffect(() => {
    if (questions.length > 0) {
      const pages = Math.ceil(questions.length / questionsPerPage);
      setTotalPages(pages);
      setCurrentQuestion(0); // Ensure the first question is loaded
      setQuestionContent(questions[0]?.question_content);
      setQuestionId(questions[0]?.question_id);
      setQuestionTitle(questions[0]?.questionTitle);
    }
  }, [questions, questionsPerPage]);

  useEffect(() => {
    if (questionId) {
      getQuestionLanguage();
      getQuestionCode(questionId);
    }
  }, [questionId]);

  const fetchAllQuestionsContent = async () => {
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/tests/${testId}/questions`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      console.log("Questions fetched:", response.data);

      setQuestions(response.data);
      setTotalQuestions(response.data.length);

      // Set initial question
      if (response.data.length > 0) {
        setQuestionId(response.data[0].question_id);
        setQuestionContent(response.data[0].question_content);
        setCurrentQuestion(0); // Ensure the first question is loaded
      }
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  };

  // const enterPress = useKeyPress("Enter");
  // const ctrlPress = useKeyPress("Control");

  const onSelectChange = (sl) => {
    console.log("selected Option...", sl);
    setLanguage(sl);
  };

  // useEffect(() => {
  //   if (enterPress && ctrlPress) {
  //     console.log("enterPress", enterPress);
  //     console.log("ctrlPress", ctrlPress);
  //     handleCompile();
  //   }
  // }, [ctrlPress, enterPress]);

  const onChange = (action, data) => {
    switch (action) {
      case "code": {
        setCode(data);
        break;
      }
      default: {
        console.warn("case not handled!", action, data);
      }
    }
  };

  // old implementation
  // const handleCompile = async () => {
  //   try {
  //     await saveCode();
  //     setProcessing(true);
  //     const formData = {
  //       code,
  //       customInput,
  //       languageId: language.id,
  //     };

  //     const response = await axios.post(
  //       `${import.meta.env.VITE_BASE_URL}/compile`,
  //       formData
  //     );
  //     console.log("Compilation request sent to backend:", response.data);

  //     const jobId = response.data.jobId;

  //     if (customInput) {
  //       runUserCode(jobId, []);
  //     } else {
  //       fetchTestCases(questionId, jobId);
  //     }
  //   } catch (err) {
  //     console.error("Error compiling code:", err);
  //     setProcessing(false);
  //     showErrorToast();
  //   }
  // };

  // const fetchTestCases = (questionId, jobId) => {
  //   axios
  //     .get(`${import.meta.env.VITE_BASE_URL}/get-all-testcases/${questionId}`)
  //     .then((response) => {
  //       console.log("Test cases retrieved:", response.data);
  //       const testCases = response.data;
  //       runUserCode(jobId, testCases);
  //     })
  //     .catch((err) => {
  //       console.error("Error fetching test cases:", err);
  //       setProcessing(false);
  //       showErrorToast();
  //     });
  // };

  // const runUserCode = (jobId, testCases) => {
  //   if (testCases.length === 0) {
  //     const formData = {
  //       code,
  //       customInput,
  //       languageId: language.id,
  //     };

  //     axios
  //       .post(`${import.meta.env.VITE_BASE_URL}/compile`, formData)
  //       .then(function (response) {
  //         console.log("Compilation request sent to backend:", response.data);
  //         const resultJobId = response.data.jobId;
  //         pollForResult(resultJobId, null);
  //       })
  //       .catch((err) => {
  //         console.error("Error compiling code for custom input:", err);
  //         showErrorToast("Error compiling code for custom input");
  //       });
  //   } else {
  //     testCases.forEach((testCase) => {
  //       const inputToUse = testCase.inputContent;

  //       const formData = {
  //         code,
  //         customInput: inputToUse,
  //         languageId: language.id,
  //       };

  //       axios
  //         .post(`${import.meta.env.VITE_BASE_URL}/compile`, formData)
  //         .then(function (response) {
  //           console.log("Compilation request sent to backend:", response.data);
  //           const resultJobId = response.data.jobId;
  //           pollForResult(resultJobId, testCase);
  //         })
  //         .catch((err) => {
  //           console.error("Error compiling code for test case:", err);
  //           showErrorToast(
  //             `Error compiling code for test case ${testCase.test_case_id}`
  //           );
  //         });
  //     });
  //   }
  // };

  // let errorToastShown = false;

  // const pollForResult = (jobId, testCase) => {
  //   axios
  //     .get(`${import.meta.env.VITE_BASE_URL}/results/${jobId}`, {
  //       params: {
  //         email: localStorage.getItem("email"),
  //         testcaseId: testCase ? testCase.test_case_id : null,
  //         questionId: testCase ? testCase.question_id : null,
  //         points: testCase ? testCase.points : null,
  //         inputContent: testCase ? testCase.inputContent : customInput,
  //         testId: localStorage.getItem("test id"),
  //       },
  //     })
  //     .then((response) => {
  //       console.log("Polling response:", response.data);
  //       if (response.data.status.description === "In Queue") {
  //         setTimeout(() => {
  //           pollForResult(jobId, testCase);
  //         }, 8000);
  //       } else {
  //         setProcessing(false);
  //         const outputDetails = response.data;
  //         compareOutput(outputDetails, testCase);
  //       }
  //     })
  //     .catch((err) => {
  //       console.error("Error polling for result:", err);
  //       if (!errorToastShown) {
  //         showErrorToast("provided code has errors");
  //         errorToastShown = true;
  //       }
  //       setProcessing(false);
  //     });
  // };

  // const compareOutput = (outputDetails, testCase) => {
  //   if (outputDetails && testCase) {
  //     const userOutput = outputDetails.stdout.trim();
  //     const expectedOutput = testCase.outputContent.trim();

  //     console.log("User Output:", userOutput);
  //     console.log("Expected Output:", expectedOutput);

  //     const testCaseResult = {
  //       testCaseId: testCase.test_case_id,
  //       passed: userOutput === expectedOutput,
  //     };

  //     setTestCaseResults((prevResults) => ({
  //       ...prevResults,
  //       [testCase.test_case_id]: testCaseResult,
  //     }));
  //   } else {
  //     setOutputDetails(outputDetails);
  //     showSuccessToast(`Compiled Successfully!`);
  //   }
  // };

  //----------------------------------------------------
  // new implementation
  const handleCompile = async () => {
    try {
      await saveCode(); // Save the code before proceeding
      setProcessing(true);
      const formData = {
        code,
        customInput,
        languageId: language.id,
      };

      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/compile`,
        formData
      );
      console.log("Compilation request sent to backend:", response.data);

      const jobId = response.data.jobId;

      if (customInput) {
        runUserCode(jobId, []); // If custom input, no test cases
      } else {
        fetchTestCases(questionId, jobId); // Otherwise fetch test cases
      }
    } catch (err) {
      console.error("Error compiling code:", err);
      setProcessing(false);
      showErrorToast();
    }
  };

  const fetchTestCases = (questionId, jobId) => {
    axios
      .get(`${import.meta.env.VITE_BASE_URL}/get-all-testcases/${questionId}`)
      .then((response) => {
        console.log("Test cases retrieved:", response.data);
        const testCases = response.data;
        runUserCode(jobId, testCases); // Pass test cases to runUserCode
      })
      .catch((err) => {
        console.error("Error fetching test cases:", err);
        setProcessing(false);
        showErrorToast();
      });
  };

  // Modified runUserCode for parallel execution
  const runUserCode = (jobId, testCases) => {
    if (testCases.length === 0) {
      // No test cases, custom input only
      const formData = {
        code,
        customInput,
        languageId: language.id,
      };

      axios
        .post(`${import.meta.env.VITE_BASE_URL}/compile`, formData)
        .then((response) => {
          console.log("Compilation request sent to backend:", response.data);
          const resultJobId = response.data.jobId;
          pollForResult(resultJobId, null); // Poll for custom input result
        })
        .catch((err) => {
          console.error("Error compiling code for custom input:", err);
          showErrorToast("Error compiling code for custom input");
        });
    } else {
      // Parallel execution of test cases
      const parallelRequests = testCases.map((testCase) => {
        const formData = {
          code,
          customInput: testCase.inputContent,
          languageId: language.id,
        };

        return axios
          .post(`${import.meta.env.VITE_BASE_URL}/compile`, formData)
          .then((response) => {
            console.log("Compilation request sent to backend:", response.data);
            return { resultJobId: response.data.jobId, testCase };
          });
      });

      // Process all test cases in parallel
      Promise.all(parallelRequests)
        .then((results) => {
          results.forEach(({ resultJobId, testCase }) => {
            pollForResult(resultJobId, testCase); // Poll for each test case's result
          });
        })
        .catch((err) => {
          console.error("Error executing test cases in parallel:", err);
          showErrorToast("Error during parallel execution of test cases");
        });
    }
  };

  let errorToastShown = false;

  // Poll for result from backend
  const pollForResult = (jobId, testCase, delay = 2000) => {
    axios
      .get(`${import.meta.env.VITE_BASE_URL}/results/${jobId}`, {
        params: {
          email: localStorage.getItem("email"),
          testcaseId: testCase ? testCase.test_case_id : null,
          questionId: testCase ? testCase.question_id : null,
          points: testCase ? testCase.points : null,
          inputContent: testCase ? testCase.inputContent : customInput,
          testId: localStorage.getItem("testId"),
        },
      })
      .then((response) => {
        const data = response.data;
        console.log("Polling response:", data);
  
        // Check if the status is "In Queue"
        if (data.status.description === "In Queue") {
          let nextDelay = delay < 8000 ? delay * 2 : 8000; // Gradually increase delay
          setTimeout(() => {
            pollForResult(jobId, testCase, nextDelay); // Poll again
          }, nextDelay);
        } else {
          setProcessing(false);
          setOutputDetails(data); // Update outputDetails, including stderr if present
  
          // Check for errors (stderr)
          if (data.stderr && data.stderr.trim().length > 0) {
            console.log("Error detected in response:", data.stderr);
            showErrorToast("Error detected! Check the Errors tab.");
          } else {
            // No errors; show success
            showSuccessToast("Compiled Successfully!");
          }
  
          // Compare output with expected (for test cases only)
          if (testCase) {
            compareOutput(data, testCase);
          }
        }
      })
      .catch((err) => {
        console.error("Error polling for result:", err);
        if (!errorToastShown) {
          showErrorToast("An error occurred while fetching results.");
          errorToastShown = true;
        }
        setProcessing(false);
      });
  };
  
  const compareOutput = (outputDetails, testCase) => {
    if (outputDetails && testCase) {
      const userOutput = outputDetails.stdout.trim();
      const expectedOutput = testCase.outputContent.trim();

      console.log("User Output:", userOutput);
      console.log("Expected Output:", expectedOutput);

      const testCaseResult = {
        testCaseId: testCase.test_case_id,
        passed: userOutput === expectedOutput,
      };

      setTestCaseResults((prevResults) => ({
        ...prevResults,
        [testCase.test_case_id]: testCaseResult,
      }));
    } else {
      setOutputDetails(outputDetails);
      showSuccessToast("Compiled Successfully!");
    }
  };

// Modified navigation handlers to clear results
const clearResults = () => {
  setTestCaseResults({});
  setOutputDetails(null);
  setProcessing(false);
};

const handleNextQuestion = () => {
  if (currentQuestion < questions.length - 1) {
    const nextQuestionIndex = currentQuestion + 1;
    setCurrentQuestion(nextQuestionIndex);
    setQuestionContent(questions[nextQuestionIndex].question_content);
    setQuestionId(questions[nextQuestionIndex].question_id);
    setQuestionTitle(questions[nextQuestionIndex].questionTitle); // Add this line
    if ((nextQuestionIndex % questionsPerPage) === 0) {
      setCurrentPage(currentPage + 1);
    }
    clearResults(); // Clear results when navigating
  }
};

const handlePreviousQuestion = () => {
  if (currentQuestion > 0) {
    const previousQuestionIndex = currentQuestion - 1;
    setCurrentQuestion(previousQuestionIndex);
    setQuestionContent(questions[previousQuestionIndex].question_content);
    setQuestionId(questions[previousQuestionIndex].question_id);
    
    setQuestionTitle(questions[previousQuestionIndex].questionTitle); // Add this line
    if ((previousQuestionIndex % questionsPerPage) === questionsPerPage - 1) {
      setCurrentPage(currentPage - 1);
    }
    clearResults(); // Clear results when navigating
  }
};

  

  // Update handleQuestionClick to navigate within the current page-------------------------------------

  const handleQuestionClick = async (questionIndex) => {
    const absoluteIndex = questionIndex + (currentPage - 1) * questionsPerPage;
    const question = questions[absoluteIndex];
    if (question) {
      try {
        await saveCode();
        setQuestionId(question.question_id); // Update the questionId state
        setQuestionContent(question.question_content);
        setQuestionTitle(question.questionTitle); // Add this line
        setCurrentQuestion(absoluteIndex);
        console.log("Clicked question ID:", question.question_id); // Log the clicked question ID
        setTestCaseResults({}); // Clear test case results
        setOutputDetails(null); // Clear output details
        setProcessing(false); // Reset processing state
        // console.log("Clicked question ID:", questionId);
      } catch (error) {
        console.error("Error saving code:", error);
      }
    } else {
      console.error("No question found at index:", questionIndex);
    }
  };

  // Updated dropdown styles
  const dropdownStyles = {
    select: {
      minWidth: '100px',  // Reduced from 120px
      maxWidth: '120px',  // Reduced from 150px
      height: '20px',     // Reduced from 32px
      fontSize: '0.75rem' // Smaller font size
    },
    container: {
      fontSize: '0.75rem',
      marginRight: '8px'
    }
  };


  const saveCode = async () => {
    const testId = localStorage.getItem("testId");
    const email = localStorage.getItem("email");

    const codeToSave = code === "" ? " " : code;

    const payload = {
      test_id: testId,
      email: email,
      question_id: questionId,
      code: codeToSave,
    };

    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/save-code`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("Code saved successfully");
      } else {
        console.error("Error saving code:", response.data.error);
      }
    } catch (error) {
      console.error("Error saving code:", error);
      throw error;
    }
  };

  const getQuestionCode = async (questionId) => {
    try {
      const testId = localStorage.getItem("testId");
      const email = localStorage.getItem("email");
      console.log("questionId => ", questionId);

      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/get-code`,
        {
          test_id: testId,
          email: email,
          question_id: questionId,
        }
      );

      if (response.status === 200 && response.data.code) {
        setCode(response.data.code);
      } else {
        console.error("Error fetching code:", response.data.error);
        setCode(pythonDefault); // Clear code or set to default if not found
      }
    } catch (error) {
      console.error("error getting code", error);
      setCode(pythonDefault); // Clear code or set to default in case of error
    }
  };

  // -----------------------------------------------------------------------------------
  const handleThemeChange = async (selectedTheme) => {
    console.log("Selected Theme:", selectedTheme);
    try {
      await defineTheme(selectedTheme.value); // Wait for the theme to load in Monaco Editor
      console.log(`Theme ${selectedTheme.value} applied successfully.`);
      setTheme(selectedTheme); // Update the theme state after successful application
    } catch (error) {
      console.error("Failed to define theme:", error);
    }
  };
  
  // Modify the useEffect for initial theme
  useEffect(() => {
    const initializeTheme = async () => {
      try {
        await defineTheme("oceanic-next");
        setTheme({ value: "oceanic-next", label: "Oceanic Next" });
      } catch (error) {
        console.error("Failed to initialize theme:", error);
      }
    };
  
    initializeTheme();
  }, []);

  const showSuccessToast = (msg) => {
    toast.success(msg || `Compiled Successfully!`, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const showErrorToast = (msg, timer) => {
    toast.error(msg || `Something went wrong! Please try again.`, {
      position: "top-right",
      autoClose: timer ? timer : 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  // Updated resize handling logic
  const handleMouseDown = useCallback((e) => {
    e.preventDefault();
    setIsDragging(true);
  }, []);

  const handleMouseMove = useCallback((e) => {
    if (!isDragging) return;

    const container = document.getElementById('split-container');
    if (!container) return;

    const containerRect = container.getBoundingClientRect();
    const newPosition = ((e.clientX - containerRect.left) / containerRect.width) * 100;

    // Constrain the position between MIN_SPLIT and MAX_SPLIT
    const constrainedPosition = Math.min(Math.max(newPosition, MIN_SPLIT), MAX_SPLIT);
    setSplitPosition(constrainedPosition);
  }, [isDragging, MIN_SPLIT, MAX_SPLIT]);

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
  }, []);

  // Add and remove event listeners
  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, handleMouseMove, handleMouseUp]);

  const endTest = async () => {
    const confirmEnd = window.confirm(
      "Are you sure you want to end the test? Your remaining time will not be saved."
    );

    if (confirmEnd) {
      await saveCode();
      submitTest();
    }
  };

  const navigateToNextQuestion = () => {
    console.log("Current Question Index (before):", currentQuestion);
    // console.log("questionId", currentQuestion.question_id);
    console.log("Total Questions:", questions.length);

    if (currentQuestion < questions.length - 1) {
      const nextQuestionIndex = currentQuestion + 1;
      console.log("Next Question Index:", nextQuestionIndex);
      setCurrentQuestion(nextQuestionIndex);
      setQuestionContent(questions[nextQuestionIndex].question_content);
      setQuestionId(questions[nextQuestionIndex].question_id);
      setTestCaseResults({}); // Clear test case results
      setOutputDetails(null); // Clear output details
      setProcessing(false); // Reset processing state
      localStorage.setItem("currentQuestion", nextQuestionIndex);
    }
  };

  const navigateToPreviousQuestion = () => {
    console.log("Current Question Index (before):", currentQuestion);

    if (currentQuestion > 0) {
      const previousQuestionIndex = currentQuestion - 1;
      console.log("Previous Question Index:", previousQuestionIndex);
      setCurrentQuestion(previousQuestionIndex);
      setQuestionContent(questions[previousQuestionIndex].question_content);
      setQuestionId(questions[previousQuestionIndex].question_id);
      localStorage.setItem("currentQuestion", previousQuestionIndex);
    }
  };

  return (
    <>
      {!showModal && <FullScreenPrompt />}
      <BackBtnModal />
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {showModal && <TimeUpModal onClose={closeModal} />}
      
      <div className="h-screen flex flex-col overflow-hidden" style={{ display: showModal ? "none" : "block" }}>
        {/* Header */}
        <div className="header bg-blue-600 text-white p-4 flex justify-between items-center">
          <h1 className="text-2xl font-bold text-white">{examTitle}</h1>
          <div className="flex items-center space-x-4">
            <button
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
              onClick={endTest}
            >
              End Test
            </button>
            <div className="remaining-time small-timer">
              <pre>{hours}:{minutes}:{seconds}</pre>
            </div>
          </div>
        </div>

        {/* Question Navigation */}
        <div className="question-navigation bg-gray-200 p-2 flex items-center justify-between">
      <div className="flex items-center space-x-4 flex-1">
        <div className="flex items-center space-x-2">
          <button 
            onClick={handlePreviousQuestion} 
            disabled={currentQuestion === 0}
            className="p-2 rounded hover:bg-gray-200 disabled:opacity-50"
          >
            <FaArrowLeft />
          </button>
          <div className="flex space-x-2">
            {questions
              .slice((currentPage - 1) * questionsPerPage, currentPage * questionsPerPage)
              .map((question, index) => {
                const absoluteIndex = index + (currentPage - 1) * questionsPerPage;
                return (
                  <button
                    key={index}
                    className={`w-8 h-8 rounded-full ${
                      currentQuestion === absoluteIndex
                        ? "bg-blue-600 text-white"
                        : "bg-gray-200 hover:bg-gray-300"
                    }`}
                    onClick={() => handleQuestionClick(index)}
                  >
                    {absoluteIndex + 1}
                  </button>
                );
              })}
          </div>
          <button 
            onClick={handleNextQuestion} 
            disabled={currentQuestion === questions.length - 1}
            className="p-2 rounded hover:bg-gray-200 disabled:opacity-50"
          >
            <FaArrowRight />
          </button>
        </div>
        <div className="flex-1" /> {/* Spacer */}
        <button
          onClick={handleCompile}
          disabled={!code}
          className={`px-6 py-2 rounded-md transition duration-200 ${
            !code ? "opacity-50" : "bg-blue-500 text-white font-bold hover:bg-blue-600"
          }`}
        >
          {processing ? (
            <div className="flex items-center">
              <VscLoading className="animate-spin mr-2" />
              Processing...
            </div>
          ) : (
            "Run Code"
          )}
        </button>
      </div>
    </div>

     {/* Main Content Area */}
     <div className="flex-1 flex flex-col overflow-hidden">
          <div id="split-container" className="flex h-full relative">
            {/* Left Panel - Question Section */}
            <div 
              className="overflow-y-auto transition-all duration-300"
              style={{ 
                width: `${splitPosition}%`, 
                minWidth: `${MIN_SPLIT}%`, 
                maxWidth: `${MAX_SPLIT}%` 
              }}
            >
              <QuestionSection 
                questionTitle={questionTitle} 
                questionContent={questionContent} 
              />
            </div>

            {/* Resizer Handle */}
            <div
              className="w-2 cursor-col-resize flex items-center justify-center absolute top-0 bottom-0 bg-gray-300 hover:bg-gray-400 active:bg-gray-500"
              style={{
                left: `${splitPosition}%`,
                transform: 'translateX(-50%)',
                zIndex: 10,
                touchAction: 'none'
              }}
              onMouseDown={handleMouseDown}
              onTouchStart={handleMouseDown}
            >
              <div className="h-8 w-1 bg-gray-400 rounded-full" />
            </div>

            {/* Right Panel - Editor and IO */}
            <div 
              className="flex flex-col transition-all duration-300"
              style={{ 
                width: `${100 - splitPosition}%`,
                marginLeft: '8px',
                minWidth: `${MIN_SPLIT}%`,
                maxWidth: `${MAX_SPLIT}%`
              }}
            >
              {/* Code Editor Header */}
              <div className="border-b border-gray-300 p-2">
                <div className="bg-white rounded-lg shadow-sm mb-2">
                  <div className="flex items-center justify-between p-2">
                    <div className="flex items-center space-x-2">
                      <span className="font-medium text-sm">Code Editor</span>
                      <div className="flex items-center space-x-2">
                        <LanguagesDropdown
                          onSelectChange={onSelectChange}
                          language={language}
                          questionId={questionId}
                          styles={dropdownStyles.select}
                        />
   <ThemeDropdown
                          handleThemeChange={handleThemeChange}
                          theme={theme}
                          styles={dropdownStyles.select}
                        />

                      </div>
                    </div>
                    <button
                      onClick={() => setIsEditorCollapsed(!isEditorCollapsed)}
                      className="p-1 hover:bg-gray-100 rounded"
                    >
                      {isEditorCollapsed ? <FaChevronDown size={14} /> : <FaChevronUp size={14} />}
                    </button>
                  </div>
                </div>
                
                {/* Code Editor Window */}
                <div className={`transition-all duration-300 ${isEditorCollapsed ? 'h-0' : 'h-[35vh]'}`}>
                  {!isEditorCollapsed && (
                    <CodeEditorWindow
                      code={code}
                      onChange={onChange}
                      language={language?.value}
                      theme={theme.value}
                    />
                  )}
                </div>
              </div>


           
              
             {/* IO Tabs Section */}
  <div className="flex-1">
    <div className="border-t border-gray-300">
      <div className="bg-white rounded-lg shadow-sm">
        <div className="flex items-center justify-between p-2">
          {/* <span className="font-medium text-sm">Input/Output</span> */}
          <button
            onClick={() => setIsIOCollapsed(!isIOCollapsed)}
            className="p-1 hover:bg-gray-100 rounded"
          >
            {isIOCollapsed ? <FaChevronDown size={14} /> : <FaChevronUp size={14} />}
          </button>
        </div>
      </div>
      
      <div className={`transition-all duration-300 ${isIOCollapsed ? 'h-0 overflow-hidden' : 'h-[calc(60vh-64px)]'}`}>
        {!isIOCollapsed && (
          <IOTabs
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            customInput={customInput}
            setCustomInput={setCustomInput}
            outputDetails={outputDetails}
            testCaseResults={testCaseResults}
          />
        )}
      </div>
    </div>
  </div>
            </div>
          </div>
        </div>

      
      </div>
        {/* Footer */}
        <div className="footer bg-gray-800 text-white p-4 flex justify-between items-center">
          <p className="text-sm">
            Need Help? Contact us (please add country code while dialing)
            <span role="img" aria-label="Indian flag">🇮🇳</span>
            +91 9175046101
          </p>
          <p className="text-sm">Powered by CirrusCypher</p>
        </div>
    </>
  );
};

export default Landing;