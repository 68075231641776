import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../css/PaymentComponent.css";
import Navbar from "./Navbar";
import { FiFileText, FiCheckSquare, FiCode, FiUsers } from "react-icons/fi";
import { showNotification } from "../utils/notification";
import SubscriptionDetails from "./SubscriptionDetails";
import SubFooter from "../components/Home/SubFooter";

function PaymentComponent() {
  const [loadingPlanId, setLoadingPlanId] = useState(null);
  const [plans, setPlans] = useState([]);
  const [activePlanId, setActivePlanId] = useState(null);
  const [isAnnual, setIsAnnual] = useState(true);

  const navigate = useNavigate();
  const user_id = localStorage.getItem("user_id");
  const token = localStorage.getItem("token");
  const username = localStorage.getItem("username");

  useEffect(() => {
    fetchPublicPlans();
    if (token) {
      getActivePlanId();
    }
  }, []);

  const fetchPublicPlans = async () => {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/api/payment/fetch-plans`
      );
      const sortedPlans = response.data.data.sort(
        (a, b) => b.max_tests - a.max_tests
      );
      setPlans(sortedPlans);
    } catch (error) {
      console.error("Error fetching plans", error);
    }
  };

  const getActivePlanId = async () => {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/get-active-plan-id`,
        { user_id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setActivePlanId(response.data.plan_id);
    } catch (error) {
      console.error("Error getting active plan", error);
    }
  };

  const handleBuyPlan = async (plan) => {
    const { plan_id, plan_name, monthly_price, annual_price } = plan;
  
    if (!token) {
      showNotification("error", "Please login first");
      navigate("/login");
      return;
    }
  
    setLoadingPlanId(plan_id);
  
    try {
      const period = isAnnual ? "yearly" : "monthly";
      const amount = isAnnual ? annual_price : monthly_price;
  
      // Step 1: Fetch Razorpay Plan Data
      const razorpayPlanResponse = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/api/payment/get-razopay-plan-data`,
        { plan_name, amount, period },
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      if (!razorpayPlanResponse.data.success) {
        throw new Error(razorpayPlanResponse.data.error || "Failed to get Razorpay plan data");
      }
  
      const { id, razorpay_plan_id } = razorpayPlanResponse.data.data;
  
      // Step 2: Create Customer
      const customerResponse = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/api/payment/create-customer`,
        { user_id },
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      if (!customerResponse.data.success) {
        throw new Error(customerResponse.data.error || "Failed to create/retrieve customer");
      }
  
      const razorpay_customer_id = customerResponse.data.razorpay_customer_id;
  
      if (!razorpay_customer_id) {
        throw new Error("Razorpay customer ID is undefined. Check createCustomer API response.");
      }
  
      console.log("Razorpay Customer ID:", razorpay_customer_id);
  
      // Step 3: Create Subscription
      const subscriptionResponse = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/api/payment/create-subscription`,
        {
          id, // Razorpay Plan ID PK
          razorpay_plan_id,
          user_id,
          razorpay_customer_id,
          plan_id,
          amount,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      if (!subscriptionResponse.data.success) {
        throw new Error(subscriptionResponse.data.error || "Failed to create subscription");
      }
  
      const { subscription_id, payment_id } = subscriptionResponse.data.data;
  
      console.log("Subscription Created Successfully:", subscriptionResponse.data);
  
      // Step 4: Proceed with Razorpay Payment Flow
      const options = {
        key: import.meta.env.VITE_RAZORPAY_KEY_ID,
        subscription_id: subscription_id,
        amount: amount * 100, // Convert to paise
        currency: "INR",
        name: "Code Editor",
        description: `Subscription for ${plan_name} Plan`,
        handler: async function (response) {
          try {
            const verifyResponse = await axios.post(
              `${import.meta.env.VITE_BASE_URL}/api/payment/verify-payment`,
              {
                user_id,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_subscription_id: subscription_id,
                razorpay_signature: response.razorpay_signature,
                payment_id,
                plan_id: plan_id,
                id,
              },
              { headers: { Authorization: `Bearer ${token}` } }
            );
  
            if (verifyResponse.data.success) {
              showNotification("success", "Payment Successful! Your plan is now active.");
              navigate("/user/dashboard");
            } else {
              throw new Error(verifyResponse.data.message || "Payment verification failed");
            }
          } catch (verifyError) {
            console.error("Payment verification error", verifyError);
            showNotification("error", "Payment verification failed. Please contact support.");
          }
        },
        prefill: {
          name: username,
          email: "user@example.com",
          contact: "9999999999",
        },
        theme: {
          color: "#F37254",
        },
        modal: {
          ondismiss: () => {
            showNotification("info", "Payment cancelled.");
            setLoadingPlanId(null);
          },
        },
        notes: {
          source: "CirrusCypher",
          org_id: user_id,
          org_name: username,
        },
      };
  
      const rzp = new window.Razorpay(options);
      rzp.open();
  
      rzp.on("payment.failed", function (response) {
        console.error("Payment failed", response);
        showNotification("error", "Payment failed. Please try again.");
        setLoadingPlanId(null);
      });
    } catch (error) {
      console.error("Error during payment process", error);
      showNotification(
        "error",
        error.message || "An error occurred during the payment process."
      );
    } finally {
      setLoadingPlanId(null);
    }
  };
  
  

  const handleUpgradePlan = async (plan) => {
    const { plan_id, plan_name, monthly_price, annual_price } = plan;

    if (!token) {
      showNotification("error", "Please login first");
      navigate("/login");
      return;
    }

    setLoadingPlanId(plan_id);

    try {
      const period = isAnnual ? "yearly" : "monthly";
      const amount = isAnnual ? annual_price : monthly_price;

      const orderResponse = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/api/payment/create-razorpay-order`,
        {
          plan_type: period === "yearly" ? "Yearly" : "Monthly",
          user_id,
          plan_id,
          amount,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!orderResponse.data.success) {
        throw new Error(orderResponse.data.error || "Failed to create order");
      }

      const { razorpay_order_id, new_payment_id } = await orderResponse.data;

      const options = {
        key: import.meta.env.VITE_RAZORPAY_KEY_ID,
        order_id: razorpay_order_id,
        amount: amount * 100,
        currency: "INR",
        name: "Code Editor",
        description: `Upgrade to ${plan_name} Plan`,
        handler: async function (response) {
          try {
            const verifyResponse = await axios.post(
              `${import.meta.env.VITE_BASE_URL}/api/payment/verify-upgrade-plan-payment`,
              {
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_order_id: response.razorpay_order_id,
                razorpay_signature: response.razorpay_signature,
                new_payment_id,
                plan_type: period === "yearly" ? "Yearly" : "Monthly",
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );

            if (verifyResponse.data.success) {
              await cancelExistingSubscription(plan_id, period);
            } else {
              throw new Error(
                verifyResponse.data.message || "Upgrade verification failed"
              );
            }
          } catch (verifyError) {
            console.error("Upgrade verification error", verifyError);
            showNotification(
              "error",
              "Upgrade verification failed. Please contact support."
            );
          }
        },
        prefill: {
          name: username,
          email: "user@example.com",
          contact: "9999999999",
        },
        theme: {
          color: "#F37254",
        },
        modal: {
          ondismiss: () => {
            showNotification("info", "Upgrade cancelled.");
            setLoadingPlanId(null);
          },
        },
        notes: {
          source: "CirrusCypher",
          org_id: user_id,
          org_name: username,
        },
      };

      const rzp = new window.Razorpay(options);
      rzp.open();

      rzp.on("payment.failed", function (response) {
        console.error("Upgrade payment failed", response);
        showNotification("error", "Upgrade payment failed. Please try again.");
        setLoadingPlanId(null);
      });
    } catch (error) {
      console.error("Error during upgrade process", error);
      showNotification(
        "error",
        error.message || "An error occurred during the upgrade process."
      );
    } finally {
      setLoadingPlanId(null);
    }
  };

  const cancelExistingSubscription = async (plan_id, period) => {
    try {
      const cancelResponse = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/api/payment/cancel-subscription`,
        { user_id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (cancelResponse.data.success) {
        await createUpgradePlanSubscription(plan_id, period);
      } else {
        throw new Error("Failed to cancel subscription");
      }
    } catch (error) {
      console.error("Subscription cancellation error", error);
      showNotification("error", "Failed to cancel subscription.");
    }
  };

  const createUpgradePlanSubscription = async (plan_id, period) => {
    try {
      const createResponse = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/api/payment/create-upgrade-plan-subscription`,
        {
          user_id,
          plan_id,
          plan_type: period === "yearly" ? "Yearly" : "Monthly",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (createResponse.data.success) {
        showNotification("success", "Plan upgraded successfully!");
        navigate("/user/dashboard");
      } else {
        throw new Error("Failed to create upgrade plan subscription");
      }
    } catch (error) {
      console.error("Error creating upgrade plan subscription", error);
      showNotification("error", "Failed to create upgrade plan subscription.");
    }
  };

  const handleFreePlanClick = async () => {
    if (!token) {
      showNotification("error", "Please login first.");
      navigate("/login");
      return;
    }

    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/api/payment/handle-free-plan-click`,
        { user_id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        navigate("/user/dashboard");
      } else {
        throw new Error(response.data.error || "Failed to process free plan.");
      }
    } catch (error) {
      console.error("Error handling free plan click", error);
      showNotification(
        "error",
        "An error occurred while processing the free plan."
      );
    }
  };

  const renderButton = (plan) => {
    const { plan_id, plan_name, max_tests } = plan;

    if (!token) {
      return (
        <button
          onClick={() => navigate("/login")}
          className={`plan-button ${
            plan_name === "Free" ? "free-plan-btn" : "buy-plan-btn"
          }`}
        >
          {plan_name === "Free" ? "Start Free" : "Buy Plan"}
        </button>
      );
    }

    if (plan_name === "Free") {
      return (
        <button
          onClick={handleFreePlanClick}
          className={`plan-button free-plan-btn ${
            activePlanId ? "disabled" : ""
          }`}
          disabled={activePlanId}
        >
          {activePlanId ? "Plan Unavailable" : "Continue with Free Plan"}
        </button>
      );
    }

    // Check if the active plan is the free plan
    if (activePlanId && activePlanId !== plan_id) {
      const activePlan = plans.find((p) => p.plan_id === activePlanId);

      if (activePlan && activePlan.plan_name === "Free") {
        // Show "Buy Plan" for other plans when the active plan is "Free"
        return (
          <button
            onClick={() => handleBuyPlan(plan)}
            disabled={loadingPlanId === plan_id}
            className={`plan-button buy-plan-btn ${
              loadingPlanId === plan_id ? "loading" : ""
            }`}
          >
            {loadingPlanId === plan_id ? "Processing..." : "Buy Plan"}
          </button>
        );
      } else if (activePlan && max_tests > activePlan.max_tests) {
        // Show "Upgrade Plan" if the current plan is upgradable
        return (
          <button
            onClick={() => handleUpgradePlan(plan)}
            className="plan-button buy-plan-btn"
            disabled={loadingPlanId === plan_id}
          >
            {loadingPlanId === plan_id ? "Processing..." : "Upgrade Plan"}
          </button>
        );
      } else {
        return (
          <button className="plan-button buy-plan-btn disabled" disabled>
            Plan Unavailable
          </button>
        );
      }
    }

    return (
      <button
        onClick={() => handleBuyPlan(plan)}
        disabled={loadingPlanId === plan_id}
        className={`plan-button buy-plan-btn ${
          loadingPlanId === plan_id ? "loading" : ""
        }`}
      >
        {loadingPlanId === plan_id ? "Processing..." : "Buy Plan"}
      </button>
    );
  };

  // Render SubscriptionDetails only if the user is logged in
  const renderSubscriptionDetails = () => {
    if (token) {
      return <SubscriptionDetails />;
    }
    return null;
  };

  return (
    <div className="payment-container" style={{ minHeight: "100vh" }}>
      <Navbar />
      {renderSubscriptionDetails()} {/* Only show when logged in */}
      <div className="pricing-toggle">
        <div
          className={`toggle-option ${!isAnnual ? "active-option" : ""}`}
          onClick={() => setIsAnnual(false)}
        >
          Monthly
        </div>
        <div
          className={`toggle-option ${isAnnual ? "active-option" : ""}`}
          onClick={() => setIsAnnual(true)}
        >
          Annual
        </div>
      </div>
      <div className="plan-cards-container">
        {plans.length === 0 ? (
          <p>Loading plans...</p>
        ) : (
          plans.map((plan) => (
            <div key={plan.plan_id} className="plan-card">
              <div className="plan-card-content">
                <div className="plan-card-header">
                  <h3>{plan.plan_name}</h3>
                </div>
                <div className="plan-card-body">
                  {plan.plan_name !== "Free" && (
                    <>
                      <div className="plan-card-price">
                        ₹
                        {isAnnual
                          ? Math.floor(plan.annual_price / 12)
                          : plan.monthly_price}
                        <span>/month</span>
                        <p>
                          {isAnnual
                            ? "You will be charged for the year"
                            : "---"}
                        </p>
                      </div>
                    </>
                  )}
                  <ul className="plan-card-features">
                    <li>
                      <FiFileText /> <strong>{plan.max_tests || "N/A"}</strong>{" "}
                      Tests
                    </li>
                    <li>
                      <FiCheckSquare />{" "}
                      <strong>{plan.max_question || "N/A"}</strong> Questions
                    </li>
                    <li>
                      <FiCode />{" "}
                      <strong>
                        {plan.max_testcases_per_question || "N/A"}
                      </strong>{" "}
                      Test Cases
                    </li>
                    <li>
                      <FiUsers />{" "}
                      <strong>{plan.max_participants || "N/A"}</strong>{" "}
                      Participants
                    </li>
                  </ul>
                  {renderButton(plan)}
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      {/* Enterprise contact link */}
      <div className="enterprise-contact">
        <a href="/contact-info">For Enterprise plan Contact Us</a>
      </div>
      <SubFooter />
    </div>
  );
}

export default PaymentComponent;
