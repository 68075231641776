import React, { useState } from "react";
import { 
  Box, 
  ThemeProvider, 
  createTheme, 
  CssBaseline, 
  Typography 
} from "@mui/material"; // Add Typography here
import CandidateHeader from "./CandidateHeader";
import CandidateMainContent from "./CandidateMainContent";
import { useNavigate, useLocation } from "react-router-dom";


// Custom Theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#1a73e8',
    },
    background: {
      default: '#f4f6f8',
      paper: '#ffffff',
    },
    text: {
      primary: 'rgba(0,0,0,0.87)',
      secondary: 'rgba(0,0,0,0.6)',
    },
  },
  typography: {
    fontFamily: [
      'Inter', 
      '-apple-system', 
      'BlinkMacSystemFont', 
      '"Segoe UI"', 
      'Roboto', 
      '"Helvetica Neue"', 
      'Arial', 
      'sans-serif'
    ].join(','),
    h4: {
      fontWeight: 700,
    },
    body1: {
      fontWeight: 500,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
        },
      },
    },
  },
});

const CandidateDashboard = () => {
  const [activeTab, setActiveTab] = useState("compete");
  const location = useLocation();
  const isGuest = new URLSearchParams(location.search).get("guest") === "true";

 

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box 
        sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          height: '100vh',
          background: 'linear-gradient(135deg, #f5f7fa 0%, #f5f7fa 100%)',
        }}
      >
        <CandidateHeader 
          activeTab={activeTab} 
          onTabChange={setActiveTab} 
        />
         {isGuest && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", mt: 2, color: "gray" }}
          >
            You are viewing as a Guest. Login to access full features.
          </Typography>
        )}
        <CandidateMainContent
          activeTab={activeTab}
          onTabChange={setActiveTab}
          guestMode={isGuest} // Pass guestMode to main content
        />
      </Box>
    </ThemeProvider>
  );
};

export default CandidateDashboard;