import React, { useState, useEffect } from "react";
import axios, { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import "../../css/AttemptTest.css";
import Navbar from "../Navbar";

const AttemptTest = () => {
  const token = localStorage.getItem("token");
  const BASE_URL = import.meta.env.VITE_BASE_URL;
  const [email, setEmail] = useState("");
  const [testId, setTestId] = useState("");
  const [error, setError] = useState("");
  const [showRegisterForm, setShowRegisterForm] = useState(true); // Track if registration form should be shown
  const [username, setUsername] = useState(""); // For registration
  const [password, setPassword] = useState(""); // For registration
  const [testInfo, setTestInfo] = useState(null);

  const featureFlag = true;

  const navigate = useNavigate();

  // Extract query params on load
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const queryEmail = queryParams.get("email");
    const queryTestId = queryParams.get("testId");

    if (queryEmail) setEmail(queryEmail);
    if (queryTestId) setTestId(queryTestId);

    // Check if the candidate exists
    checkCandidateExists(queryEmail);
  }, []);

  const checkCandidateExists = async (email) => {
    try {
      await axios.post(
        `${import.meta.env.VITE_BASE_URL}/check-candidate-email`,
        { email }
      );
      // If the response is 200, the candidate exists. Do nothing.
      setShowRegisterForm(false);
    } catch (error) {
      if (
        error.response &&
        (error.response.status === 404 || error.response.status === 400)
      ) {
        // Email not found, show registration form
        setShowRegisterForm(true);
      } else {
        setError("Error checking candidate status");
      }
    }
  };

  const handleRegistration = async () => {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/onboard-candidate`,
        {
          email,
          username,
          password,
        }
      );

      if (response.status === 201) {
        // Registration successful, hide form and proceed to attempt test
        setShowRegisterForm(false);
        // handleAttemptTest();
      }
      if (response.status === 409) {
        setError("You are already registered. Continue with your email id");
      }
    } catch (error) {
      var status = error.response.status;
      var message = error.response.data.error;
      if (status == 409) {
        setError("Username/Email already registered.");
      } else {
        setError("Error during registration:");
      }
    }
  };

  // const handleAttemptTest = async () => {
  //   try {
  //     console.log("Checking test availability...");
  //     const availabilityResponse = await axios.post(
  //       `${import.meta.env.VITE_BASE_URL}/check-test-availability`,
  //       { testId }
  //     );

  //     if (availabilityResponse.status === 200) {
  //       console.log("Test is available, attempting test...");

  //       // Record the test attempt in the database
  //       const recordResponse = await axios.post(
  //         `${import.meta.env.VITE_BASE_URL}/test-attempt-record`,
  //         {
  //           email,
  //           test_id: testId,
  //         }
  //       );

  //       if (recordResponse.status === 200) {
  //         console.log("Test attempt recorded successfully");

  //         // Proceed with the test attempt
  //         const response = await axios.post(
  //           `${import.meta.env.VITE_BASE_URL}/attempt-test`,
  //           { email, testId }
  //         );
  //         console.log("Attempt test response:", response); // Check if response is 200

  //         if (response.status === 200) {
  //           console.log("Redirecting to instruction...");
  //           localStorage.setItem("email", email);
  //           // localStorage.setItem("test id", testId);
  //           localStorage.setItem("testId", testId);
  //           navigate("/instruction");
  //         }
  //       } else {
  //         setError("Failed to record test attempt");
  //       }
  //     } else {
  //       console.log("Test is not available.");
  //     }
  //   } catch (error) {
  //     console.error("Error attempting test:", error);
  //     if (error.response && error.response.status === 400) {
  //       setError(error.response.data.error);
  //     } else if (error.response && error.response.status === 403) {
  //       setError("You have already attempted the test");
  //     } else {
  //       setError("Error attempting test, invalid credentials");
  //     }
  //   }
  // };

  const handleAttemptTest = async () => {
    try {
      console.log("Checking test availability...");
      const availabilityResponse = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/check-test-availability`,
        { email, testId }
      );

      if (availabilityResponse.status === 200) {
        console.log("Test is available, attempting test...");
        const testData = availabilityResponse.data.data;

        // Record the test attempt and get participantId
        const recordResponse = await axios.post(
          `${import.meta.env.VITE_BASE_URL}/test-attempt-record`,
          {
            email,
            test_id: testId,
          }
        );

        if (recordResponse.status === 200) {
          console.log("Test attempt recorded successfully");

          // Add participantId to testData
          const updatedTestData = {
            ...testData,
            participantId: recordResponse.data.data.participantId,
          };

          // Store updated test information in localStorage
          console.log("Test Info being stored:", updatedTestData);
          localStorage.setItem("testInfo", JSON.stringify(updatedTestData));
          localStorage.setItem("email", email);
          localStorage.setItem("testId", testId);

          // Proceed with the test attempt
          const response = await axios.post(
            `${import.meta.env.VITE_BASE_URL}/attempt-test`,
            { email, testId }
          );

          if (response.status === 200) {
            console.log("Redirecting to instruction...");
            navigate("/instruction");
          }
        } else {
          setError("Failed to record test attempt");
        }
      }
    } catch (error) {
      console.error("Error attempting test:", error);
      if (error.response && error.response.status === 400) {
        setError(error.response.data.error);
      } else if (error.response && error.response.status === 403) {
        setError("You have already attempted the test");
      } else {
        setError("Error attempting test, invalid credentials");
      }
    }
  };

  // const userId = "25fdeac4-0af5-4a81-92ab-64e060ad6e0b";
  // const testsId = 81715;

  // Feature flag true
  // const handleAttemptTest2 = async () => {
  //   var org_details_status = 404;
  //   var org_details = undefined;
  //   try {
  //     console.log(testId)
  //     org_details = await axios.get(
  //       `${import.meta.env.VITE_BASE_URL}/tests/${testId}`
  //     );
  //   } catch (error) {
  //     if (error.response) {
  //       if (error.response.status === 400) {
  //         setError(error.response.data.error);
  //       } else if (error.response.status === 403) {
  //         setError("You have already attempted the test");
  //       }
  //       org_details_status = error.response.status;
  //     }
  //   }

  //   if (org_details_status === 404) {
  //     try {
  //       org_details = await axios.get(
  //         `${import.meta.env.VITE_BASE_URL}/api/org/test/fetch-test-data/${testId}`
  //       );
  //     } catch (error) {
  //       if (error.response) {
  //         if (error.response.status === 400) {
  //           setError(error.response.data.error);
  //         } else if (error.response.status === 403) {
  //           setError("You have already attempted the test");
  //         }
  //         org_details_status = error.response.status;
  //       }
  //     }
  //   }

  //   if (org_details === undefined) {
  //     setError("Unable to get test details");
  //     return;
  //   }
  //   console.log(org_details);
  //   var userId = "";
  //   if (org_details.status === 200) {
  //     userId = org_details.data.created_by;
  //     if (userId === undefined) {
  //       userId = org_details.data.data.created_by;
  //     }
  //   }

  //   var createParticipantResponse;
  //   var createParticipantResponse_status = 404;
  //   try {
  //     createParticipantResponse = await axios.post(
  //       `${import.meta.env.VITE_BASE_URL}/create-participant`,
  //       {
  //         user_id: userId,
  //         first_name: email,
  //         last_name: email,
  //         email: email,
  //       }
  //     );
  //     createParticipantResponse_status = createParticipantResponse.status;
  //   } catch (error) {
  //     if (error.response) {
  //       createParticipantResponse_status = error.response.status;
  //     }
  //   }

  //   const addParticipantToTestResponse = await axios.post(
  //     `${import.meta.env.VITE_BASE_URL}/add-participant`,
  //     {
  //       user_id: userId,
  //       email: email,
  //       test_id: testId,
  //     }
  //   );

  //   if (addParticipantToTestResponse === 200) {
  //     console.log("participant added in test");
  //   }

  //   console.log("Checking test availability...");
  //   const availabilityResponse = await axios.post(
  //     `${import.meta.env.VITE_BASE_URL}/check-test-availability`,
  //     { email: email, testId: testId }
  //   );

  //   if (availabilityResponse.status === 200) {
  //     const testData = availabilityResponse.data.data;
  //     console.log("Test Info being stored:", testData);

  //     localStorage.setItem("testInfo", JSON.stringify(testData));
  //     localStorage.setItem("email", email);
  //     localStorage.setItem("testId", testId);

  //     console.log("Test is available, attempting test...");

  //     const recordResponse = await axios.post(
  //       `${import.meta.env.VITE_BASE_URL}/test-attempt-record`,
  //       {
  //         email,
  //         test_id: testId,
  //       }
  //     );

  //     if (recordResponse.status === 200) {
  //       console.log("Test attempt recorded successfully");

  //       try {
  //         const response = await axios.post(
  //           `${import.meta.env.VITE_BASE_URL}/attempt-test`,
  //           { email, testId: testId }
  //         );
  //         console.log("Attempt test response:", response);

  //         if (response.status === 200) {
  //           console.log("Redirecting to instruction...");
  //           navigate("/instruction");
  //         }
  //       } catch (error) {
  //         if (error.response?.status === 403) {
  //           setError("You have already attempted the test");
  //         }
  //       }
  //     } else {
  //       setError("Failed to record test attempt");
  //     }
  //   } else {
  //     console.log("Test is not available.");
  //   }
  // };

  const handleAttemptTest2 = async () => {
    try {
      console.log(testId);
      // Fetch complete test details
      const testDataResponse = await axios.get(
        `${BASE_URL}/api/org/tests/get-test-data/${testId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (!testDataResponse.data.success) {
        setError("Unable to get test details");
        return;
      }

      const testData = testDataResponse.data.data;

      // Check test timing
      const now = new Date();
      const testStart = new Date(testData.testStartDateTime);
      const testEnd = new Date(testData.testEndDateTime);

      if (now < testStart) {
        setError(`Test will start on ${testStart.toLocaleString()}`);
        return;
      }

      if (now > testEnd) {
        setError("Test has ended");
        return;
      }

      // Get userId from test data
      const userId = testData.createdBy;

      // Create participant (keeping existing logic)
      var createParticipantResponse;
      var createParticipantResponse_status = 404;
      try {
        createParticipantResponse = await axios.post(
          `${import.meta.env.VITE_BASE_URL}/create-participant`,
          {
            user_id: userId,
            first_name: email,
            last_name: email,
            email: email,
          }
        );
        createParticipantResponse_status = createParticipantResponse.status;
      } catch (error) {
        if (error.response) {
          createParticipantResponse_status = error.response.status;
        }
      }

      // Add participant to test
      const addParticipantToTestResponse = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/add-participant`,
        {
          user_id: userId,
          email: email,
          test_id: testId,
        }
      );

      // Record test attempt and get participantId
      const recordResponse = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/test-attempt-record`,
        {
          email,
          test_id: testId,
        }
      );

      if (recordResponse.status === 200) {
        console.log("Test attempt recorded successfully");

        // Add participantId to testData
        const updatedTestData = {
          ...testData,
          participantId: recordResponse.data.data.participantId,
        };

        // Store updated test data in localStorage
        localStorage.setItem("testInfo", JSON.stringify(updatedTestData));
        localStorage.setItem("email", email);
        localStorage.setItem("testId", testId);

        try {
          const response = await axios.post(
            `${import.meta.env.VITE_BASE_URL}/attempt-test`,
            { email, testId: testId }
          );
          console.log("Attempt test response:", response);

          if (response.status === 200) {
            console.log("Redirecting to instruction...");
            navigate("/instruction");
          }
        } catch (error) {
          if (error.response?.status === 403) {
            setError("You have already attempted the test");
          }
        }
      } else {
        setError("Failed to record test attempt");
      }
    } catch (error) {
      console.error("Error attempting test:", error);
      if (error.response) {
        if (error.response.status === 400) {
          setError(error.response.data.error);
        } else if (error.response.status === 403) {
          setError("You have already attempted the test");
        } else {
          setError("Error attempting test, please try again");
        }
      }
    }
  };

  return (
    <div>
      <Navbar />
      <div className="attempt-test-hero">
        <div className="attempt-test-hero-content">
          <h1>Welcome to CirrusCypher</h1>
          <p>Unleash your coding potential with our challenging tests.</p>
        </div>
      </div>
      <div className="attempt-test-container">
        <div className="attempt-test-header">
          <img
            src="/assets/student.jpg"
            alt="Logo"
            className="attempt-test-logo"
          />
          <h2>Attempt Test</h2>
        </div>
        {error && <p className="attempt-test-error">{error}</p>}

        {!showRegisterForm ? (
          <div className="attempt-test-form">
            <div className="attempt-test-form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="attempt-test-form-group">
              <label htmlFor="testId">Test ID:</label>
              <input
                type="text"
                id="testId"
                value={testId}
                onChange={(e) => setTestId(e.target.value)}
                required
              />
            </div>
            <button
              className="attempt-test-btn-attempt"
              onClick={featureFlag ? handleAttemptTest2 : handleAttemptTest}
            >
              Attempt Test
            </button>
          </div>
        ) : (
          // If user is not found, show registration form
          <div className="registration-form">
            <h3>Register to Continue</h3>
            <div className="attempt-test-form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                on
                required
              />
            </div>
            <div className="attempt-test-form-group">
              <label htmlFor="username">Username:</label>
              <input
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="attempt-test-form-group">
              <label htmlFor="password">Password:</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="attempt-test-form-group">
              <button
                className="attempt-test-btn-register bg-blue-500"
                onClick={handleRegistration}
              >
                Register
              </button>
            </div>
            <div className="attempt-test-form-group">
              <button
                onClick={(e) => {
                  setError("");
                  setShowRegisterForm(false);
                }}
              >
                Already Registered?
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AttemptTest;
