// IOTabs.jsx
import React, { useState } from "react";
import {
  FaCheckCircle,
  FaTimesCircle, 
  FaSpinner,
  FaArrowLeft,
  FaArrowRight,
  FaChevronDown,
  FaChevronUp,
  FaArrowsAltH,
} from "react-icons/fa";
import { VscLoading } from "react-icons/vsc";
import CustomInput from "../../CustomInput"; 
import OutputWindow from "../OutputWindow";

const IOTabs = ({
  activeTab,
  setActiveTab,
  customInput,
  setCustomInput,
  outputDetails,
  testCaseResults,
}) => {
  const hasErrors =
    outputDetails?.stderr && outputDetails.stderr.trim().length > 0;

  return (
    <div className="w-full">
      <div className="flex border-b border-gray-200 bg-gray-50">
        <button
          className={`px-4 py-2 font-medium ${
            activeTab === "custom"
              ? "border-b-2 border-blue-500 text-blue-600 bg-white"
              : "text-gray-600 hover:bg-gray-100"
          }`}
          onClick={() => setActiveTab("custom")}
        >
          Custom Input/Output
        </button>
        <button
          className={`px-4 py-2 font-medium ${
            activeTab === "testcases"
              ? "border-b-2 border-blue-500 text-blue-600 bg-white"
              : "text-gray-600 hover:bg-gray-100"
          }`}
          onClick={() => setActiveTab("testcases")}
        >
          Test Cases
        </button>
        {hasErrors && (
          <button
            className={`px-4 py-2 font-medium ${
              activeTab === "errors"
                ? "border-b-2 border-blue-500 text-blue-600 bg-white"
                : "text-gray-600 hover:bg-gray-100"
            }`}
            onClick={() => setActiveTab("errors")}
          >
            Errors
          </button>
        )}
      </div>

      <div className="p-4">
        {activeTab === "custom" && (
          <div className="flex space-x-4">
            <div className="w-1/2">
              <h3 className="font-medium mb-2">Input:</h3>
              <CustomInput
                customInput={customInput}
                setCustomInput={setCustomInput}
              />
            </div>
            <div className="w-1/2">
              <OutputWindow outputDetails={outputDetails} />
            </div>
          </div>
        )}
        {activeTab === "testcases" && (
          <div className="test-case-results">
            <h3 className="font-medium mb-2">Test Case Results:</h3>
            <div className="grid grid-cols-2 gap-4">
              {Object.entries(testCaseResults).map(([index, result], idx) => (
                <div
                  key={index}
                  className="flex items-center space-x-2 bg-gray-50 p-3 rounded"
                >
                  <span className="font-medium">{`Test Case ${idx + 1}:`}</span>
                  {result.passed ? (
                    <FaCheckCircle className="text-green-500" />
                  ) : (
                    <FaTimesCircle className="text-red-500" /> // Fixed here as well
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
        {activeTab === "errors" && hasErrors && (
          <div className="p-4 bg-red-50 border border-red-500 rounded text-red-700">
            <h3 className="font-medium mb-2">Error Output:</h3>
            <pre className="whitespace-pre-wrap">{outputDetails.stderr}</pre>
          </div>
        )}
      </div>
    </div>
  );
};

export default IOTabs;
