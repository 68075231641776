// QuestionViewModal.jsx
import React from "react";
import { Modal, Box, Typography, IconButton, Paper, Chip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const QuestionViewModal = ({ open, onClose, question }) => {
  if (!question) return null;

  const getDifficultyColor = (difficulty) => {
    switch (difficulty?.toLowerCase()) {
      case "easy":
        return "#4caf50";
      case "medium":
        return "#ff9800";
      case "hard":
        return "#f44336";
      default:
        return "inherit";
    }
  };

  console.log("Question Data:", question);

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 800,
          maxHeight: "90vh",
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            p: 2,
            borderBottom: "1px solid",
            borderColor: "divider",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">
            {question.type === "coding"
              ? "Coding Question Details"
              : "MCQ Question Details"}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box sx={{ p: 3, overflowY: "auto" }}>
          {question.type === "coding" ? (
            // Coding Question View
            <Box>
              <Paper sx={{ p: 3, mb: 3 }}>
                <Typography variant="h6" gutterBottom color="primary">
                  Question Title
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {question.title}
                </Typography>
              </Paper>

              <Paper sx={{ p: 3, mb: 3 }}>
                <Typography variant="h6" gutterBottom color="primary">
                  Description
                </Typography>
                <Box
                  sx={{
                    "& p": { mb: 2 },
                    "& blockquote": {
                      borderLeft: "4px solid",
                      borderColor: "primary.main",
                      pl: 2,
                      my: 2,
                      bgcolor: "action.hover",
                      py: 1,
                    },
                    "& strong": { fontWeight: "bold" },
                    "& em": { fontStyle: "italic" },
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: question.content,
                    }}
                  />
                </Box>
              </Paper>

              {/* Test Cases Section */}
              {question.testCases && question.testCases.length > 0 && (
                <Paper sx={{ p: 3 }}>
                  <Typography variant="h6" gutterBottom color="primary">
                    Sample Test Cases
                  </Typography>
                  {question.testCases.slice(0, 2).map((testCase, index) => (
                    <Box
                      key={index}
                      sx={{
                        mb: 2,
                        p: 2,
                        bgcolor: "action.hover",
                        borderRadius: 1,
                      }}
                    >
                      <Typography variant="subtitle2" gutterBottom>
                        Test Case {index + 1}
                      </Typography>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                          gap: 2,
                        }}
                      >
                        <Box>
                          <Typography variant="caption" color="text.secondary">
                            Input:
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              fontFamily: "monospace",
                              whiteSpace: "pre-wrap",
                              bgcolor: "grey.100",
                              p: 1,
                              borderRadius: 1,
                            }}
                          >
                            {testCase.inputContent || testCase.input}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="caption" color="text.secondary">
                            Expected Output:
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              fontFamily: "monospace",
                              whiteSpace: "pre-wrap",
                              bgcolor: "grey.100",
                              p: 1,
                              borderRadius: 1,
                            }}
                          >
                            {testCase.outputContent || testCase.output}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Paper>
              )}
            </Box>
          ) : (
            // MCQ Question View
            <Box>
              <Paper sx={{ p: 3, mb: 3 }}>
                <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                  Question Type:
                </Typography>
                <Typography>
                  {question.question_type === 0
                    ? "Single Correct Option"
                    : "Multiple Correct Options"}
                </Typography>
              </Paper>

              <Paper sx={{ p: 3, mb: 3 }}>
                <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                  Question Text:
                </Typography>
                <div
                  dangerouslySetInnerHTML={{
                    __html: question.question_text,
                  }}
                />
              </Paper>

              <Paper sx={{ p: 3, mb: 3 }}>
                <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                  Description:
                </Typography>
                <div
                  dangerouslySetInnerHTML={{
                    __html: question.question_description,
                  }}
                />
              </Paper>

              <Paper sx={{ p: 3, mb: 3 }}>
                <Box sx={{ display: "flex", gap: 4 }}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Difficulty:{" "}
                    <span
                      style={{
                        color: getDifficultyColor(question.difficulty),
                      }}
                    >
                      {question.difficulty}
                    </span>
                  </Typography>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Points: {question.points}
                  </Typography>
                </Box>
              </Paper>

              {/* Section to handle different option structures */}
              <Paper sx={{ p: 3 }}>
                <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                  Options:
                </Typography>
                {Array.isArray(question.options) ? (
                  question.options.map((option, index) => {
                    // Check both possible correct option flags
                    const isCorrect = option.isCorrect || option.is_correct;

                    return (
                      <Box
                        key={option.option_id || option.optionId || index}
                        sx={{
                          mb: 2,
                          p: 2,
                          bgcolor: isCorrect ? "success.light" : "grey.50",
                          borderRadius: 1,
                          border: 1,
                          borderColor: isCorrect ? "success.main" : "grey.300",
                          transition: "all 0.2s ease",
                          "&:hover": {
                            boxShadow: 1,
                          },
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "space-between",
                            gap: 1,
                          }}
                        >
                          <Typography variant="body2" sx={{ flex: 1 }}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: option.option_text || option.optionText,
                              }}
                            />
                          </Typography>
                          <Typography
                            variant="body2"
                            color={
                              isCorrect ? "success.main" : "text.secondary"
                            }
                          >
                            {isCorrect ? "Correct" : "Incorrect"}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  })
                ) : (
                  <Typography color="text.secondary">
                    No options available
                  </Typography>
                )}
              </Paper>
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default QuestionViewModal;
