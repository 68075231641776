import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Paper,
  Typography,
  useTheme,
  Chip,
  Container
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import dayjs from 'dayjs'


import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import QuizIcon from '@mui/icons-material/Quiz';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CodeIcon from '@mui/icons-material/Code';
import SettingsIcon from '@mui/icons-material/Settings';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all 0.3s ease',
  borderRadius: theme.spacing(3),
  boxShadow: '0 8px 15px rgba(0,0,0,0.1)',
  cursor: 'pointer',
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: '0 12px 20px rgba(0,0,0,0.15)',
    '& .card-title': {
      color: '#1a73e8'
    }
  }
}));

const StatBox = ({ icon: Icon, label, value, theme }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      px: 3,
      py: 2,
      borderRight: `1px solid ${theme.palette.divider}`,
      '&:last-child': {
        borderRight: 'none'
      }
    }}
  >
    <Icon
      sx={{
        color: theme.palette.primary.main,
        fontSize: 24,
        mr: 1.5
      }}
    />
    <Box>
      <Typography
        variant="body2"
        color="text.secondary"
        sx={{
          fontSize: '0.75rem',
          fontWeight: 500,
          textTransform: 'uppercase',
          letterSpacing: '0.1em',
          mb: 0.5
        }}
      >
        {label}
      </Typography>
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: '1.1rem',
          color: theme.palette.text.primary
        }}
      >
        {value}
      </Typography>
    </Box>
  </Box>
);

const PrepareTestDetails = ({ test, onBack }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [testId, setTestId] = useState("");

   // Similar to CandidateTestDetail, fetch email and validate test details
   // Fetch email and test ID
  useEffect(() => {
    const fetchCandidateDetails = () => {
      try {
        const storedEmail = localStorage.getItem("email");
        if (storedEmail) {
          setEmail(storedEmail);
        } else {
          setError("Email not found in localStorage");
        }

        // Explicitly check and set testId
        if (test && test.testId) {
            setTestId(test.testId); // Use testId from the passed prop
        } else {
          setError("Test ID not found in props");
          console.error("Test ID is missing:", test);
        }
      } catch (err) {
        console.error("Error fetching candidate details:", err);
        setError("Failed to fetch candidate details");
      }
    };

    fetchCandidateDetails();
  }, [test]); // Depend on the test prop

 // Comprehensive question count calculation
 const calculateQuestionCounts = () => {
    if (!test || !test.sections) return { mcqCount: 0, codingCount: 0, totalCount: 0 };

    let mcqCount = 0;
    let codingCount = 0;

    test.sections.forEach(section => {
        mcqCount += section.mcqQuestionIds?.length || 0;
        codingCount += section.codingQuestionIds?.length || 0;

        section.groupIds?.forEach(group => {
            if (group && group.questions) {
                group.questions.forEach(question => {
                    if (question.type === "mcq") mcqCount++;
                    else if (question.type === "coding") codingCount++;
                });
            }
        });
    });

    return { mcqCount, codingCount, totalCount: mcqCount + codingCount };
};


  const handleStartPrepare = async () => {
    try {
      if (!test) {
        setError("Test data is not available. Please try again.");
        return;
      }

      if (!testId) {
        setError("Test ID is missing. Cannot proceed.");
        return;
      }
      // Time validation logic similar to CandidateTestDetail
      const now = new Date();
      const testStart = test.testStartDateTime ? new Date(test.testStartDateTime) : null;
      const testEnd = test.testEndDateTime ? new Date(test.testEndDateTime) : null;

      if (testStart && now < testStart) {
        setError(`Test will start on ${testStart.toLocaleString()}`);
        return;
      }

      if (testEnd && now > testEnd) {
        setError("Test has ended");
        return;
      }

      // Navigate to preparation/attempt page
      // You might want to create a specific route for preparation
      navigate(`/attempt-test-page?email=${encodeURIComponent(email)}&testId=${testId}`);
    } catch (error) {
      console.error("Error starting test preparation:", error);
      setError("An unexpected error occurred while starting test preparation");
    }
  };

  // Error handling if no test data
  if (!test) {
    return (
      <Container maxWidth="md">
        <Box 
          display="flex" 
          flexDirection="column" 
          justifyContent="center" 
          alignItems="center" 
          minHeight="400px"
        >
          <Typography color="error" variant="h6" sx={{ mb: 2 }}>
            No test data received
          </Typography>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={onBack}
          >
            Back to Tests
          </Button>
        </Box>
      </Container>
    );
  }

  const questionCounts = calculateQuestionCounts();

  const calculateTotalDuration = () => {
    if (!test || !test.sections) return 0;
    return test.sections.reduce((sum, section) => sum + (section.sectionDuration || 0), 0);
};

const totalDuration = calculateTotalDuration();

const calculateDifficulty = () => {
    const questionCounts = calculateQuestionCounts();
    const totalQuestions = questionCounts.totalCount;
    if (totalQuestions < 5) return "Easy";
    if (totalQuestions <= 10) return "Medium";
    return "Hard";
};

const difficulty = calculateDifficulty();

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box sx={{ px: 0 }}>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={onBack}
          sx={{ 
            mb: 3,
            ml: 0,
            '&:hover': {
              backgroundColor: 'rgba(25,118,210,0.1)'
            }
          }}
          variant="outlined"
          color="primary"
        >
          Back to Prepare Tests
        </Button>

        <Paper 
          elevation={4}
          sx={{
            borderRadius: 3,
            overflow: 'hidden',
            boxShadow: '0 10px 20px rgba(0,0,0,0.08)',
            transition: 'all 0.3s ease',
            '&:hover': {
              boxShadow: '0 15px 25px rgba(0,0,0,0.12)'
            }
          }}
        >
          <Box 
            sx={{ 
              backgroundColor: 'rgba(26,115,232,0.1)',
              p: 4,
              borderBottom: '1px solid rgba(0,0,0,0.1)'
            }}
          >
            <Typography
              variant="h5"
              sx={{ 
                fontWeight: 700,
                color: 'primary.main',
                borderBottom: '3px solid',
                pb: 1,
                mb: 2
              }}
            >
              {test.testTitle}
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ 
                lineHeight: 1.6 
              }}
            >
              {test.testDescription || 'No description available'}
            </Typography>
          </Box>

          <Box 
            sx={{ 
              px: 4,
              py: 3,
              display: 'flex',
              borderBottom: `1px solid ${theme.palette.divider}`
            }}
          >
            <StatBox
              icon={AccessTimeIcon}
              label="Duration"
              value={`${totalDuration} min`}
              theme={theme}
            />
            <StatBox
              icon={QuizIcon}
              label="Difficulty"
              value={difficulty}
              theme={theme}
            />
            <StatBox
              icon={AssignmentIcon}
              label="Total Questions"
              value={questionCounts.totalCount}
              theme={theme}
            />
          </Box>

          <Box sx={{ p: 4 }}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 700,
            mb: 3,
            display: 'flex',
            alignItems: 'center',
            color: 'primary.main',
            borderBottom: '3px solid',
            pb: 1
          }}
        >
          <QuizIcon sx={{ mr: 2 }} />
          Questions Breakdown
        </Typography>

        <Card 
          sx={{ 
            mb: 3,
            border: '1px solid rgba(0,0,0,0.1)'
          }}
        >
          <CardContent>
            <Box 
              sx={{ 
                display: 'flex',
                borderBottom: `1px solid ${theme.palette.divider}`
              }}
            >
              <StatBox
                icon={QuizIcon}
                label="MCQ Questions"
                value={questionCounts.mcqCount}
                theme={theme}
            />
            <StatBox
                icon={CodeIcon}
                label="Coding Questions"
                value={questionCounts.codingCount}
                theme={theme}
            />
            <StatBox
                icon={AssignmentIcon}
                label="Total Questions"
                value={questionCounts.totalCount}
                theme={theme}
            />
            </Box>

                   {/* Language chips section */}
            {test.allowedLanguages && test.allowedLanguages.length > 0 && (
              <Box 
                sx={{ 
                  p: 2,
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center'
                }}
              >
                <Box sx={{ display: 'flex', gap: 1 }}>
                  {test.allowedLanguages.map((lang, i) => (
                    <Chip
                      key={`${lang}-${i}`}
                      label={lang}
                      size="small"
                      color="primary"
                      variant="outlined"
                      sx={{
                        fontWeight: 500
                      }}
                    />
                  ))}
                </Box>
              </Box>
            )}
          </CardContent>
        </Card>
             
              {/* Error message display */}
        {error && (
          <Typography 
            color="error" 
            variant="body2" 
            sx={{ 
              textAlign: 'center', 
              mt: 2, 
              mb: 2 
            }}
          >
            {error}
          </Typography>
        )}

            <Box
              sx={{
                mt: 4,
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={handleStartPrepare}
                sx={{
                  minWidth: 200,
                  py: 1.5,
                  fontSize: '1.1rem',
                  fontWeight: 600,
                  borderRadius: 2,
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-3px)',
                    boxShadow: theme.shadows[6]
                  }
                }}
              >
                Start Preparation
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};

export default PrepareTestDetails;