import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Avatar,
  Paper,
  IconButton,
  InputAdornment,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  CameraAlt,
  Edit,
  Save,
  Cancel,
  BusinessCenter,
} from "@mui/icons-material";
import axios from "axios";

const ProfileWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  minHeight: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
}));

const ProfilePaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: theme.spacing(2),
  maxWidth: "900px",
  width: "100%",
  boxShadow: "0 10px 30px rgba(0,0,0,0.08)",
}));

const ProfileHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(4),
  paddingBottom: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const AvatarUploadContainer = styled(Box)({
  position: "relative",
  display: "inline-block",
});

const LoadingOverlay = styled(Box)({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "rgba(255, 255, 255, 0.7)",
  borderRadius: "50%",
});

const OrgProfile = ({ onUpdateProfile }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [addressId, setAddressId] = useState(null);
  const [originalUsername, setOriginalUsername] = useState(""); // Add this line

  const [formData, setFormData] = useState({
    orgName: "",
    username: "",
    contactPersonName: "",
    companyName: "",
    gstNumber: "",
    streetAddress: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
    contactNumber: "",
    contactEmail: "",
  });

  useEffect(() => {
    fetchOrgProfile();
  }, []);

  const fetchOrgProfile = async () => {
    setLoading(true);
    const userId = localStorage.getItem("user_id");
    if (!userId) {
      setSnackbar({
        open: true,
        message: "User ID is missing. Please log in again.",
        severity: "error",
      });
      setLoading(false);
      return;
    }

    try {
      const response = await axios.get(
        `${
          import.meta.env.VITE_BASE_URL
        }/api/org/org-profile/get-profile/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.success) {
        const profileData = response.data.data;
        if (profileData) {
          // Store the original username
          setOriginalUsername(profileData.username || "");

          setFormData({
            orgName: profileData.name || "",
            username: profileData.username || "",
           
            contactPersonName: profileData.contact_person_name || "",
            companyName: profileData.company_name || "",
            gstNumber: profileData.gst_number || "",
            streetAddress: profileData.street_address || "",
            city: profileData.city || "",
            state: profileData.state || "",
            country: profileData.country || "",
            pincode: profileData.pincode || "",
            contactNumber: profileData.contact_number || "",
            contactEmail: profileData.contact_email || "",
          });
          setAddressId(profileData.address_id);
          if (profileData.profile_photo_url) {
            setProfileImage(profileData.profile_photo_url);
            setPreviewImage(profileData.profile_photo_url);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching organization profile:", error);
      setSnackbar({
        open: true,
        message: "Failed to fetch profile data.",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (formErrors[name]) {
      setFormErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      // Add file type and size validation
      const validTypes = ["image/jpeg", "image/png", "image/gif"];
      const maxSize = 5 * 1024 * 1024; // 5MB

      if (!validTypes.includes(file.type)) {
        setSnackbar({
          open: true,
          message: "Please upload a valid image file (JPEG, PNG, or GIF)",
          severity: "error",
        });
        return;
      }

      if (file.size > maxSize) {
        setSnackbar({
          open: true,
          message: "File size should not exceed 5MB",
          severity: "error",
        });
        return;
      }

      setIsUploading(true);
      try {
        setProfileImage(file);
        setPreviewImage(URL.createObjectURL(file));
      } catch (error) {
        setSnackbar({
          open: true,
          message: "Error processing image",
          severity: "error",
        });
      } finally {
        setIsUploading(false);
      }
    }
  };

  const validateForm = () => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileRegex = /^[0-9]{10}$/;
    const pincodeRegex = /^[0-9]{6}$/;

    if (!formData.orgName) errors.orgName = "Organization name is required";
    if (!formData.contactPersonName)
      errors.contactPersonName = "Contact person is required";
    if (!formData.companyName) errors.companyName = "Company name is required";

    if (!formData.contactEmail) {
      errors.contactEmail = "Email is required";
    } else if (!emailRegex.test(formData.contactEmail)) {
      errors.contactEmail = "Invalid email format";
    }

    if (!formData.contactNumber) {
      errors.contactNumber = "Mobile number is required";
    } else if (!mobileRegex.test(formData.contactNumber)) {
      errors.contactNumber = "Invalid mobile number";
    }

    if (!formData.pincode) {
      errors.pincode = "Pincode is required";
    } else if (!pincodeRegex.test(formData.pincode)) {
      errors.pincode = "Invalid pincode";
    }

    if (!formData.streetAddress)
      errors.streetAddress = "Street address is required";
    if (!formData.city) errors.city = "City is required";
    if (!formData.state) errors.state = "State is required";
    if (!formData.country) errors.country = "Country is required";

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;
  
    const userId = localStorage.getItem("user_id");
    if (!userId) {
      setSnackbar({
        open: true,
        message: "User ID is missing. Please log in again.",
        severity: "error",
      });
      return;
    }
  
    setLoading(true);
    try {
      const formDataToSend = new FormData();
  
      // Always include userId
      formDataToSend.append("userId", userId);
  
      // Add form fields based on context
      const fieldsToSend = {
        contactPersonName: formData.contactPersonName,
        companyName: formData.companyName,
        gstNumber: formData.gstNumber || "", // Handle empty GST
        streetAddress: formData.streetAddress,
        city: formData.city,
        state: formData.state,
        country: formData.country,
        pincode: formData.pincode,
        contactNumber: formData.contactNumber,
        contactEmail: formData.contactEmail,
      };
  
      // Only include `orgName` and `username` for updates
      if (addressId) {
        if (formData.username && formData.username !== originalUsername) {
          fieldsToSend.username = formData.username;
        }
        if (formData.orgName) {
          fieldsToSend.orgName = formData.orgName;
        }
      }
  
      // Add fields to FormData
      Object.entries(fieldsToSend).forEach(([key, value]) => {
        if (value !== undefined && value !== null) {
          formDataToSend.append(key, value.trim());
        }
      });
  
      // Include addressId for updates
      if (addressId) {
        formDataToSend.append("addressId", addressId);
      }
  
      // Include profile photo if it's a new file
      if (profileImage instanceof File) {
        formDataToSend.append("profilephoto", profileImage);
      }
  
      // Determine the endpoint and method
      const url = addressId
        ? `${import.meta.env.VITE_BASE_URL}/api/org/org-profile/update-profile`
        : `${import.meta.env.VITE_BASE_URL}/api/org/org-profile/create-profile`;
  
      const method = addressId ? "put" : "post";
  
      // Log FormData contents for debugging
      for (let [key, value] of formDataToSend.entries()) {
        console.log(`${key}: ${value}`);
      }
  
      const response = await axios({
        method,
        url,
        data: formDataToSend,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      });
  
      if (response.data.success) {
        setSnackbar({
          open: true,
          message: addressId
            ? "Profile updated successfully."
            : "Profile created successfully.",
          severity: "success",
        });
        await fetchOrgProfile();
        setIsEditing(false);
      }
    } catch (error) {
      console.error("Error details:", error.response?.data);
      setSnackbar({
        open: true,
        message: error.response?.data?.error || "Failed to save profile.",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };
  

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <ProfileWrapper>
      <ProfilePaper elevation={3}>
        <ProfileHeader>
          <BusinessCenter sx={{ mr: 2, fontSize: 40, color: "primary.main" }} />
          <Box flexGrow={1}>
            <Typography variant="h4" component="h1" fontWeight="600">
              Organization Profile
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              Manage your organization's core information
            </Typography>
          </Box>
          <Box>
            {!isEditing ? (
              <IconButton
                color="primary"
                onClick={() => setIsEditing(true)}
                disabled={loading}
              >
                <Edit />
              </IconButton>
            ) : (
              <>
                <IconButton
                  color="primary"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  <Save />
                </IconButton>
                <IconButton
                  color="error"
                  onClick={() => {
                    setIsEditing(false);
                    setFormErrors({});
                    fetchOrgProfile();
                  }}
                  disabled={loading}
                >
                  <Cancel />
                </IconButton>
              </>
            )}
          </Box>
        </ProfileHeader>

        <Box display="flex" justifyContent="center" mb={4}>
          <AvatarUploadContainer>
            <Avatar
              src={previewImage || profileImage}
              sx={{ width: 100, height: 100, mb: 2 }}
            />
            {isUploading && (
              <LoadingOverlay>
                <CircularProgress size={24} />
              </LoadingOverlay>
            )}
            {isEditing && (
              <label htmlFor="org-profile-image">
                <input
                  accept="image/*"
                  id="org-profile-image"
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                  disabled={loading || isUploading}
                />
                <IconButton
                  component="span"
                  color="primary"
                  disabled={loading || isUploading}
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    backgroundColor: "white",
                    boxShadow: 1,
                  }}
                >
                  <CameraAlt />
                </IconButton>
              </label>
            )}
          </AvatarUploadContainer>
        </Box>

        <Grid container spacing={3}>
          {[
            {
              name: "username",
              label: "Username",
              required: false,
              disabled: true,
            },
            { name: "orgName", label: "Organization Name", required: false },
            {
              name: "contactPersonName",
              label: "Contact Person",
              required: true,
            },
            { name: "companyName", label: "Company Name", required: true },
            { name: "gstNumber", label: "GST Number" },
            { name: "streetAddress", label: "Street Address", required: true },
            { name: "city", label: "City", required: true },
            { name: "state", label: "State", required: true },
            { name: "country", label: "Country", required: true },
            { name: "pincode", label: "Pincode", required: true },
            {
              name: "contactNumber",
              label: "Mobile Number",
              required: true,
              type: "tel",
              inputProps: {
                startAdornment: (
                  <InputAdornment position="start">+91</InputAdornment>
                ),
              },
            },
            {
              name: "contactEmail",
              label: "Email",
              required: true,
              type: "email",
            },
          ].map((field) => (
            <Grid item xs={12} sm={6} key={field.name}>
              <TextField
                fullWidth
                label={field.label}
                name={field.name}
                type={field.type || "text"}
                value={formData[field.name]}
                onChange={handleInputChange}
                disabled={field.disabled || !isEditing || loading}
                required={field.required}
                error={!!formErrors[field.name]}
                helperText={formErrors[field.name]}
                variant="outlined"
                InputProps={field.inputProps}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 2,
                  },
                }}
              />
            </Grid>
          ))}
        </Grid>
      </ProfilePaper>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </ProfileWrapper>
  );
};

export default OrgProfile;
