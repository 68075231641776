import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  IconButton,
  Avatar,
  Button,
  TextField,
  Paper,
  Chip,
  styled,
  Container,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Select,
MenuItem,
  Link,
  CircularProgress
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  LinkedIn as LinkedInIcon,
  GitHub as GitHubIcon,
  WorkOutline as WorkIcon,
  School as SchoolIcon,
  Badge as BadgeIcon,
  VerifiedUser as CertificationIcon,
  Language as LanguageIcon
} from '@mui/icons-material';

import axios from 'axios';
// Import the WorkExperienceModal component
import WorkExperienceModal from './WorkExperienceModal';
import CandidateEducation from './CandidateEducation';
import CandidateLinks from './CandidateLinks';
import CandidateSkills from './CandidateSkills';



// Styled components for consistent design
const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  borderRadius: 16,
  boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
  '&:hover': {
    boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
    transition: 'box-shadow 0.3s ease-in-out'
  }
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(3)
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '20px',
  textTransform: 'none',
  padding: theme.spacing(0.5, 2),
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white
  }
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  borderRadius: '16px',
  margin: theme.spacing(0.5),
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white
  }
}));

const CandidateProfile = () => { 
  // State management for all profile data
  const [profileData, setProfileData] = useState({
    workExperience: [],
    education: [], // Add default empty array
    links: [], // Add default empty array
    skills: [], // Add default empty array
    badges: [], // Add default empty array
    certifications: [], // Add default empty array
    name: '', // Add default empty string
    email: '', // Add default empty string
    phone: '', // Add default empty string
    location: '', // Add default empty string
    avatarUrl: '', // Add default empty string
    completionPercentage: 0, // Add default completion percentage
  });
  

 // Modal states
 const [loading, setLoading] = useState(false);
 const [openWorkExperienceModal, setOpenWorkExperienceModal] = useState(false);
 const [selectedWorkExperience, setSelectedWorkExperience] = useState(null);
 const [openEducationModal, setOpenEducationModal] = useState(false);
 const [selectedEducation, setSelectedEducation] = useState(null);
 const [openLinksModal, setOpenLinksModal] = useState(false);
 const [openSkillsModal, setOpenSkillsModal] = useState(false);
 const [openEditProfileModal, setOpenEditProfileModal] = useState(false);
  const [editProfileData, setEditProfileData] = useState({
    profileName: '',
    country: '',
  });


  // Calculate profile completion percentage
  useEffect(() => {
    const calculateCompletion = () => {
      const sections = [
        !!profileData.name,
        !!profileData.email,
        !!profileData.phone,
        !!profileData.location,
        profileData.skills.length > 0,
        profileData.workExperience.length > 0,
        profileData.education.length > 0,
        profileData.links.length > 0
      ];

      const completedSections = sections.filter(Boolean).length;
      const percentage = Math.round((completedSections / sections.length) * 100);

      setProfileData((prev) => ({
        ...prev,
        completionPercentage: percentage
      }));
    };

    calculateCompletion();
  }, [profileData]);

  // Event handlers
  // Fetch work experiences using candidateId from local storage
  const fetchWorkExperience = async () => {
    setLoading(true);
    try {
      const candidateId = localStorage.getItem('candidateId'); // Retrieve candidateId
      if (!candidateId) {
        console.error('Candidate ID is not found in local storage.');
        return;
      }
  
      const response = await axios.get(`${import.meta.env.VITE_BASE_URL}/api/candidate/profile`, {
        params: { candidateId },
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
  
      console.log('Fetched Work Experience Response:', response.data); // Log API response
  
      const { workExperience } = response.data.data;
  
      setProfileData((prev) => ({
        ...prev,
        workExperience,
      }));
    } catch (error) {
      console.error('Error fetching work experiences:', error);
    } finally {
      setLoading(false);
    }
  };
  
  
  
  
  
  // Add or Update Work Experience with candidateId
  const handleAddOrEditWorkExperience = async (experienceData) => {
    setLoading(true);
    try {
      const candidateId = localStorage.getItem('candidateId');
      const token = localStorage.getItem('token');
  
      if (!candidateId || !token) {
        console.error('Candidate ID or authorization token not found in local storage.');
        return;
      }
  
      // Prepare the payload
      const formattedExperienceData = {
        jobTitle: experienceData.title,
        companyName: experienceData.company,
        location: experienceData.location,
        employmentType: experienceData.employmentType,
        isCurrentlyWorking: experienceData.isCurrentlyWorking ?? false,
        startMonth: String(experienceData.startMonth),
        startYear: String(experienceData.startYear),
        endMonth: experienceData.isCurrentlyWorking ? null : String(experienceData.endMonth || ''),
        endYear: experienceData.isCurrentlyWorking ? null : String(experienceData.endYear || ''),
        description: experienceData.description,
      };
  
      // Include `id` only for editing
      if (selectedWorkExperience) {
        formattedExperienceData.id = selectedWorkExperience.id;
      }
  
      // Validate the required fields
      if (!formattedExperienceData.jobTitle || !formattedExperienceData.companyName) {
        throw new Error('Job title and company name are required.');
      }
  
      // Determine API details
      const apiUrl = `${import.meta.env.VITE_BASE_URL}/api/candidate/profile/work-experience`;
      const apiMethod = selectedWorkExperience ? 'put' : 'post';
      
      // Modify the payload based on whether it's a new or existing work experience
      const apiPayload = selectedWorkExperience
        ? { updatedWorkExperience: formattedExperienceData } // No candidateId for update
        : { 
            candidateId, // Include candidateId only for new work experience
            workExperiences: [formattedExperienceData] 
          };
  
      // Make API call
      await axios({
        method: apiMethod,
        url: apiUrl,
        data: apiPayload,
        headers: { 
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        // Only add params for update request
        ...(selectedWorkExperience && { params: { candidateId } })
      });
  
      // Refresh the work experience data
      await fetchWorkExperience();
    } catch (error) {
      console.error('Error saving work experience:', error.response?.data || error.message);
      // Optional: Add user-friendly error handling
      alert('Failed to save work experience. Please try again.');
    } finally {
      setOpenWorkExperienceModal(false);
      setSelectedWorkExperience(null);
      setLoading(false);
    }
  };
 

  /**
   * Delete Work Experience
   * @param {string} id - ID of the experience to delete
   */

  const handleDeleteWorkExperience = async (id) => {
    if (window.confirm('Are you sure you want to delete this work experience?')) {
      setLoading(true);
      try {
        // Log the id being sent to confirm correctness
        console.log('Deleting Work Experience with ID:', id);
  
        await axios.delete(`${import.meta.env.VITE_BASE_URL}/api/candidate/profile/work-experience/`, {
          params: { id }, // Pass id as query parameter
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        fetchWorkExperience(); // Refresh data
      } catch (error) {
        console.error('Error deleting work experience:', error.response.data);
      } finally {
        setLoading(false);
      }
    }
  };
  /**
   * Add or Update Work Experience
   * @param {Object} experienceData - Data to add/update
   */
  
  // Fetch candidate details, including education
   // Fetch candidate details, including links
   const fetchCandidateDetails = async () => {
    setLoading(true);
    try {
      const candidateId = localStorage.getItem('candidateId');
      if (!candidateId) throw new Error('Candidate ID not found');
  
      const response = await axios.get(`${import.meta.env.VITE_BASE_URL}/api/candidate/profile`, {
        params: { candidateId },
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
  
      const {
        candidate,
        links,
        education,
        workExperience,
        skills,
      } = response.data.data;
  
      // Map education fields to match expected format
      const mappedEducation = education.map((edu) => ({
        id: edu.id,
        school: edu.school_or_college_name,
        degree: edu.degree,
        department: edu.department,
        currentlyStudying: !!edu.is_currently_studying,
        startMonth: edu.start_month,
        startYear: edu.start_year,
        endMonth: edu.end_month,
        endYear: edu.end_year,
        scoreType: edu.score_type,
        score: edu.score,
        description: edu.description,
      }));
  
      // Update profile data
      setProfileData((prevData) => ({
        ...prevData,
        name: candidate.profile_name,
        email: candidate.email,
        phone: candidate.phone_no,
        location: candidate.country,
        avatarUrl: candidate.profilePhotoUrl,
        links: links.map((link) => ({
          platform: determineLinkPlatform(link.link),
          url: link.link,
        })),
        education: mappedEducation,
        workExperience,
        skills,
      }));
    } catch (error) {
      console.error('Error fetching candidate details:', error);
    } finally {
      setLoading(false);
    }
  };
  
// Function to open edit profile modal
const handleOpenEditProfileModal = () => {
    setEditProfileData({
      profileName: profileData.name,
      country: profileData.location,
    });
    setOpenEditProfileModal(true);
  };

  // Function to update profile details
  const updatePersonalProfile = async () => {
    try {
      const candidateId = localStorage.getItem('candidateId');
      const response = await axios.put(
        `${import.meta.env.VITE_BASE_URL}/api/candidate/profile/`, 
        {
          candidateId,
          profileName: editProfileData.profileName,
          country: editProfileData.country
        },
        {
          headers: { 
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          }
        }
      );

      // Update local state if API call is successful
      if (response.data.success) {
        setProfileData(prev => ({
          ...prev,
          name: editProfileData.profileName,
          location: editProfileData.country
        }));
        setOpenEditProfileModal(false);
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      // Optionally show error toast/message
    }
  };

  // Helper function to determine link platform
  const determineLinkPlatform = (url) => {
    if (url.includes('linkedin.com')) return 'LinkedIn';
    if (url.includes('github.com')) return 'GitHub';
    return 'Portfolio';
  };

  // Add or update education
  const handleAddOrEditEducation = async (educationData) => {
    setLoading(true);
    try {
      const candidateId = localStorage.getItem('candidateId');
      const token = localStorage.getItem('token');
      
      if (!candidateId || !token) {
        console.error('Candidate ID or authorization token not found in local storage.');
        return;
      }
  
      // Format education data for the API
      const formattedEducationData = {
        schoolOrCollegeName: educationData.school,
        degree: educationData.degree,
        department: educationData.department,
        startMonth: educationData.startMonth,
        startYear: String(educationData.startYear),
        endMonth: educationData.currentlyStudying ? null : educationData.endMonth,
        endYear: educationData.currentlyStudying ? null : String(educationData.endYear),
        scoreType: educationData.scoreType,
        score: educationData.score,
        description: educationData.description,
        isCurrentlyStudying: educationData.currentlyStudying,
      };
  
      // Determine if this is an edit (existing entry) or a new addition
      const isEditing = educationData.id;
  
      // Set API method and endpoint
      const apiMethod = isEditing ? 'put' : 'post';
      const apiUrl = `${import.meta.env.VITE_BASE_URL}/api/candidate/profile/education`;
  
      // Adjust payload for backend requirement
      const apiPayload = isEditing
        ? { updatedEducation: { id: educationData.id, ...formattedEducationData } } // Include `id` for editing
        : { 
            candidateId, // Include candidateId for new education only
            educationDetails: [formattedEducationData] // Wrap in `educationDetails` for new addition
          };
  
      // Make API request
      await axios({
        method: apiMethod,
        url: apiUrl,
        data: apiPayload,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
  
      // Refresh profile data after saving
      await fetchCandidateDetails();
    } catch (error) {
      console.error('Error saving education:', error.response?.data || error.message);
      alert('Failed to save education. Please try again.');
    } finally {
      setOpenEducationModal(false);
      setSelectedEducation(null);
      setLoading(false);
    }
  };
  
  

  // Delete education
  const handleDeleteEducation = async (id) => {
    if (!window.confirm('Are you sure you want to delete this education?')) return;

    setLoading(true);
    try {
      await axios.delete(`${import.meta.env.VITE_BASE_URL}/api/candidate/profile/education`, {
        params: { id },
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });

      await fetchCandidateDetails();
    } catch (error) {
      console.error('Error deleting education:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCandidateDetails();
  }, []);



  // Edit button click handler

  const handleOpenEducationModal = () => {
    setOpenEducationModal(true);
  };
  
  const handleCloseEducationModal = () => {
    setOpenEducationModal(false);
  };
  
  const handleAddEducation = (educationData) => {
    setProfileData((prev) => ({
      ...prev,
      education: [...prev.education, educationData]
    }));
    setOpenEducationModal(false);
  };
  const handleAddLinks = (linksData) => {
    setProfileData((prev) => ({
      ...prev,
      links: linksData
    }));
  };
  const handleOpenSkillsModal = () => {
    setOpenSkillsModal(true);
  };

  // Reusable section renderer with icon and custom content
  const renderSection = (title, content, addButtonText, icon, onAddClick) => (
    <StyledCard>
      <StyledCardContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            {icon}
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              {title}
            </Typography>
          </Box>
          <StyledButton
            startIcon={<AddIcon />}
            variant="outlined"
            size="small"
            onClick={onAddClick}
          >
            {addButtonText}
          </StyledButton>
        </Box>
        {content}
      </StyledCardContent>
    </StyledCard>
  );

  // Personal information section
  const renderPersonalInfo = () => (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 3
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Avatar
            src={profileData.avatarUrl}
            sx={{
              width: 80,
              height: 80,
              border: '2px solid',
              borderColor: 'primary.main'
            }}
          />
          <Box>
            <Typography variant="h5" sx={{ fontWeight: 700 }}>
              {profileData.name}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {profileData.location}
            </Typography>
          </Box>
        </Box>
        <IconButton color="primary" onClick={handleOpenEditProfileModal}>
          <EditIcon />
        </IconButton>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          label="Email"
          value={profileData.email}
          disabled
          sx={{ backgroundColor: 'background.default' }}
        />
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          label="Phone"
          value={profileData.phone}
          disabled
          sx={{ backgroundColor: 'background.default' }}
        />
      </Box>
    </Box>
  );

  // Section content renderers
  const renderBadges = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {profileData.badges.length === 0 ? (
        <Typography variant="body2" color="text.secondary">
          You have not unlocked any badges yet. Complete your profile and start earning badges!
        </Typography>
      ) : (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          {profileData.badges.map((badge, index) => (
            <StyledChip key={index} label={badge.name} icon={<BadgeIcon />} />
          ))}
        </Box>
      )}
    </Box>
  );

  const renderCertifications = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {profileData.certifications.length === 0 ? (
        <Typography variant="body2" color="text.secondary">
          Add your professional certifications to stand out to recruiters.
        </Typography>
      ) : (
        profileData.certifications.map((cert, index) => (
          <Paper
            key={index}
            elevation={0}
            sx={{ p: 2, backgroundColor: 'background.default' }}
          >
            <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
              {cert.name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {cert.issuer} • {cert.date}
            </Typography>
          </Paper>
        ))
      )}
    </Box>
  );

  // Render work experiences
  const renderWorkExperience = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {profileData.workExperience.length === 0 ? (
        <Typography variant="body2" color="text.secondary">
          Add your work experience to showcase your professional journey.
        </Typography>
      ) : (
        profileData.workExperience.map((exp) => (
           
          <Paper
            key={exp.id}
            elevation={0}
            sx={{ p: 2, backgroundColor: 'background.default', position: 'relative' }}
          >
            <Box>
              <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                {exp.jobTitle} at {exp.companyName}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {exp.location} • {exp.employmentType}
              </Typography>
              <Typography variant="body2">{exp.description}</Typography>
            </Box>
            <Box sx={{ position: 'absolute', top: 8, right: 8, display: 'flex', gap: 1 }}>

            <IconButton color="primary" onClick={() => handleEditWorkExperience(exp)}>
              <EditIcon />
            </IconButton>
              <IconButton color="error" onClick={() => handleDeleteWorkExperience(exp.id)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          </Paper>
        ))
      )}
    </Box>
  );

  /**
   * Edit Work Experience
   */
  const handleEditWorkExperience = async (experience) => {
    setLoading(true);
    try {
      // Fetch the latest data (if needed)
      await fetchWorkExperience();
  
      // Set the selected experience for editing
      setSelectedWorkExperience(experience);
  
      // Open the modal
      setOpenWorkExperienceModal(true);
    } catch (error) {
      console.error('Error fetching data for editing:', error);
    } finally {
      setLoading(false);
    }
  }
  
  const handleSaveSkills = async (newSkills) => {
    setLoading(true);
    try {
      const candidateId = localStorage.getItem('candidateId');
      const token = localStorage.getItem('token');
  
      if (!candidateId) throw new Error('Candidate ID not found');
      if (!token) throw new Error('Authorization token not found');
  
      // Transform skills array to contain only skill strings
      const skillStrings = newSkills.map(skill => skill.skill);
  
      // Prepare payload for skills API
      const payload = {
        candidateId,
        skills: skillStrings
      };
  
      // Send skills to backend
      await axios.post(
        `${import.meta.env.VITE_BASE_URL}/api/candidate/profile/skills`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
  
      // Refresh the candidate details to get updated skills
      await fetchCandidateDetails();
  
      // Close the skills modal
      setOpenSkillsModal(false);
    } catch (error) {
      console.error('Error saving skills:', error);
      // Optionally, show an error message to the user
    } finally {
      setLoading(false);
    }
  };
  
  // Fetch data on mount
  useEffect(() => {
    fetchWorkExperience();
  }, []);

  useEffect(() => {
    fetchCandidateDetails();
  }, []);
 
  const renderEducation = () => (
    <Box>
      {profileData.education.length === 0 ? (
        <Typography>No education added yet.</Typography>
      ) : (
        profileData.education.map((edu) => (
          <Paper key={edu.id} sx={{ mb: 2, p: 2, position: 'relative' }}>
            <Typography variant="subtitle1">{edu.schoolOrCollegeName}</Typography>
            <Typography variant="body2">{edu.degree}</Typography>
            <Typography variant="body2">{edu.department}</Typography>
            <IconButton
              onClick={() => {
                setSelectedEducation(edu);
                setOpenEducationModal(true);
              }}
              color="primary"
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => handleDeleteEducation(edu.id)}
              color="error"
            >
              <DeleteIcon />
            </IconButton>
          </Paper>
        ))
      )}
    </Box>
  );

  const renderLinks = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {profileData.links.length === 0 ? (
        <Typography variant="body2" color="text.secondary">
          Add your professional and portfolio links to showcase your work.
        </Typography>
      ) : (
        profileData.links.map((link, index) => (
          <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            {link.platform === 'LinkedIn' ? (
              <LinkedInIcon color="primary" />
            ) : link.platform === 'GitHub' ? (
              <GitHubIcon color="primary" />
            ) : (
              <LanguageIcon color="primary" />
            )}
            <Link 
              href={link.url} 
              target="_blank"
              rel="noopener noreferrer"
              variant="body2" 
              sx={{ 
                color: 'primary.main',
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
            >
              {link.url}
            </Link>
          </Box>
        ))
      )}
    </Box>
  );

  const renderSkills = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
        {profileData.skills.map((skillObj, index) => (
          <StyledChip
            key={skillObj.id}
            label={skillObj.skill} // Access the skill property correctly
            variant="outlined"
            onDelete={() => {
              // Handle skill deletion
            }}
          />
        ))}
      </Box>
    </Box>
  );
  

  return (
    <Container maxWidth="xl" sx={{ py: 4 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Paper
            elevation={4}
            sx={{
              borderRadius: 3,
              overflow: 'hidden',
              boxShadow: '0 10px 20px rgba(0,0,0,0.08)',
              transition: 'all 0.3s ease',
              '&:hover': {
                boxShadow: '0 15px 25px rgba(0,0,0,0.12)'
              }
            }}
          >
            <Box
              sx={{
                p: 3,
                backgroundColor: 'background.paper',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}
            >
              <Box>
                <Box
                  sx={{
                    mb: 3,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <Typography variant="h5" sx={{ fontWeight: 700, color: 'primary.main' }}>
                    Profile
                  </Typography>
                  <Paper
                    sx={{
                      px: 2,
                      py: 1,
                      borderRadius: '20px',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      backgroundColor: 'background.default'
                    }}
                  >
                    <Typography variant="body2">Profile Completion:</Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: 600,
                        color:
                          profileData.completionPercentage >= 80
                            ? 'success.main'
                            : 'primary.main'
                      }}
                    >
                      {profileData.completionPercentage}%
                    </Typography>
                  </Paper>
                </Box>
                {renderPersonalInfo()}
              </Box>
              {/* <Box>
                {renderSection(
                  'My Badges',
                  renderBadges(),
                  'Get Badges',
                  <BadgeIcon color="primary" />,
                  () => {}
                )}
                {renderSection(
                  'My Certifications',
                  renderCertifications(),
                  'Add Certification',
                  <CertificationIcon color="primary" />,
                  () => {}
                )}
              </Box> */}
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
  <Paper
    elevation={4}
    sx={{
      borderRadius: 3,
      overflow: 'hidden',
      boxShadow: '0 10px 20px rgba(0,0,0,0.08)',
      transition: 'all 0.3s ease',
      '&:hover': {
        boxShadow: '0 15px 25px rgba(0,0,0,0.12)',
      },
    }}
  >
    <Box
      sx={{
        p: 3,
        backgroundColor: 'background.paper',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box>
        {renderSection(
          'Work Experience', // Title of the section
          loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', py: 2 }}>
              <CircularProgress />
            </Box>
          ) : (
            renderWorkExperience()
          ), // Render Work Experience or show loading spinner
          'Add Work Experience', // Button Text
          <WorkIcon color="primary" />, // Section Icon
          () => setOpenWorkExperienceModal(true) // Button action for opening modal
        )}
                {renderSection(
                  'Education',
                  renderEducation(),
                  'Add Education',
                  <SchoolIcon color="primary" />,
                  () => setOpenEducationModal(true)
                )}
              </Box>
              <Box>
              {renderSection(
  'Links',
  renderLinks(),
  'Add Links',
  <LinkedInIcon color="primary" />,
  () => setOpenLinksModal(true)  // Update this line
)}
{renderSection(
  'My Skills',
  renderSkills(),
  'Add Skills',
  <BadgeIcon color="primary" />,
  handleOpenSkillsModal
)}
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>

        {/* Edit Profile Modal */}
        <Dialog 
  open={openEditProfileModal} 
  onClose={() => setOpenEditProfileModal(false)}
  maxWidth="sm"
  fullWidth
>
  <DialogTitle>Edit Personal Information</DialogTitle>
  <DialogContent>
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 1 }}>
      <TextField
        fullWidth
        label="Profile Name"
        value={editProfileData.profileName}
        onChange={(e) => setEditProfileData(prev => ({
          ...prev, 
          profileName: e.target.value
        }))}
        variant="outlined"
      />
      <TextField
        fullWidth
        label="Country"
        value={editProfileData.country}
        onChange={(e) => setEditProfileData(prev => ({
          ...prev, 
          country: e.target.value
        }))}
        variant="outlined"
      />
    </Box>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setOpenEditProfileModal(false)}>Cancel</Button>
    <Button 
      variant="contained" 
      onClick={updatePersonalProfile}
    >
      Save Changes
    </Button>
  </DialogActions>
</Dialog>

        {/* Modals */}
        <WorkExperienceModal
        open={openWorkExperienceModal}
        onClose={() => setOpenWorkExperienceModal(false)}
        onSave={handleAddOrEditWorkExperience}
        experience={selectedWorkExperience}
      />
     <CandidateEducation
        open={openEducationModal}
        onClose={() => setOpenEducationModal(false)}
        onSave={handleAddOrEditEducation}
        educationData={selectedEducation}
      />

<CandidateLinks
    open={openLinksModal}
    onClose={() => setOpenLinksModal(false)}
    onSave={(newLinks) => {
      setProfileData((prev) => ({
        ...prev,
        links: newLinks
      }));
      setOpenLinksModal(false);
    }}
    initialLinks={profileData.links}
    fetchLatestProfile={fetchCandidateDetails} // Pass the fetch method
  />
<CandidateSkills
    open={openSkillsModal}
    onClose={() => setOpenSkillsModal(false)}
    onSave={handleSaveSkills}
    initialSkills={profileData.skills}
  />

    </Container>
  );
};

export default CandidateProfile;