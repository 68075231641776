import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Chip,
  Box,
  Typography
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';

const CandidateSkills = ({ open, onClose, onSave, initialSkills = [] }) => {
  const [skills, setSkills] = useState(initialSkills);
  const [newSkill, setNewSkill] = useState('');

  // Reset skills when modal opens
  useEffect(() => {
    if (open) {
      setSkills(initialSkills);
      setNewSkill('');
    }
  }, [open, initialSkills]);

  const handleAddSkill = () => {
    const trimmedSkill = newSkill.trim();
    if (trimmedSkill && !skills.some((s) => s.skill === trimmedSkill)) {
      const newSkillObject = { id: Date.now(), skill: trimmedSkill, order_no: skills.length + 1 };
      setSkills([...skills, newSkillObject]);
      setNewSkill('');
    }
  };

  const handleRemoveSkill = (skillToRemove) => {
    setSkills(skills.filter((skill) => skill.id !== skillToRemove.id));
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>My Skills</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            label="Add a skill"
            value={newSkill}
            onChange={(e) => setNewSkill(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleAddSkill();
              }
            }}
            InputProps={{
              endAdornment: (
                <Button
                  onClick={handleAddSkill}
                  color="primary"
                  disabled={!newSkill.trim() || skills.some((s) => s.skill === newSkill.trim())}
                >
                  <AddIcon />
                </Button>
              )
            }}
            error={skills.some((s) => s.skill === newSkill.trim())}
            helperText={skills.some((s) => s.skill === newSkill.trim()) ? "This skill already exists" : ""}
          />
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {skills.map((skill) => (
              <Chip
                key={skill.id}
                label={skill.skill}
                onDelete={() => handleRemoveSkill(skill)}
                color="primary"
                variant="outlined"
              />
            ))}
          </Box>
          {skills.length === 0 && (
            <Typography variant="body2" color="text.secondary">
              Add your skills to showcase your expertise.
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() => onSave(skills)}
          variant="contained"
          color="primary"
          disabled={skills.length === 0}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CandidateSkills;
