import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Container,
  Box,
  Skeleton,
  Paper
} from '@mui/material';
import CandidateTestDetail from './CandidateTestDetail';
import { styled } from '@mui/material/styles';

// Styled component for the card with hover effects
const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all 0.3s ease',
  borderRadius: theme.spacing(3),
  boxShadow: '0 8px 15px rgba(0,0,0,0.1)',
  cursor: 'pointer',
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: '0 12px 20px rgba(0,0,0,0.15)',
    '& .card-title': {
      color: '#1a73e8'
    }
  }
}));

const PublicTests = ({ 
  onAttemptTestRedirect, 
  isGuestMode = false 
}) => {
  const [tests, setTests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTest, setSelectedTest] = useState(null);

  useEffect(() => {
    fetchTests();
  }, []);

  // Updated fetchTests function to use the new API endpoint
  const fetchTests = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/api/candidate/fetch-public-tests`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
  
      // Updated to match the new API response structure
      if (response.data.success) {
        setTests(response.data.tests); // Note: Changed from response.data.data to response.data.tests
      } else {
        setError('No tests found');
      }
    } catch (error) {
      // Updated error handling to match new API response patterns
      const errorMessage = error.response?.status === 401 ? 'Unauthorized access. Please log in again.' :
                          error.response?.status === 404 ? 'No public tests available at this time.' :
                          error.response?.data?.error || 'An unexpected error occurred.';
      setError(errorMessage);
      console.error('Error fetching public tests:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleViewTestDetails = async (testId) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/api/org/tests/get-test-data/${testId}`,
        // {
        //   headers: {
        //     Authorization: `Bearer ${localStorage.getItem("token")}`,
        //   },
        // }
      );
      
      console.log('Test Details Response:', response.data);
      
      if
       (response.data.success) {
        console.log('Selected Test Data:', response.data.data);
        setSelectedTest(response.data.data);
      } else {
        console.error('Failed to fetch test details:', response.data);
        setError('Failed to load test details');
      }
    } catch (err) {
      console.error('Error fetching test details:', err);
      setError('Error loading test details');
    } finally {
      setLoading(false);
    }
  };
 


  const renderTestGrid = () => {
    if (loading) {
      return (
        <Grid container spacing={4}>
          {[1, 2, 3, 4, 5, 6].map((item) => (
            <Grid item xs={12} sm={6} md={4} key={item}>
              <Skeleton 
                variant="rectangular" 
                width="100%" 
                height={250} 
                sx={{ 
                  borderRadius: 4,
                  transform: 'scale(1)',
                  backgroundColor: 'rgba(0,0,0,0.06)'
                }} 
              />
            </Grid>
          ))}
        </Grid>
      );
    }

    if (error) {
      return (
        <Box 
          sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            justifyContent: 'center',
            py: 8,
            textAlign: 'center'
          }}
        >
          <Typography 
            variant="h5" 
            color="error" 
            sx={{ mb: 2, fontWeight: 600 }}
          >
            {error}
          </Typography>
        </Box>
      );
    }

    if (tests.length === 0) {
      return (
        <Box 
          sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            justifyContent: 'center',
            py: 8,
            textAlign: 'center'
          }}
        >
          <Typography 
            variant="h5" 
            sx={{ 
              mb: 2, 
              fontWeight: 600, 
              color: 'primary.main' 
            }}
          >
            No Public Tests Available
          </Typography>
          <Typography 
            variant="body1" 
            color="text.secondary"
          >
            Check back later for upcoming tests
          </Typography>
        </Box>
      );
    }

    return (
      <Grid container spacing={4}>
        {tests.map((test) => (
          <Grid item xs={12} sm={6} md={4} key={test.test_id}>
            <StyledCard 
              onClick={() => handleViewTestDetails(test.test_id)}
              elevation={3}
            >
              <CardContent 
                sx={{ 
                  display: 'flex', 
                  flexDirection: 'column', 
                  flex: 1,
                  p: 3
                }}
              >
                <Typography 
                  variant="h6" 
                  className="card-title"
                  sx={{
                    mb: 2,
                    fontWeight: 700,
                    color: 'rgba(0,0,0,0.8)',
                    transition: 'color 0.3s ease',
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden'
                  }}
                >
                  {test.test_title}
                </Typography>
                
                <Typography 
                  variant="body2" 
                  color="text.secondary"
                  sx={{
                    flexGrow: 1,
                    display: '-webkit-box',
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    mb: 2,
                    lineHeight: 1.6
                  }}
                >
                  {test.test_description}
                </Typography>
                
                {/* Added duration display since it's now available in the API response */}
                {/* <Typography 
                  variant="body2" 
                  color="primary"
                  sx={{
                    fontWeight: 500
                  }}
                >
                  Duration: {test.duration} minutes
                </Typography> */}
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {!selectedTest ? (
  renderTestGrid()
) : (
  <CandidateTestDetail 
          test={selectedTest}
          isGuestMode={isGuestMode}  // Use isGuestMode here
          onAttemptTestRedirect={onAttemptTestRedirect}
          onBack={() => setSelectedTest(null)}
        />
)}
    </Container>
  );
};

export default PublicTests;