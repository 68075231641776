import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Container,
  Grid,
  Chip,
  IconButton,
  useTheme,
  useMediaQuery,
  LinearProgress
} from '@mui/material';
import { CreditCard, Download, ArrowUpward } from '@mui/icons-material';
import axios from 'axios';
import InvoiceGenerator from './InvoiceGenerator';

const BillingInvoice = () => {
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const userId = localStorage.getItem("user_id");

  useEffect(() => {
    fetchPaymentDetails();
  }, []);

  const fetchPaymentDetails = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/api/org/payment/get-payment-details/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.data.success) {
        setPaymentDetails(response.data.data);
      } else {
        setError('No subscription found for the user');
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status === 404 &&
        error.response.data.error.includes('Payment data not found')
      ) {
        setError('No subscription found for the user');
      } else {
        console.error('Error fetching payment details:', error);
        setError('An unexpected error occurred.');
      }
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return '-';
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const formatCurrency = (amount) => {
    if (!amount) return '₹0';
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      maximumFractionDigits: 0
    }).format(amount);
  };

  const downloadInvoice = (singleRecord = false) => {
    try {
      const records = singleRecord ? [paymentDetails] : [paymentDetails];
      records.forEach(record => {
        InvoiceGenerator.generateInvoicePDF(record);
      });
    } catch (error) {
      console.error('Error generating invoice:', error);
    }
  };
  

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <LinearProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4, textAlign: 'center' }}>
        <Typography color="error">{error}</Typography>
        {error === 'No subscription found for the user' && (
          <>
            <Typography color="textSecondary" sx={{ mt: 2 }}>
              You haven't subscribed to any premium plan yet. Upgrade now to access additional features!
            </Typography>
            <Button
              variant="contained"
              color="primary"
              startIcon={<ArrowUpward />}
              onClick={() => navigate('/subscription')}
              sx={{
                mt: 3,
                borderRadius: 2,
                textTransform: 'none',
                boxShadow: 2,
              }}
            >
              Upgrade Plan
            </Button>
          </>
        )}
      </Container>
    );
  }
  

  const isPlanActive = paymentDetails && paymentDetails.planName && paymentDetails.planName !== 'Free';

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" sx={{ fontWeight: 600, color: '#1a237e' }}>
          Billing
        </Typography>
        <Typography color="textSecondary" sx={{ mt: 1 }}>
          Effortlessly handle your billing and invoices right here.
        </Typography>
      </Box>

      <Grid container spacing={4}>
        {/* Current Plan Summary */}
        <Grid item xs={12} md={8}>
          <Card 
            elevation={3}
            sx={{
              borderRadius: 2,
              background: 'linear-gradient(to right bottom, #ffffff, #f8f9fa)'
            }}
          >
            <CardContent sx={{ p: 3 }}>
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                <Typography variant="h6" sx={{ fontWeight: 600, color: '#1a237e' }}>
                  Current Plan Summary
                </Typography>
                <Button 
                  variant="contained" 
                  color="primary"
                  startIcon={<ArrowUpward />}
                  onClick={() => navigate('/subscription')}
                  sx={{
                    borderRadius: 2,
                    textTransform: 'none',
                    boxShadow: 2
                  }}
                >
                  Upgrade
                </Button>
              </Box>

              {isPlanActive ? (
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <Typography color="textSecondary" sx={{ mb: 1, fontSize: '0.875rem' }}>
                      PLAN NAME
                    </Typography>
                    <Typography variant="h6" sx={{ fontWeight: 500 }}>
                      {paymentDetails?.planName}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography color="textSecondary" sx={{ mb: 1, fontSize: '0.875rem' }}>
                      BILLING CYCLE
                    </Typography>
                    <Typography variant="h6" sx={{ fontWeight: 500 }}>
                      Monthly
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography color="textSecondary" sx={{ mb: 1, fontSize: '0.875rem' }}>
                      PLAN COST
                    </Typography>
                    <Typography variant="h6" sx={{ fontWeight: 500 }}>
                      {formatCurrency(paymentDetails?.amount)}
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <Box py={3}>
                  <Typography variant="h6" sx={{ textAlign: 'center', color: 'text.secondary' }}>
                    You haven't subscribed to any premium plan yet. Upgrade now to access additional features!
                  </Typography>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* Payment Method */}
        {isPlanActive && (
          <Grid item xs={12} md={4}>
            <Card 
              elevation={3}
              sx={{
                borderRadius: 2,
                height: '100%',
                background: 'linear-gradient(to right bottom, #ffffff, #f8f9fa)'
              }}
            >
              <CardContent sx={{ p: 3 }}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                  <Typography variant="h6" sx={{ fontWeight: 600, color: '#1a237e' }}>
                    Payment Method
                  </Typography>
                  <Button 
                    size="small" 
                    color="primary"
                    sx={{ textTransform: 'none' }}
                  >
                    Change
                  </Button>
                </Box>
                
                <Box display="flex" alignItems="center" gap={2}>
                  <CreditCard sx={{ color: '#1a237e' }} />
                  <Box>
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      •••• •••• •••• 4242
                    </Typography>
                    <Typography color="textSecondary" sx={{ fontSize: '0.875rem' }}>
                      Expires {formatDate(paymentDetails?.planValidTill)}
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        )}

        {/* Invoice History */}
        {isPlanActive && (
          <Grid item xs={12}>
            <Card 
              elevation={3}
              sx={{
                borderRadius: 2,
                background: 'linear-gradient(to right bottom, #ffffff, #f8f9fa)'
              }}
            >
              <CardContent sx={{ p: 3 }}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                  <Typography variant="h6" sx={{ fontWeight: 600, color: '#1a237e' }}>
                    Invoice History
                  </Typography>
                  <Button
  variant="contained"
  color="primary"
  startIcon={<Download />}
  onClick={() => downloadInvoice(false)}
  sx={{
    borderRadius: 2,
    textTransform: 'none',
    boxShadow: 2
  }}
>
  Download Invoice
</Button>

                </Box>

                <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ fontWeight: 600, color: '#1a237e' }}>Invoice ID</TableCell>
                        <TableCell sx={{ fontWeight: 600, color: '#1a237e' }}>Billing Date</TableCell>
                        <TableCell sx={{ fontWeight: 600, color: '#1a237e' }}>Plan</TableCell>
                        <TableCell sx={{ fontWeight: 600, color: '#1a237e' }}>Amount</TableCell>
                        <TableCell sx={{ fontWeight: 600, color: '#1a237e' }}>Status</TableCell>
                        <TableCell align="right" sx={{ fontWeight: 600, color: '#1a237e' }}>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paymentDetails && (
                        <TableRow sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}>
                          <TableCell>#{paymentDetails.paymentId}</TableCell>
                          <TableCell>{formatDate(paymentDetails.createdAt)}</TableCell>
                          <TableCell>{paymentDetails.planName}</TableCell>
                          <TableCell>{formatCurrency(paymentDetails.amount)}</TableCell>
                          <TableCell>
                            <Chip 
                              label="Paid"
                              color="success"
                              size="small"
                              sx={{ 
                                borderRadius: 1,
                                '& .MuiChip-label': { fontWeight: 500 }
                              }}
                            />
                          </TableCell>
                          <TableCell align="right">
                          <IconButton 
  size="small"
  onClick={() => downloadInvoice(true)}
  sx={{ 
    color: '#1a237e',
    '&:hover': { backgroundColor: 'rgba(26, 35, 126, 0.04)' }
  }}
>
  <Download />
</IconButton>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default BillingInvoice;
