import React from "react";
import { Box, Container } from "@mui/material";
import PublicTests from "./PublicTests";
import CandidatePrepareTests from "./CandidatePrepareTests";
import CandidateProfile from "./CandidateProfile";
import CandidateSetting from "./CandidateSetting";

const CandidateMainContent = ({
  activeTab,
  onTabChange,
  isGuestMode = false,  // Add this prop with a default
  onAttemptTestRedirect,
  currentUsername,
  onUsernameChange,
}) => {
  return (
    <Box
      sx={{
        flex: 1,
        height: "calc(100vh - 64px)", // Adjust the height to fill the available space
        overflowY: "auto", // Enable single scrollbar
        backgroundColor: "background.default", // Use the default background color
      }}
    >
     {activeTab === 'compete' && ( 
       <PublicTests 
         onTabChange={onTabChange} 
         isGuestMode={isGuestMode}
         onAttemptTestRedirect={onAttemptTestRedirect}
       />)}
      {activeTab === "prepare" && <CandidatePrepareTests />}
      {activeTab === "profile" && <CandidateProfile />}
     
      {activeTab === "settings" && (
        <CandidateSetting
          currentUsername={currentUsername}
          onUsernameChange={onUsernameChange}
        />
      )}
    </Box>
  );
};

export default CandidateMainContent;

// import React from 'react';
// import {
//   Box,
//   Typography,
//   Paper,
//   Container
// } from '@mui/material';
// import PublicTests from './PublicTests';
// import CandidatePrepareTests from './CandidatePrepareTests';
// import CandidateProfile from './CandidateProfile';
// import CandidateSetting from './CandidateSetting';

// const CandidateMainContent = ({ activeTab, onTabChange, currentUsername, onUsernameChange }) => {
//   return (
//     <Box
//       sx={{
//         flex: 1,
//         backgroundColor: 'transparent',
//         height: 'calc(100vh - 64px)', // Fixed height
//         overflowY: 'auto', // Enable single scrollbar
//         py: 4,
//         px: 2
//       }}
//     >
//       <Container maxWidth="xl" sx={{ height: '100%' }}>
//         <Paper
//           elevation={4}
//           sx={{
//             height: '100%', // Take full height of parent
//             borderRadius: 3,
//             overflow: 'hidden',
//             boxShadow: '0 10px 20px rgba(0,0,0,0.08)',
//             transition: 'all 0.3s ease',
//             '&:hover': {
//               boxShadow: '0 15px 25px rgba(0,0,0,0.12)'
//             }
//           }}
//         >
//           <Box
//             sx={{
//               p: 3,
//               backgroundColor: 'background.paper',
//               height: '100%', // Take full height of parent
//               overflowY: 'auto' // Enable scrolling within this box
//             }}
//           >
//             {activeTab === 'compete' && (
//               <PublicTests onTabChange={onTabChange} />
//             )}

//             {activeTab === 'prepare' && (
//               <CandidatePrepareTests />
//             )}

//             {activeTab === 'profile' && (
//               <CandidateProfile />
//             )}

//             {activeTab === 'settings' && (
//               <CandidateSetting
//                 currentUsername={currentUsername}
//                 onUsernameChange={onUsernameChange}
//               />
//             )}
//           </Box>
//         </Paper>
//       </Container>
//     </Box>
//   );
// };

// export default CandidateMainContent;
