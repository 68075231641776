import React, { useState, useEffect } from "react";
import Select from "react-select";
import { customStyles } from "../../constants/customStyles";

const LanguagesDropdown = ({ onSelectChange, language, languages }) => {
  const [selectedLanguage, setSelectedLanguage] = useState(null);

  // Convert languages array to the format expected by react-select
  const languageOptions =
    languages?.map((lang) => ({
      value: lang.language.toLowerCase(),
      label: lang.language,
      id: lang.language_id,
      language: lang.language,
    })) || [];

  useEffect(() => {
    if (language && languages?.length > 0) {
      const currentLanguage = {
        value: language.language.toLowerCase(),
        label: language.language,
        id: language.language_id,
        language: language.language,
      };
      setSelectedLanguage(currentLanguage);
      onSelectChange(currentLanguage);
    }
  }, [language, languages]);

  return (
    <Select
      placeholder="Select language"
      options={languageOptions}
      styles={customStyles}
      value={selectedLanguage}
      onChange={(selectedOption) => {
        setSelectedLanguage(selectedOption);
        onSelectChange(selectedOption);
      }}
    />
  );
};

export default LanguagesDropdown;
