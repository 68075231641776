import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  FaUsers,
  FaClipboardList,
  FaShoppingCart,
  FaSignOutAlt,
  FaPlus,
  FaTachometerAlt,
  FaEye,
  FaEnvelope,
  FaCalendarAlt,
  FaRegCalendarCheck,
  FaRegClipboard,
  FaChartBar,
  FaQuestionCircle,
  FaTrash,
  FaUserCircle,
  FaFileInvoiceDollar,
  FaLayerGroup,
} from "react-icons/fa";
import GroupsIcon from "@mui/icons-material/Groups";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import { GroupWork as GroupWorkIcon } from "@mui/icons-material";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
  IconButton,
  Box,
  useTheme,
} from "@mui/material";
import { showNotification } from "../../utils/notification";
import "../../css/UserDashboard.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import UserDashboardHeader from "./UserDashboardHeader";
import { v4 as uuidv4 } from "uuid";
import CreateTest from "./CreateTest";
import EditTest from "./EditTest";
import ManageParticipants from "./ManageParticipants";
import TestView from "./TestView";
import Scorecard from "./Scorecard";
import ScorecardModal from "./ScorecardModal";

import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import QuestionTypeSelector from "../AdminDashboard/QuestionTypeSelector";
import MCQQuestions from "../AdminDashboard/MCQQuestions";
import TestTypeSelector from "./TestTypeSelector";
import MCQTests from "./MCQTests";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import OrgProfile from "./OrgProfile";

import QuestionsGroups from "./QuestionGroups/QuestionsGroups";
import BillingInvoice from "./BillingInvoice";
import CombineTests from "./Tests/CombineTests";

const UserDashboard = () => {
  const theme = useTheme();

  const token = localStorage.getItem("token");
  const [tests, setTests] = useState([]);
  const [view, setView] = useState("dashboard");
  const [previousView, setPreviousView] = useState("dashboard");

  const [upcomingTests, setUpcomingTests] = useState([]);
  const [pastTests, setPastTests] = useState([]);
  const [currentTests, setCurrentTests] = useState([]);
  const [createQuestionView, setCreateQuestionView] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [selectedTestId, setSelectedTestId] = useState(null);
  const [participantEmails, setParticipantEmails] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isEmailDialogOpen, setIsEmailDialogOpen] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [showAllParticipants, setShowAllParticipants] = useState(false);
  const [bulkDeleteParticipantView, setBulkDeleteParticipantView] =
    useState(false);
  const [createParticipantView, setCreateParticipantView] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [run, setRun] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isJoyrideShown, setIsJoyrideShown] = useState(false);
  const [activeItem, setActiveItem] = useState("dashboard");
  const [notifications, setNotifications] = useState([]);
  const [bulkCreateParticipantView, setBulkCreateParticipantView] =
    useState(false);
  const [participantAllowed, setParticipantAllowed] = useState(0);
  const [alreadySavedParticipantCount, setAlreadySavedParticipantCount] =
    useState(0);
  const [canAddParticipants, setCanAddParticipants] = useState(0);
  const [csvRowCount, setCsvRowCount] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const [createBulkQuestionView, setCreateBulkQuestionView] = useState(false);
  const [questionLimit, setQuestionLimit] = useState(0);
  const [testcaseLimitPerQuestion, setTestcaseLimitPerQuestion] = useState(0);
  const [questionCount, setQuestionCount] = useState(0);
  const [remainingQuestionCount, setRemainingQuestionCount] = useState(0);
  const [isScorecardOpen, setIsScorecardOpen] = useState(false);

  const [questionType, setQuestionType] = useState(null);
  const [testType, setTestType] = useState(null);
  const [mcqTests, setMcqTests] = useState([]);
  const [selectedTestType, setSelectedTestType] = useState(null);

  // Consolidated state for dashboard data
  const [dashboardData, setDashboardData] = useState({
    testCount: 0,
    todayTestCount: 0,
    upcomingTestCount: 0,
    participantCount: 0,
    planName: "Free Plan",
    planValidity: "No validity since free plan",
    orgName: "",
    maxTests: 0,
    maxParticipants: 0,
  });

  // Handler for selecting question type
  const handleQuestionTypeSelect = (type) => {
    setQuestionType(type);
    // If 'coding' is selected, we keep the existing view
    // If 'mcq' is selected, we'll show the MCQQuestions component
    if (type === "mcq") {
      setView("mcqQuestions");
    } else {
      setView("questions");
    }
  };

  // Handler to go back to question type selection
  const handleBackToQuestionTypes = () => {
    setQuestionType(null);
    setView("questions");
  };

  const handleTestTypeSelect = (type) => {
    setTestType(type);
    if (type === "mcq") {
      setView("mcqTests");
    } else {
      setView("codingTests");
    }
  };

  const handleBackToTestTypes = () => {
    setTestType(null);
    setView("tests");
  };

  //proctor-data

  const proctoringUsage = 2; // Current usage
  const totalProctoring = 5; // Total allowed under the plan
  const topupUsage = 0; // Number of top-ups used
  const billingDate = "N/A"; // Next billing date if available

  useEffect(() => {
    if (view !== "bulkDeleteParticipantView") {
      setIsButtonDisabled(true);
      setErrorMessage("");
    }
  }, [view]);

  useEffect(() => {
    if (view !== "createBulkQuestionView") {
      setErrorMessage("");
    }
  }, [view]);

  useEffect(() => {
    if (view !== "bulkCreateParticipantView") {
      setCsvRowCount(0);
      setErrorMessage("");
    }
  }, [view]);

  useEffect(() => {
    console.log("Current view:", view);
  }, [view]);

  const handleBulkCreateParticipant = async (e) => {
    e.preventDefault();
    try {
      setBulkCreateParticipantView(true);
      setView("bulkCreateParticipant");
      const planLimitResponse = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/get-plan-id-limits`,
        {
          user_id: userId,
        }
      );
      const participant_allowed = planLimitResponse.data.max_participants;
      setParticipantAllowed(participant_allowed);
      console.log("participant_allowed", participant_allowed);

      const getParticipantCount = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/get-participant-count`,
        {
          user_id: userId,
        }
      );
      const already_saved_participant_count =
        getParticipantCount.data.participant_count;
      setAlreadySavedParticipantCount(already_saved_participant_count);
      console.log(
        "already_saved_participant_count",
        already_saved_participant_count
      );

      const can_add_participants =
        participant_allowed - already_saved_participant_count;
      setCanAddParticipants(can_add_participants);
      console.log("can_add_participants", can_add_participants);
    } catch (error) {
      console.error("Error bulk uploading participant data", error);
    }
  };

  const handleCSVUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("csvFile", file);
    formData.append("user_id", userId);

    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/count-csv-rows`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setCsvRowCount(response.data.rowCount);
    } catch (error) {
      console.error("Error uploading CSV file", error);
    }
  };

  const handleBulkSaveParticipant = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/bulk-add-participants`,
        {
          user_id: userId,
        }
      );
      if (response.status === 200) {
        setBulkCreateParticipantView(false);
        setView("participants");
      } else {
        // setErrorMessage(response.data.error || response.data.message);
        if (response.data.error) {
          setErrorMessage(response.data.error);
        } else if (response.data.message) {
          setErrorMessage(response.data.message);
        }
      }
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error ||
          error.response?.data?.message ||
          "Internal server error"
      );
    }
  };

  //delete bulk participant
  const handleBulkDeleteParticipant = async (e) => {
    e.preventDefault();
    try {
      setBulkDeleteParticipantView(true);
      setView("bulkDeleteParticipant");
    } catch (error) {
      console.error("Error initializing bulk delete participant view", error);
    }
  };

  const handleCSVDeleteUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("csvFile", file);
    formData.append("user_id", userId);

    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/check-validations-and-save-file`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const { invalidEmails, duplicateEmails } = response.data;

      if (invalidEmails.length === 0 && duplicateEmails.length === 0) {
        setIsButtonDisabled(false);
      } else {
        if (invalidEmails.length !== 0) {
          setErrorMessage(`File contains invalid emails ${invalidEmails}`);
          setIsButtonDisabled(true);
        } else if (duplicateEmails.length !== 0) {
          setErrorMessage(`File contains duplicate emails ${duplicateEmails}`);
          setIsButtonDisabled(true);
        }
      }
    } catch (error) {
      console.error("Error uploading CSV file for deletion", error);
      setIsButtonDisabled(true);
    }
  };

  const handleBulkDeleteParticipantSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.delete(
        `${import.meta.env.VITE_BASE_URL}/bulk-delete-org-participants`,
        {
          params: {
            user_id: userId,
          },
        }
      );
      if (response.status === 200) {
        setBulkDeleteParticipantView(false);
        setView("participants");
        setIsButtonDisabled(true);
      } else {
        if (response.data.error) {
          setErrorMessage(response.data.error);
        } else if (response.data.message) {
          setErrorMessage(response.data.message);
        }
      }
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error ||
          error.response?.data?.message ||
          "Internal server error"
      );
    }
  };

  //show participant
  const handleShowAllParticipants = async () => {
    try {
      const response = await fetch(
        `${import.meta.env.VITE_BASE_URL}/get-all-participants`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ user_id: userId }), // Replace userId with the actual user ID
        }
      );
      const data = await response.json();
      if (response.ok) {
        setParticipants(data.data);
        setShowAllParticipants(true);
      } else {
        showNotification("error", data.message);
      }
    } catch (error) {
      console.error("Error fetching participants:", error);
    }
  };

  const handleDeleteParticipant = async (email) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this participant?"
    );
    if (confirmDelete) {
      try {
        const response = await fetch(
          `${import.meta.env.VITE_BASE_URL}/delete-org-participant`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email, user_id: userId }), // Replace userId with the actual user ID
          }
        );
        const data = await response.json();
        if (response.ok) {
          showNotification("success", data.message);
          setParticipants(participants.filter((p) => p.email !== email));
        } else {
          showNotification("error", data.message);
        }
      } catch (error) {
        console.error("Error deleting participant:", error);
      }
    }
  };
  // Questions view // to show testcases using eye button
  const [selectedQuestionTestCases, setSelectedQuestionTestCases] = useState(
    []
  );
  const [isTestCaseDialogOpen, setIsTestCaseDialogOpen] = useState(false);

  const handleShowTestCases = (testCases) => {
    setSelectedQuestionTestCases(testCases);
    setIsTestCaseDialogOpen(true);
  };
  // ------------------------------------------------------
  // Questions view // to open edit question page when clicked on edit icon
  const handleEditQuestion = (questionId) => {
    navigate(`/edit-question/${questionId}`);
  };

  //---------------------------------------------------------

  // Placeholder redirect function
  const redirectTo = (section) => {
    switch (section) {
      case "participant":
        handleSetView("participants");
        break;
      case "tests":
        handleSetView("tests");
        break;
      case "plan":
        break;
      case "planValidity":
        break;
      case "upcomingTests":
        handleSetView("upcomingTests");
        break;
      case "currentTest":
        handleSetView("currentTests");
        break;
      default:
        console.log(`No redirection defined for ${section}`);
    }
  };

  const upgradePlan = () => {
    // Redirect to subscription page
    navigate("/subscription");
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  useEffect(() => {
    const joyrideShown = localStorage.getItem("isJoyrideShown");
    if (joyrideShown === "true") {
      setIsJoyrideShown(true);
      setRun(false); // Don't run Joyride if it has been shown
    } else if (isLoggedIn) {
      setRun(true); // Run Joyride if it hasn't been shown and user is logged in
    }
  }, [isLoggedIn]);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    if (status === "finished" || status === "skipped") {
      localStorage.setItem("isJoyrideShown", "true");
      setIsJoyrideShown(true);
      setRun(false);
    }
  };

  const userId = localStorage.getItem("user_id");
  const userName = localStorage.getItem("user_name");

  const navigate = useNavigate();
  // const token = localStorage.getItem("token");
  // if (!token) {
  //   navigate("/login");
  // }

  // api calls for fetching data on dashboard----------------------------------------------
  const [orgName, setOrgName] = useState("");
  const [participantCount, setParticipantCount] = useState("");
  const [testCount, setTestCount] = useState("");
  const [planPurchased, setPlanPurchased] = useState("Free Plan");
  const [planValidity, setPlanValidity] = useState(
    "No validity since free plan"
  );
  const [upcomingTestCount, setUpcomingTestCount] = useState("");
  const [todayTestCount, setTodayTestCount] = useState("");

  const fetchDashboardData = async () => {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/get-tests-count`,
        { user_id: userId },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setTestCount(response.data.tests_count);
      setTodayTestCount(response.data.today_test_count);
      setUpcomingTestCount(response.data.upcoming_test_count);

      const participantCountResponse = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/get-participant-count`,
        { user_id: userId },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setParticipantCount(participantCountResponse.data.participant_count);

      try {
        // Attempt to get plan details
        const planDetailResponse = await axios.post(
          `${import.meta.env.VITE_BASE_URL}/get-plan-name-and-validity`,
          { user_id: userId },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        setPlanPurchased(planDetailResponse.data.plan_name);
        setPlanValidity(planDetailResponse.data.plan_valid_till);
        setOrgName(planDetailResponse.data.username);
      } catch (error) {
        // If the error is a 404, it means no plan exists, so create a free plan entry
        if (error.response && error.response.status === 404) {
          await axios.post(
            `${
              import.meta.env.VITE_BASE_URL
            }/api/payment/handle-free-plan-click`,
            { user_id: userId },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
        } else {
          console.error("Error fetching plan details:", error);
        }
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };

  // const fetchUserTests = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${import.meta.env.VITE_BASE_URL}/tests/user/${userId}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         },
  //       }
  //     );
  //     const tests = response.data;
  //     const now = new Date();
  //     setUpcomingTests(tests.filter((test) => new Date(test.test_date) > now));
  //     setPastTests(tests.filter((test) => new Date(test.test_date) < now));
  //     setCurrentTests(tests.filter((test) => new Date(test.test_date) === now));

  //     setTests(tests);
  //   } catch (error) {
  //     console.error("Error fetching user tests:", error);
  //   }
  // };

  useEffect(() => {
    if (view === "participants") {
      fetchUserTests();
    }
  }, [view]);

  const fetchUserTests = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/api/org/tests`,
        {
          // params: { createdBy: userId },
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.data.success) {
        setTests(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching user tests:", error);
      toast.error("Failed to fetch tests");
    }
  };

  useEffect(() => {
    fetchUserTests();
    fetchMcqTests();

    fetchDashboardData();
  }, []);

  const handleCreateTest = async () => {
    try {
      const getPlanIdResponse = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/get-plan-id-limits`,
        {
          user_id: userId,
        }
      );
      const max_test = getPlanIdResponse.data.max_tests;
      const getTestsCount = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/get-tests-count`,
        {
          user_id: userId,
        }
      );
      const test_count = getTestsCount.data.tests_count;
      if (test_count < max_test) {
        const response = await axios.post(
          `${import.meta.env.VITE_BASE_URL}/tests-user/generate-id`,
          {},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        setView("createTest");
        setSelectedTestId(response.data.test_id);
      } else {
        showNotification(
          "error",
          "You have reached your test creation limit. Upgrade your plan to create more tests or delete existing tests."
        );
      }
    } catch (error) {
      console.error("Error creating test:", error);
    }
  };

  const handleTestCreated = (newTest) => {
    console.log("New Test:", newTest); // Log the new test data

    if (newTest && newTest.test_id) {
      setTests((prevTests) => [...prevTests, newTest]); // Add the new test to the list of tests
      setView("tests"); // Navigate back to the tests view
    } else {
      console.error("New test data is incomplete or invalid.");
    }
  };

  const handleDeleteTest = async (testId) => {
    const userConfirmed = window.confirm(
      "Are you sure you want to delete this test?"
    );
    if (userConfirmed) {
      try {
        await axios.delete(`${import.meta.env.VITE_BASE_URL}/tests/${testId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        fetchUserTests();
      } catch (error) {
        console.error("Error deleting test:", error);
      }
    }
  };

  const handleUpdateTests = (updatedTest) => {
    setTests((prevTests) =>
      prevTests.map((test) =>
        test.test_id === updatedTest.test_id ? updatedTest : test
      )
    );
    setView("tests"); // Ensure this is the correct view
  };

  const handleEditTest = (testId) => {
    console.log("Selected Test ID for Edit:", testId); // Debugging
    setSelectedTestId(testId);
    setView("editTest");
  };

  const handleViewTest = (testId) => {
    setSelectedTestId(testId);
    // console.log(testId);
    setView("viewTest");
  };

  const handleManageParticipants = (testId) => {
    setSelectedTestId(testId);
    setView("manageParticipants");
  };

  const handleBackToTests = () => {
    setView("tests");
  };

  const Logouthandle = () => {
    localStorage.clear();
    navigate("/login");
  };

  const handleViewScorecard = (testId, testType) => {
    const selectedTest = [...tests, ...mcqTests].find(
      (test) => test.test_id === testId || test.mcq_test_id === testId
    );

    if (!selectedTest || selectedTest.participant_count === 0) {
      toast.warning("No participants have taken this test yet.");
      return;
    }

    setSelectedTestId(testId);
    setSelectedTestType(testType);
    setView("scorecard");
  };

  const handleCloseScorecard = () => {
    setIsScorecardOpen(false);
    setSelectedTestId(null);
  };

  // Create Question View ------------------------------------------------------------------
  const [questionId, setQuestionId] = useState("");
  const [questionTitle, setQuestionTitle] = useState("");
  const [questionDescription, setQuestionDescription] = useState("");
  const [step, setStep] = useState(1);

  // const handleCreateQuestion = async () => {
  //   setView("createQuestion");
  //   setCreateQuestionView(true);
  // };

  const handleCreateQuestion = async () => {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/get-plan-id-limits`,
        {
          user_id: userId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const questionLimit = response.data.max_question;
      console.log("questionLimit", questionLimit);

      const questionCountResponse = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/get-question-count`,
        {
          created_by: userId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const questionCount = questionCountResponse.data.question_count;
      console.log("question_count", questionCount);

      if (questionCount < questionLimit) {
        setView("createQuestion");
        setCreateQuestionView(true);
      } else {
        showNotification(
          "error",
          "Question creation limit reached, upgrade plan or delete existing questions"
        );
        return;
      }
    } catch (error) {
      console.error("error creating question", error);
    }

    // setView("createQuestion");
    // setCreateQuestionView(true);
  };

  const handleGoBack = () => {
    setCreateQuestionView(false);
    setView("questions");
  };

  const handleSubmitQuestion = async () => {
    try {
      const newQuestionId = uuidv4();
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/create-question`,
        {
          questionId: newQuestionId,
          questionTitle,
          questionDescription,
          created_by: userId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log(response.data);
      setQuestionTitle("");
      setQuestionDescription("");
      setQuestionId(newQuestionId);
      return true;
    } catch (error) {
      console.error("error creating question frontend", error);
      return false;
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const success = await handleSubmitQuestion();
    if (success) {
      setStep(2);
    }
  };

  // create testcases view--------------------------------------------------------
  const [testCases, setTestCases] = useState([]);
  const [input, setInput] = useState("");
  const [output, setOutput] = useState("");
  const [points, setPoints] = useState(50);

  const handleTestcaseSubmit = async (e) => {
    e.preventDefault();
    try {
      const planResponse = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/get-plan-id-limits`,
        {
          user_id: userId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const testcaseLimit = planResponse.data.max_testcases_per_question;
      console.log("testcaseLimit", testcaseLimit);

      const testcaseCountResponse = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/get-testcase-count`,
        {
          question_id: questionId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const testcaseCount = testcaseCountResponse.data.testcase_count;
      console.log("testcaseCount", testcaseCount);

      if (testcaseCount < testcaseLimit) {
        const response = await axios.post(
          `${import.meta.env.VITE_BASE_URL}/create-testcase/${questionId}`,
          {
            input,
            output,
            points,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setInput("");
        setOutput("");
        setPoints(50);
        setTestCases([...testCases, { input, output }]);
        console.log(questionId);
        console.log(response.data);
      } else {
        showNotification("error", "Test case creation limit reached");
        return;
      }
    } catch (error) {
      console.error("error creating testcase", error);
    }
  };

  const handleGoBackToQuestions = () => {
    setStep(1);
    setView("questions");
    setTestCases([]);
  };

  // Create Participant View ------------------------------------------------------------------

  const handleCreateParticipant = () => {
    setView("createParticipant");
    setCreateParticipantView(true);
  };

  const handleGoBackToParticipants = () => {
    setCreateParticipantView(false);
    setView("participants");
    setIsButtonDisabled(true);
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSaveParticipant = async (e) => {
    e.preventDefault();
    try {
      const getPlanIdResponse = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/get-plan-id-limits`,
        {
          user_id: userId,
        }
      );

      const participant_allowed = getPlanIdResponse.data.max_participants;
      console.log("participant_allowed", participant_allowed);

      const getParticipantCount = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/get-participant-count`,
        {
          user_id: userId,
        }
      );

      const participantCount = getParticipantCount.data.participant_count;
      console.log("participantCount", participantCount);

      if (participantCount < participant_allowed) {
        if (!validateEmail(email)) {
          toast.error("Please enter a valid email address", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          return;
        }

        const response = await axios.post(
          `${import.meta.env.VITE_BASE_URL}/create-participant`,
          {
            user_id: userId,
            first_name: firstName,
            last_name: lastName,
            email,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        console.log(response.data);

        // Success toast
        toast.success("Participant added successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        // Reset form fields
        setFirstName("");
        setLastName("");
        setEmail("");
      } else {
        // Plan limit exceeded toast
        toast.error(
          "You have reached your limit for adding participants. Upgrade your plan to add more participants or delete existing participants",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    } catch (error) {
      console.error("error creating participant", error);

      // Error toast for failed participant creation
      toast.error(
        error.response?.data?.message ||
          "Failed to add participant. Please try again.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
  };

  // ---------------------------------------------------------------------------------

  const handleGoBackToTests = () => {
    setView("tests");
  };

  useEffect(() => {
    if (view === "questions") {
      fetchQuestions();
    } else if (view === "upcomingTests") {
      fetchUpcomingTests();
    } else if (view === "currentTests") {
      fetchCurrentTests();
    } else if (view === "pastTests") {
      fetchPastTests();
    }
  }, [view]);

  const fetchQuestions = async () => {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/get-all-questions-content-ud`,
        {
          created_by: userId,
        }
      );
      setQuestions(response.data);
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  };

  // const fetchParticipants = async (testId) => {
  //   try {
  //     const response = await axios.get(
  //       `${import.meta.env.VITE_BASE_URL}/get-participant-details/${testId}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         },
  //       }
  //     );
  //     setParticipantEmails(response.data);
  //     setIsDialogOpen(true);
  //   } catch (error) {
  //     console.error("Error fetching participants:", error);
  //     setParticipantEmails([]);
  //     setIsDialogOpen(true); // Open the dialog even if there is an error to show "no participants found"
  //   }
  // };

  const fetchParticipants = async (testId) => {
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/get-participant-details/${testId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setParticipantEmails(response.data);
      setIsDialogOpen(true);
    } catch (error) {
      console.error("Error fetching participants:", error);
      setParticipantEmails([]);
      setIsDialogOpen(true);
    }
  };

  const handleShowParticipants = (testId) => {
    setSelectedTestId(testId);
    fetchParticipants(testId);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const formatTestDate = (testDate) => {
    const date = new Date(testDate);
    return date.toLocaleDateString();
  };

  const fetchUpcomingTests = async () => {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/upcoming-tests`,
        {
          created_by: userId,
        }
      );
      setUpcomingTests(response.data);
    } catch (error) {
      console.error("Error fetching upcoming tests:", error);
    }
  };

  const fetchCurrentTests = async () => {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/current-tests`,
        {
          created_by: userId,
        }
      );
      setCurrentTests(response.data);
    } catch (error) {
      console.error("Error fetching current tests:", error);
    }
  };

  const fetchPastTests = async () => {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/past-tests`,
        {
          created_by: userId,
        }
      );
      setPastTests(response.data);
    } catch (error) {
      console.error("Error fetching past tests:", error);
    }
  };

  // const handleSendEmail = (testId) => {
  //   setSelectedTestId(testId);
  //   setIsEmailDialogOpen(true);
  // };

  const handleSendEmail = (testId) => {
    const selectedTest = [...tests, ...mcqTests].find(
      (test) => test.test_id === testId || test.mcq_test_id === testId
    );

    if (!selectedTest || selectedTest.participant_count === 0) {
      showNotification(
        "warning",
        "Please add participants before sending emails."
      );
      return;
    }

    setSelectedTestId(testId);
    setIsEmailDialogOpen(true);
  };

  // const handleEmailSendConfirmation = async () => {
  //   setIsEmailDialogOpen(false);

  //   try {
  //     await axios.post(
  //       `${import.meta.env.VITE_BASE_URL}/send-emails/${selectedTestId}`,
  //       {},
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         },
  //       }
  //     );
  //     console.log("Emails sent successfully!");
  //   } catch (error) {
  //     console.error("Error sending emails:", error);
  //   }
  // };

  const handleEmailSendConfirmation = async () => {
    setIsEmailDialogOpen(false);

    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/api/mail/notify/${selectedTestId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        console.log("Emails sent successfully!");
        toast.success("Emails sent successfully!");
      } else {
        throw new Error(response.data.error || "Failed to send emails");
      }
    } catch (error) {
      console.error("Error sending emails:", error);
      if (error.response?.status === 404) {
        toast.warning(
          "No participants found for this test. Please add participants before sending emails."
        );
      } else {
        toast.error(
          error.message || "Failed to send emails. Please try again."
        );
      }
    }
  };

  const closeEmailDialog = () => {
    setIsEmailDialogOpen(false);
  };

  const steps = [
    {
      target: ".user-sidebar-nav li:nth-child(1)",
      content:
        "Here you can see overall statistics of your organization that total no of participant,test,etc you have created till now",
    },
    {
      target: ".user-sidebar-nav li:nth-child(2)",
      content:
        "Here you can create new tests and see the past, current, and upcoming tests",
    },
    {
      target: ".user-sidebar-nav li:nth-child(3)",
      content:
        "Here you can create questions for your test and see the number of questions created till now",
    },

    {
      target: ".user-sidebar-nav li:nth-child(4)",
      content:
        "Here you can see the summary of all the participants who have participated in your tests",
    },
    {
      target: ".user-info .user-logout-btn",
      content: "Use this to logout from the dashboard to login page",
    },
  ];

  const handleSetView = (view) => {
    setView(view);
    setActiveItem(view);
    if (view === "questions") {
      // Reset question type when entering the questions view
      setQuestionType(null);
    }
  };

  const handleLogout = () => {
    navigate("/login");
  };
  const organization = {
    name: "Tanmay",
  };

  const user = {
    name: "John Doe",
  };

  const handleDeleteQuestion = async (questionId) => {
    try {
      const userConfirmation = window.confirm(
        "Are you sure you want to delete this question"
      );
      if (userConfirmation) {
        await axios.delete(
          `${import.meta.env.VITE_BASE_URL}/delete-question/${questionId}`
        );
        fetchQuestions();
      }
    } catch (error) {
      console.error(
        "Error deleting question and it's associated testcases",
        error
      );
    }
  };

  const handleBulkCreateQuestionBtnClick = async (e) => {
    e.preventDefault();
    try {
      setView("createBulkQuestions");
      setCreateBulkQuestionView(true);

      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/get-plan-id-limits`,
        {
          user_id: userId,
        }
      );

      const questionLimit = response.data.max_question;
      console.log("questionLimit", questionLimit);
      setQuestionLimit(questionLimit);

      const testcaseLimitPerQuestion = response.data.max_testcases_per_question;
      console.log("testcaseLimitPerQuestion", testcaseLimitPerQuestion);
      setTestcaseLimitPerQuestion(testcaseLimitPerQuestion);

      const questionCountResponse = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/get-question-count`,
        {
          created_by: userId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const questionCount = questionCountResponse.data.question_count;
      console.log("question_count", questionCount);
      setQuestionCount(questionCount);

      const remainingQuestionCount = questionLimit - questionCount;
      console.log("remainingQuestionCount", remainingQuestionCount);
      setRemainingQuestionCount(remainingQuestionCount);
    } catch (error) {
      console.error("Error for bulk uploading questions", error);
    }
  };

  const handleBulkCreateQuestions = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/bulk-question-and-testcase-upload`,
        {
          user_id: userId,
          created_by: userId,
        }
      );

      if (response.status === 200) {
        setCreateBulkQuestionView(false);
        setView("questions");
      }
    } catch (error) {
      setErrorMessage("Internal server error");
    }
  };

  const handleCreateBulkQuestionsCSVUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("csvFile", file);
    formData.append("user_id", userId);
    formData.append("created_by", userId);

    try {
      // First API call to count rows and columns
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/count-csv-rows`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setCsvRowCount(response.data.rowCount);
      const columnCount = response.data.columnCount;
      console.log("columnCount", columnCount);

      if (columnCount === 0) {
        setErrorMessage("No data present in file");
        return;
      }

      // Second API call to get the max testcase number
      const testcaseCountResponse = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/get-max-testcase-number`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const maxTestcaseNumber = testcaseCountResponse.data.maxTestcaseNumber;
      console.log("maxTestcaseNumber", maxTestcaseNumber);

      if (maxTestcaseNumber > testcaseLimitPerQuestion) {
        setErrorMessage(
          `You have added more than ${testcaseLimitPerQuestion} test cases per question, please remove them`
        );
      }
    } catch (error) {
      console.error("Error uploading CSV file", error);
      setErrorMessage("Failed to process the CSV file");
    }
  };

  const handleGoBackToQuestionsView = () => {
    setCreateBulkQuestionView(true);
    setView("questions");
  };

  const fetchMcqTests = async () => {
    try {
      const response = await axios.get(
        `${
          import.meta.env.VITE_BASE_URL
        }/api/org/participant/fetch-mcq-tests-data/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setMcqTests(response.data.data.mcqTests);
    } catch (error) {
      console.error("Error fetching MCQ tests:", error);
    }
  };

  const handleEditProfile = () => {
    console.log("Edit profile clicked");
    setPreviousView(view);
    setView("profile");
    handleSetView("profile");
    setActiveItem("dashboard");
  };

  const handleBackFromProfile = () => {
    const fallbackView = "dashboard";
    setView(previousView || fallbackView);
    setActiveItem(previousView || fallbackView);
  };

  return (
    <>
      <UserDashboardHeader
        organizationName={orgName}
        onEditProfile={handleEditProfile}
        onLogout={handleLogout}
      />

      <div>
        <div className="user-dashboard-container">
          <aside className="user-sidebar">
            <ul className="user-sidebar-nav">
              <li
                onClick={() => handleSetView("dashboard")}
                className={activeItem === "dashboard" ? "active" : ""}
              >
                <DashboardCustomizeIcon sx={{ fontSize: 20 }} />
                <span>Dashboard</span>
              </li>
              <li
                onClick={() => handleSetView("tests")}
                className={activeItem === "tests" ? "active" : ""}
              >
                <FaClipboardList />
                <span>Tests</span>
              </li>
              <li
                onClick={() => handleSetView("questionGroups")}
                className={activeItem === "questionGroups" ? "active" : ""}
              >
                <GroupWorkIcon sx={{ fontSize: 20 }} />
                <span>Question Groups</span>
              </li>
              <li
                onClick={() => handleSetView("questions")}
                className={activeItem === "questions" ? "active" : ""}
              >
                <FaQuestionCircle />
                <span>Questions</span>
              </li>

              <li
                onClick={() => handleSetView("participants")}
                className={activeItem === "participants" ? "active" : ""}
              >
                <FaUsers />
                <span>Participants</span>
              </li>
              <li
                onClick={() => handleSetView("billing")}
                className={activeItem === "billing" ? "active" : ""}
              >
                <FaFileInvoiceDollar />
                <span>Billing & Invoice</span>
              </li>
              <li
                onClick={() => handleSetView("profile")}
                className={activeItem === "profile" ? "active" : ""}
              >
                <FaUserCircle />
                <span>Profile</span>
              </li>

              {/* <li
                onClick={() => handleSetView("scorecard")}
                className={activeItem === "scorecard" ? "active" : ""}
              >
                <FaChartBar />
                <span>Scorecard</span>
              </li> */}
            </ul>
          </aside>
          <main className="user-dashboard-content">
            <div className="user-content-container">
              {/* Debug output */}
              {console.log("Rendering view:", view)}

              {/* Profile view */}
              {view === "profile" && (
                <div className="w-full">
                  <div className="flex items-center mb-4">
                    <button
                      onClick={handleBackFromProfile}
                      className="flex items-center text-blue-600 hover:text-blue-800"
                    >
                      <ArrowBackIcon />
                      <span className="ml-1">Back</span>
                    </button>
                  </div>
                  {/* <h2 className="text-2xl font-semibold mb-4">Organization Profile</h2> */}
                  <OrgProfile
                    onUpdateProfile={() => {
                      handleBackFromProfile();
                    }}
                  />
                </div>
              )}

              {view === "dashboard" && (
                <>
                  {/* Header Section */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mt: 4,
                      mb: 4,
                      ml: 4,
                      gap: 2,
                    }}
                  >
                    <DashboardCustomizeIcon
                      sx={{
                        fontSize: 38,
                        color: theme.palette.primary.main,
                      }}
                    />
                    <Typography variant="h4" component="h1">
                      Organization Statistics
                    </Typography>
                  </Box>
                  <div className="statistics-cards">
                    <div
                      className="stat-card"
                      onClick={() => redirectTo("participant")}
                    >
                      <div className="card-content">
                        <FaUsers className="card-icon" />
                        <h3>Total No of Participants</h3>
                        <p>{participantCount}</p>
                      </div>
                    </div>
                    <div
                      className="stat-card"
                      onClick={() => redirectTo("tests")}
                    >
                      <div className="card-content">
                        <FaClipboardList className="card-icon" />
                        <h3>Total No of Tests</h3>
                        <p>{testCount}</p>
                      </div>
                    </div>
                    <div
                      className="stat-card"
                      onClick={() => redirectTo("plan")}
                    >
                      <div className="card-content">
                        <FaShoppingCart className="card-icon" />
                        <h3>Plan Purchased</h3>
                        <p>{planPurchased}</p>
                      </div>
                    </div>
                    <div
                      className="stat-card"
                      onClick={() => redirectTo("planValidity")}
                    >
                      <div className="card-content">
                        <FaCalendarAlt className="card-icon" />
                        <h3>Plan Validity</h3>
                        <p>{planValidity}</p>
                      </div>
                    </div>
                    <div
                      className="stat-card"
                      onClick={() => redirectTo("upcomingTests")}
                    >
                      <div className="card-content">
                        <FaRegCalendarCheck className="card-icon" />
                        <h3>Upcoming Tests</h3>
                        <p>{upcomingTestCount}</p>
                      </div>
                    </div>
                    <div
                      className="stat-card"
                      onClick={() => redirectTo("currentTest")}
                    >
                      <div className="card-content">
                        <FaRegClipboard className="card-icon" />
                        <h3>Current Test</h3>
                        <p>{todayTestCount}</p>
                      </div>
                    </div>

                    {/* <div className="stat-card">
                      <div className="card-content">
                        <FaEye className="card-icon" />
                        <h3>Proctoring Usage</h3>
                        <p>
                          Proctoring Usage: {proctoringUsage}/{totalProctoring}
                        </p>
                        <p>Top-up Usage: {topupUsage}</p>
                        <p>Next Billing Date: {billingDate}</p>
                      </div>
                    </div> */}
                  </div>

                  <button className="upgrade-button" onClick={upgradePlan}>
                    Upgrade Plan
                  </button>
                </>
              )}

              {/* {view === "tests" && (
                <TestTypeSelector onSelectType={handleTestTypeSelect} />
              )} */}
              {view === "tests" && <CombineTests />}

              {view === "codingTests" && (
                <>
                  <Button
                    startIcon={<ArrowBackIcon />}
                    onClick={handleBackToTestTypes}
                    sx={{ mb: 2, display: "flex", alignItems: "flex-start" }}
                  >
                    Back to Test Selection
                  </Button>
                  <h1 className="user-header">Coding Tests</h1>
                  <div className="user-create-test-container">
                    <button
                      onClick={handleCreateTest}
                      className="user-create-test-btn"
                    >
                      <FaPlus />
                      <span>Create New Coding Test</span>
                    </button>
                  </div>
                  <div className="user-tabs">
                    <button
                      className="user-tab"
                      onClick={() => setView("upcomingTests")}
                    >
                      Upcoming Tests
                    </button>
                    <button
                      className="user-tab"
                      onClick={() => setView("pastTests")}
                    >
                      Past Tests
                    </button>
                    <button
                      className="user-tab"
                      onClick={() => setView("currentTests")}
                    >
                      Current Test
                    </button>
                  </div>

                  <table className="user-dashboard-table">
                    <thead>
                      <tr>
                        <th>Test ID</th>
                        <th>Title</th>
                        <th>Description</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tests.map((test) => (
                        <tr key={test.test_id}>
                          <td>{test.test_id}</td>
                          <td>{test.test_title}</td>
                          <td>{test.test_description}</td>
                          <td>
                            <div className="actions-container">
                              <i
                                className="fas fa-edit"
                                title="Edit test"
                                onClick={() => handleEditTest(test.test_id)}
                              ></i>
                              <i
                                className="fas fa-users"
                                title="Manage participants"
                                onClick={() =>
                                  handleManageParticipants(test.test_id)
                                }
                              ></i>
                              <i
                                className="fas fa-eye"
                                title="View test"
                                onClick={() => handleViewTest(test.test_id)}
                              ></i>
                              <i
                                className="fas fa-chart-bar" // Using chart-bar icon for scorecard
                                title="View Scorecard"
                                onClick={() =>
                                  handleViewScorecard(test.test_id, "coding")
                                } // Add test type parameter
                              ></i>
                              <i
                                className="fas fa-trash-alt"
                                title="Delete test"
                                onClick={() => handleDeleteTest(test.test_id)}
                              ></i>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}

              {view === "mcqTests" && (
                <MCQTests onBack={handleBackToTestTypes} />
              )}
              {view === "createTest" && (
                <CreateTest
                  onClose={handleBackToTests}
                  testId={selectedTestId}
                  onTestCreated={handleTestCreated}
                />
              )}

              {view === "editTest" && selectedTestId && (
                <EditTest
                  testId={selectedTestId}
                  onClose={() => setView("tests")}
                  onUpdate={handleUpdateTests} // Pass this to update test data
                />
              )}

              {view === "viewTest" && selectedTestId && (
                <TestView
                  testId={selectedTestId}
                  onClose={handleBackToTests}
                  // Pass any additional props needed
                />
              )}

              {view === "manageParticipants" && selectedTestId && (
                <ManageParticipants
                  testId={selectedTestId}
                  onClose={handleBackToTests}
                  // Pass any additional props needed
                />
              )}
              {view === "upcomingTests" && (
                <>
                  <h2>Upcoming Tests</h2>
                  <table className="user-dashboard-table">
                    <thead>
                      <tr>
                        <th>Test Title</th>
                        <th>Test Date (MM/DD/YYYY)</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {upcomingTests.map((test) => (
                        <tr key={test.test_title}>
                          <td>{test.test_title}</td>
                          <td>{formatTestDate(test.test_date)}</td>
                          <td>{test.start_time}</td>
                          <td>{test.end_time}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="user-back-button-container">
                    <button
                      onClick={handleGoBackToTests}
                      className="user-back-button"
                    >
                      Back
                    </button>
                  </div>
                </>
              )}
              {view === "pastTests" && (
                <>
                  <h2>Past Tests</h2>
                  <table className="user-dashboard-table">
                    <thead>
                      <tr>
                        <th>Test Title</th>
                        <th>Test Date (MM/DD/YYYY)</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pastTests.map((test) => (
                        <tr key={test.test_title}>
                          <td>{test.test_title}</td>
                          <td>{formatTestDate(test.test_date)}</td>
                          <td>{test.start_time}</td>
                          <td>{test.end_time}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="user-back-button-container">
                    <button
                      onClick={handleGoBackToTests}
                      className="user-back-button"
                    >
                      Back
                    </button>
                  </div>
                </>
              )}
              {view === "currentTests" && (
                <>
                  <h2>Current Tests</h2>
                  <table className="user-dashboard-table">
                    <thead>
                      <tr>
                        <th>Test Title</th>
                        <th>Test Date (MM/DD/YYYY)</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentTests.map((test) => (
                        <tr key={test.test_title}>
                          <td>{test.test_title}</td>
                          <td>{formatTestDate(test.test_date)}</td>
                          <td>{test.start_time}</td>
                          <td>{test.end_time}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="user-back-button-container">
                    <button
                      onClick={handleGoBackToTests}
                      className="user-back-button"
                    >
                      Back
                    </button>
                  </div>
                </>
              )}
              {view === "questions" && (
                <>
                  {!questionType ? (
                    // Show question type selector if no type is selected
                    <QuestionTypeSelector
                      onSelectType={handleQuestionTypeSelect}
                    />
                  ) : questionType === "coding" ? (
                    // Show existing questions view for coding questions
                    <>
                      <div className="button-group">
                        <button
                          onClick={handleCreateQuestion}
                          className="Participant-create-button"
                        >
                          Create Question
                        </button>
                        <button
                          onClick={handleBulkCreateQuestionBtnClick}
                          className="Participant-create-button"
                        >
                          Create Bulk Questions
                        </button>
                      </div>
                      {/* Existing table for coding questions */}
                      <table className="user-dashboard-table">
                        <thead>
                          <tr>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {questions.map((question) => (
                            <tr key={question.id}>
                              <td>{question.title}</td>
                              <td>{question.content}</td>
                              <td>
                                <div className="actions-container">
                                  <i
                                    className="eye-icon"
                                    title="View Test Cases"
                                    onClick={() =>
                                      handleShowTestCases(question.testCases)
                                    }
                                  >
                                    <FaEye className="icon" />
                                  </i>
                                  <i
                                    className="fas fa-edit"
                                    title="Edit Question and Testcases"
                                    onClick={() =>
                                      handleEditQuestion(question.id)
                                    }
                                  ></i>
                                  <i
                                    className="fas fa-trash-alt"
                                    title="Delete question and it's testcases"
                                    onClick={() =>
                                      handleDeleteQuestion(question.id)
                                    }
                                  ></i>
                                </div>
                                {/* <div
                              className="eye-icon"
                              title="View Test Cases"
                              onClick={() =>
                                handleShowTestCases(question.testCases)
                              }
                            >
                              <FaEye className="icon" />
                            </div> */}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  ) : (
                    // Show MCQ questions component if MCQ type is selected
                    <MCQQuestions onBack={handleBackToQuestionTypes} />
                  )}
                </>
              )}

              {/* New view for MCQ questions */}
              {view === "mcqQuestions" && (
                <MCQQuestions onBack={handleBackToQuestionTypes} />
              )}
              {isTestCaseDialogOpen && (
                <div className="dialog-overlay">
                  <div className="dialog">
                    <h2>Test Cases</h2>
                    <ul>
                      {selectedQuestionTestCases.length > 0 ? (
                        selectedQuestionTestCases.map((testCase, index) => (
                          <li key={testCase.id}>
                            <strong>Test Case {index + 1}:</strong>
                            <br />
                            <strong>Input:</strong> {testCase.inputContent}
                            <br />
                            <strong>Output:</strong> {testCase.outputContent}
                          </li>
                        ))
                      ) : (
                        <li>No test cases found.</li>
                      )}
                    </ul>
                    <button onClick={() => setIsTestCaseDialogOpen(false)}>
                      Close
                    </button>
                  </div>
                </div>
              )}

{createQuestionView && view === "createQuestion" && (
  <div className="create-question-container">
    <h1>Create Question</h1>
    {step === 1 && (
      <form
        onSubmit={handleFormSubmit}
        className="create-question-form"
      >
        <div>
          <label htmlFor="questionTitle">Question Title</label>
          <input
            type="text"
            id="questionTitle"
            placeholder="Enter question title"
            value={questionTitle}
            onChange={(e) => setQuestionTitle(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="questionDescription">Question Description</label>
          <ReactQuill
            theme="snow"
            value={questionDescription}
            onChange={setQuestionDescription}
            placeholder="Enter question description"
            modules={{
              toolbar: [
                [{ header: [1, 2, false] }],
                ["bold", "italic", "underline", "strike", "blockquote"],
                [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
                ["link", "image"], // Enable image upload
                ["clean"]
              ],
            }}
          />
        </div>
        <button type="submit">Save and Next</button>
      </form>
    )}
                  {step === 2 && (
                    <form
                      onSubmit={handleTestcaseSubmit}
                      className="create-question-form"
                    >
                      <h2>Test Cases</h2>
                      <div>
                        <label htmlFor="testCaseInput">Input</label>
                        <textarea
                          id="testCaseInput"
                          placeholder="Enter input"
                          value={input}
                          onChange={(e) => setInput(e.target.value)}
                          required
                        />
                      </div>
                      <div>
                        <label htmlFor="testCaseOutput">Output</label>
                        <textarea
                          id="testCaseOutput"
                          placeholder="Enter output"
                          value={output}
                          onChange={(e) => setOutput(e.target.value)}
                          required
                        />
                      </div>
                      <div>
                        <label htmlFor="points">Points</label>
                        <input
                          type="number"
                          id="points"
                          placeholder="Enter points"
                          value={points}
                          onChange={(e) => setPoints(e.target.value)}
                          min={1}
                          required
                        />
                      </div>
                      <button type="submit">Add Test Case</button>
                      <button type="button" onClick={handleGoBackToQuestions}>
                        Submit Question
                      </button>
                      {/* <button type="button" onClick={() => setStep(1)}>
                      Go back
                    </button> */}
                      <div>
                        <h3>Current Test Cases</h3>
                        <ul>
                          {testCases.map((testCase, index) => (
                            <li key={index}>
                              {index + 1}. Input: {testCase.input} Output:{" "}
                              {testCase.output}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </form>
                  )}
                </div>
              )}

              {createBulkQuestionView && view === "createBulkQuestions" && (
                <>
                  <h1 className="text-2xl font-bold mb-4">
                    Bulk Create Questions
                  </h1>

                  <h2 className="text-sm font-bold mb-4 text-red-600">
                    <p>Plan Limit to create questions: {questionLimit}</p>
                    <p>
                      Plan Limit to create testcases per question:{" "}
                      {testcaseLimitPerQuestion}
                    </p>
                    <p>Questions Already Created: {questionCount}</p>
                    <p>
                      Questions that can be created more:{" "}
                      {remainingQuestionCount}
                    </p>
                  </h2>
                  {csvRowCount <= remainingQuestionCount ? (
                    <h3 className="text-sm font-bold mb-4">
                      No. of Questions in file: {csvRowCount}
                    </h3>
                  ) : (
                    <>
                      <h3 className="text-sm font-bold mb-4">
                        No. of Participants in file: {csvRowCount}
                      </h3>
                      <p className="text-sm font-bold mb-4 text-red-600">
                        Please adjust the questions data as it exceeds the limit
                        to create questions.
                      </p>
                    </>
                  )}
                  {errorMessage && (
                    <p className="text-sm font-bold mb-4 text-red-600">
                      {errorMessage}
                    </p>
                  )}

                  <form
                    onSubmit={handleBulkCreateQuestions}
                    className="max-w-md mx-auto"
                  >
                    <div className="mb-4">
                      <label
                        htmlFor="csvFile"
                        className="block text-sm font-semibold mb-2"
                      >
                        Upload CSV File
                      </label>
                      <input
                        type="file"
                        id="csvFile"
                        accept=".csv"
                        onChange={handleCreateBulkQuestionsCSVUpload}
                        className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                        required
                      />
                    </div>
                    <div className="mb-4">
                      <a
                        href="/SampleCreateBulkQuestions.csv"
                        download
                        className="inline-block px-4 py-2 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600"
                      >
                        Download Sample CSV
                      </a>
                    </div>
                    <button
                      type="submit"
                      className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-300"
                    >
                      Save All
                    </button>
                    <button
                      type="button"
                      onClick={handleGoBackToQuestionsView}
                      className="w-full bg-gray-300 text-gray-700 py-2 mt-2 rounded-md hover:bg-gray-400 transition duration-300"
                    >
                      Go Back
                    </button>
                  </form>
                </>
              )}

              {view === "participants" && (
                <>
                  {/* Header Section */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mt: 4,
                      mb: 4,
                      ml: 4,
                      gap: 2,
                    }}
                  >
                    <GroupsIcon
                      sx={{
                        fontSize: 38,
                        color: theme.palette.primary.main,
                      }}
                    />
                    <Typography variant="h4" component="h1">
                      Participants
                    </Typography>
                  </Box>
                  <button
                    className="Participant-show-button"
                    onClick={handleShowAllParticipants}
                  >
                    Show Participants
                  </button>
                  <div className="button-group">
                    <button
                      className="Participant-create-button"
                      onClick={handleCreateParticipant}
                    >
                      Add participants
                    </button>
                    <button
                      className="Participant-create-button"
                      onClick={handleBulkCreateParticipant}
                    >
                      Bulk Add participants
                    </button>
                    <button
                      className="Participant-create-button"
                      onClick={handleBulkDeleteParticipant}
                    >
                      Bulk Delete Participants
                    </button>
                  </div>
                  <table className="user-dashboard-table">
                    <thead>
                      <tr>
                        <th>Test ID</th>
                        <th>Title</th>
                        <th>Show Participants</th>
                        <th>Send Email</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tests && tests.length > 0 ? (
                        tests.map((test) => (
                          <tr key={test.test_id}>
                            <td>{test.test_id}</td>
                            <td>{test.test_title}</td>
                            <td>
                              <div
                                className="eye-icon"
                                title="view participant"
                                onClick={() =>
                                  handleShowParticipants(test.test_id)
                                }
                              >
                                <FaEye className="icon" />
                              </div>
                            </td>
                            <td>
                              <div
                                className="email-icon"
                                title="Send Email to participant"
                                onClick={() => handleSendEmail(test.test_id)}
                              >
                                <FaEnvelope className="icon" />
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="4" style={{ textAlign: "center" }}>
                            No tests found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </>
              )}
              {showAllParticipants && (
                <div className="participant-modal">
                  <h2 className="text-2xl font-bold mb-4">Participants</h2>
                  <div className="participants-table-container">
                    <table className="participants-table">
                      <thead>
                        <tr>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Email</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {participants.map((participant) => (
                          <tr key={participant.email}>
                            <td>{participant.first_name}</td>
                            <td>{participant.last_name}</td>
                            <td>{participant.email}</td>
                            <td>
                              <button
                                className="delete-button"
                                onClick={() =>
                                  handleDeleteParticipant(participant.email)
                                }
                              >
                                <FaTrash />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <button
                    className="close-modal-button"
                    onClick={() => setShowAllParticipants(false)}
                  >
                    Close
                  </button>
                </div>
              )}

              {createParticipantView && view === "createParticipant" && (
                <>
                  <h1 className="text-2xl font-bold mb-4">
                    Create Participant
                  </h1>
                  <form
                    onSubmit={handleSaveParticipant}
                    className="max-w-md mx-auto"
                  >
                    <div className="mb-4">
                      <label
                        htmlFor="firstName"
                        className="block text-sm font-semibold mb-2"
                      >
                        First Name
                      </label>
                      <input
                        type="text"
                        id="firstName"
                        placeholder="Enter First Name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                        required
                      />
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor="lastName"
                        className="block text-sm font-semibold mb-2"
                      >
                        Last Name
                      </label>
                      <input
                        type="text"
                        id="lastName"
                        placeholder="Enter Last Name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                        required
                      />
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor="email"
                        className="block text-sm font-semibold mb-2"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        id="email"
                        placeholder="Enter Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                        required
                      />
                    </div>
                    <button
                      type="submit"
                      className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-300"
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      onClick={handleGoBackToParticipants}
                      className="w-full bg-gray-300 text-gray-700 py-2 mt-2 rounded-md hover:bg-gray-400 transition duration-300"
                    >
                      Go Back
                    </button>
                  </form>
                </>
              )}
              {bulkCreateParticipantView &&
                view === "bulkCreateParticipant" && (
                  <>
                    <h1 className="text-2xl font-bold mb-4">
                      Bulk Add Participants
                    </h1>
                    <h2 className="text-sm font-bold mb-4 text-red-600">
                      <p>
                        Plan Limit to Add Participants: {participantAllowed}
                      </p>
                      <p>
                        Participant's Already Added:{" "}
                        {alreadySavedParticipantCount}
                      </p>
                      <p>
                        No. of Participant's that can be added more:{" "}
                        {canAddParticipants}
                      </p>
                    </h2>
                    {csvRowCount <= canAddParticipants ? (
                      <h3 className="text-sm font-bold mb-4">
                        No. of Participants in file: {csvRowCount}
                      </h3>
                    ) : (
                      <>
                        <h3 className="text-sm font-bold mb-4">
                          No. of Participants in file: {csvRowCount}
                        </h3>
                        <p className="text-sm font-bold mb-4 text-red-600">
                          Please adjust the participant data as it exceeds the
                          limit to add participants.
                        </p>
                      </>
                    )}
                    {errorMessage && (
                      <p className="text-sm font-bold mb-4 text-red-600">
                        {errorMessage}
                      </p>
                    )}
                    <form
                      onSubmit={handleBulkSaveParticipant}
                      className="max-w-md mx-auto"
                    >
                      <div className="mb-4">
                        <label
                          htmlFor="csvFile"
                          className="block text-sm font-semibold mb-2"
                        >
                          Upload CSV File
                        </label>
                        <input
                          type="file"
                          id="csvFile"
                          accept=".csv"
                          onChange={handleCSVUpload}
                          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                          required
                        />
                      </div>
                      <div className="mb-4">
                        <a
                          href="/SampleBulkAddParticipants.csv"
                          download
                          className="inline-block px-4 py-2 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600"
                        >
                          Download Sample CSV
                        </a>
                      </div>
                      <button
                        type="submit"
                        className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-300"
                      >
                        Save All
                      </button>
                      <button
                        type="button"
                        onClick={handleGoBackToParticipants}
                        className="w-full bg-gray-300 text-gray-700 py-2 mt-2 rounded-md hover:bg-gray-400 transition duration-300"
                      >
                        Go Back
                      </button>
                    </form>
                  </>
                )}
              {bulkDeleteParticipantView &&
                view === "bulkDeleteParticipant" && (
                  <>
                    <h1 className="text-2xl font-bold mb-4">
                      Bulk Delete Participants
                    </h1>
                    {errorMessage && (
                      <p className="text-sm font-bold mb-4 text-red-600">
                        {errorMessage}
                      </p>
                    )}
                    <form
                      onSubmit={handleBulkDeleteParticipantSubmit}
                      className="max-w-md mx-auto"
                    >
                      <div className="mb-4">
                        <label
                          htmlFor="csvFileDelete"
                          className="block text-sm font-semibold mb-2"
                        >
                          Upload CSV File with Participant IDs to Delete
                        </label>
                        <input
                          type="file"
                          id="csvFileDelete"
                          accept=".csv"
                          onChange={handleCSVDeleteUpload}
                          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                          required
                        />
                      </div>
                      <div className="mb-4">
                        <a
                          href="/SampleBulkDeleteParticipants.csv"
                          download
                          className="inline-block px-4 py-2 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600"
                        >
                          Download Sample Delete CSV
                        </a>
                      </div>
                      <button
                        type="submit"
                        className="w-full bg-red-500 text-white py-2 rounded-md hover:bg-red-600 transition duration-300"
                        disabled={isButtonDisabled}
                      >
                        Delete All
                      </button>
                      <button
                        type="button"
                        onClick={handleGoBackToParticipants}
                        className="w-full bg-gray-300 text-gray-700 py-2 mt-2 rounded-md hover:bg-gray-400 transition duration-300"
                      >
                        Go Back
                      </button>
                    </form>
                  </>
                )}

              {view === "billing" && <BillingInvoice />}

              {view === "scorecard" && (
                <>
                  {selectedTestId ? (
                    <Scorecard
                      testId={selectedTestId}
                      testType={selectedTestType}
                      onBack={() => {
                        setSelectedTestId(null);
                        setSelectedTestType(null);
                      }}
                      testTitle={
                        [...tests, ...mcqTests].find(
                          (test) =>
                            test.test_id === selectedTestId ||
                            test.mcq_test_id === selectedTestId
                        )?.test_title
                      }
                    />
                  ) : (
                    <>
                      <Typography variant="h4" sx={{ mb: 3 }}>
                        Scorecard Summary
                      </Typography>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Test ID</TableCell>
                              <TableCell>Title</TableCell>
                              <TableCell>Type</TableCell>
                              <TableCell>Participants</TableCell>
                              <TableCell>Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {[...tests, ...mcqTests].map((test) => (
                              <TableRow key={test.test_id || test.mcq_test_id}>
                                <TableCell>
                                  {test.test_id || test.mcq_test_id}
                                </TableCell>
                                <TableCell>{test.test_title}</TableCell>
                                <TableCell>{test.type || "coding"}</TableCell>
                                <TableCell>
                                  {test.participant_count ||
                                    test.participants_count}
                                </TableCell>
                                <TableCell>
                                  <IconButton
                                    onClick={() => {
                                      setSelectedTestId(
                                        test.test_id || test.mcq_test_id
                                      );
                                      setSelectedTestType(
                                        test.type || "coding"
                                      );
                                    }}
                                    color="primary"
                                    title="View Scorecard"
                                  >
                                    <FaEye />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  )}
                </>
              )}

              {view === "questionGroups" && <QuestionsGroups />}
            </div>
          </main>
          <ScorecardModal
            isOpen={isScorecardOpen}
            onClose={handleCloseScorecard}
          >
            {selectedTestId && (
              <Scorecard
                testId={selectedTestId}
                testType={selectedTestType}
                onClose={handleCloseScorecard}
              />
            )}
          </ScorecardModal>

          {isDialogOpen && (
            <div className="dialog-overlay">
              <div className="dialog">
                <h2>Participants for Test ID: {selectedTestId}</h2>

                <ul>
                  {participantEmails.length > 0 ? (
                    participantEmails.map((email, index) => (
                      <li key={index}>{email.email}</li>
                    ))
                  ) : (
                    <li>No participants found.</li>
                  )}
                </ul>
                <button onClick={closeDialog}>Close</button>
              </div>
            </div>
          )}
          {isEmailDialogOpen && (
            <div className="dialog-overlay">
              <div className="dialog">
                <h2>
                  Are you sure you want to send an email to all participants?
                </h2>
                <button onClick={handleEmailSendConfirmation}>Yes</button>
                <button onClick={closeEmailDialog}>No</button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UserDashboard;
