import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Modal,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    LinearProgress,
    Tooltip,
} from '@mui/material';
import {
    Add as AddIcon,
    Edit as EditIcon,
    Visibility as ViewIcon,
    Delete as DeleteIcon,
    ArrowBack as ArrowBackIcon,
    Close as CloseIcon,
    People as PeopleIcon,
} from '@mui/icons-material';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CreateMCQTest from './CreateMCQTest';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ManageParticipants from './ManageParticipants';

const MCQTests = ({ onBack }) => {
    const [mcqTests, setMcqTests] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showCreateTest, setShowCreateTest] = useState(false);
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [editingTest, setEditingTest] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [testToDelete, setTestToDelete] = useState(null);
    const [selectedTest, setSelectedTest] = useState(null);
    const token = localStorage.getItem("token");
    const BASE_URL = import.meta.env.VITE_BASE_URL;
    const [showManageParticipants, setShowManageParticipants] = useState(false);
    const [selectedTestId, setSelectedTestId] = useState(null);


    useEffect(() => {
        fetchMCQTests();
    }, []);

    const fetchMCQTests = async () => {
        setIsLoading(true);
        try {
            const userId = localStorage.getItem("user_id");
            const response = await axios.get(
                `${BASE_URL}/api/org/test/fetch-tests/${userId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setMcqTests(response.data.data);
            // toast.success("MCQ tests fetched successfully");
        } catch (error) {
            console.error("Error fetching MCQ tests:", error);
            // toast.error("Failed to fetch MCQ tests");
        } finally {
            setIsLoading(false);
        }
    };

    const checkTestCreationLimit = async () => {
        try {
            const userId = localStorage.getItem("user_id");

            // Get plan limits
            const planLimitResponse = await axios.post(
                `${BASE_URL}/get-plan-id-limits`,
                {
                    user_id: userId,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const maxTests = planLimitResponse.data.max_tests;

            // Get current test count
            const testCountResponse = await axios.post(
                `${BASE_URL}/get-tests-count`,
                {
                    user_id: userId,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const currentTestCount = testCountResponse.data.tests_count;

            return {
                canCreate: currentTestCount < maxTests,
                currentCount: currentTestCount,
                maxLimit: maxTests
            };
        } catch (error) {
            console.error("Error checking test creation limits:", error);
            toast.error("Failed to verify test creation limits");
            return { canCreate: false };
        }
    };

    const handleCreateMCQTest = async () => {
        try {
            const { canCreate, currentCount, maxLimit } = await checkTestCreationLimit();

            if (canCreate) {
                setShowCreateTest(true);
                setEditingTest(null);
                toast.info(`Creating new MCQ test (${currentCount + 1}/${maxLimit} tests used)`);
            } else {
                toast.warning(
                    `Test creation limit reached (${currentCount}/${maxLimit} tests). Please upgrade your plan or delete existing tests.`,
                    {
                        autoClose: 5000,
                    }
                );
            }
        } catch (error) {
            console.error("Error in test creation:", error);
            toast.error("Failed to initialize test creation");
        }
    };


    const handleTestCreated = (newTest) => {
        setMcqTests([...mcqTests, newTest]);
        setShowCreateTest(false);
        setEditingTest(null);
        toast.success("MCQ test operation completed successfully");
    };

    const handleTestOperation = async (newTest) => {
        await fetchMCQTests(); // Refresh the full list
        setShowCreateTest(false);
        setEditingTest(null);
        // toast.success(`MCQ test ${editingTest ? 'updated' : 'created'} successfully`);
    };

    const handleViewMCQTest = async (testId) => {
        try {
            const response = await axios.get(
                `${BASE_URL}/api/org/test/fetch-test-data/${testId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            
            console.log("Fetched test data:", response.data.data); // Debugging line
    
            setSelectedTest(response.data.data); // Ensure this has the structure you need
            setSelectedTestId(testId); // Explicitly set the test ID separately
            setViewModalOpen(true);
        } catch (error) {
            console.error("Error fetching test details:", error);
            // toast.error("Failed to fetch test details");
        }
    };
    

    const handleEditMCQTest = async (testId) => {
        try {
            const response = await axios.get(
                `${BASE_URL}/api/org/test/fetch-test-data/${testId}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );
            console.log('Fetched test data:', response.data.data);
            setEditingTest({
                ...response.data.data,
                mcq_test_id: testId,
                questions: response.data.data.questions.map(q => ({
                    questionId: q.mcqQuestionId,
                    ...q
                }))
            });
            setShowCreateTest(true);
        } catch (error) {
            console.error("Error fetching test details for edit:", error);
            // toast.error("Failed to fetch test details for editing");
        }
    };

    const handleDeleteClick = (test) => {
        setTestToDelete(test);
        setDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = async () => {
        if (!testToDelete) return;

        try {
            await axios.delete(
                `${BASE_URL}/api/org/test/delete-mcq-test/${testToDelete.mcq_test_id}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setMcqTests(prevTests => prevTests.filter(test => test.mcq_test_id !== testToDelete.mcq_test_id));
            toast.success("MCQ test deleted successfully");
        } catch (error) {
            console.error("Error deleting MCQ test:", error);
            toast.error("Failed to delete MCQ test");
        } finally {
            setDeleteDialogOpen(false);
            setTestToDelete(null);
        }
    };

    if (showCreateTest) {
        return <CreateMCQTest
            onClose={() => {
                setShowCreateTest(false);
                setEditingTest(null);
            }}
            onTestCreated={handleTestOperation}
            editTest={editingTest}
        />;
    }

    const handleManageParticipants = (testId) => {
        setSelectedTestId(testId);
        setShowManageParticipants(true);
    };

    if (showManageParticipants) {
        return <ManageParticipants
            testId={selectedTestId}
            onClose={() => {
                setShowManageParticipants(false);
                setSelectedTestId(null);
            }}
        />;
    }


    if (isLoading) {
        return (
            <Box sx={{ p: 3 }}>
                <LinearProgress />
                <Typography variant="h6" color="primary" sx={{ mb: 2 }}>
                    Loading MCQ Tests...
                </Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ p: 3 }}>
            <Button
                startIcon={<ArrowBackIcon />}
                onClick={onBack}
                sx={{ mb: 2, display: "flex", justifyContent: "flex-start" }}
            >
                Back to Test Selection
            </Button>
            <Typography variant="h4" gutterBottom>MCQ Tests</Typography>
            <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleCreateMCQTest}
                sx={{ mb: 2 }}
            >
                Create New MCQ Test
            </Button>
            {mcqTests.length > 0 ? (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Test ID</TableCell>
                                <TableCell>Title</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {mcqTests.map((test) => (
                                <TableRow key={test.mcq_test_id}>
                                    <TableCell>{test.mcq_test_id}</TableCell>
                                    <TableCell>{test.test_title}</TableCell>
                                    <TableCell>{test.test_description}</TableCell>
                                    <TableCell>
                                        <Tooltip title="View">
                                            <IconButton onClick={() => handleViewMCQTest(test.mcq_test_id)} color="primary">
                                                <ViewIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Edit">
                                            <IconButton onClick={() => handleEditMCQTest(test.mcq_test_id)} color="primary">
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Manage Participants">
                                            <IconButton onClick={() => handleManageParticipants(test.mcq_test_id)} color="primary">
                                                <PeopleIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Delete">
                                            <IconButton onClick={() => handleDeleteClick(test)} color="error">
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Typography>No MCQ tests available. Create one to get started!</Typography>
            )}

            {/* View Modal */}
            <Modal
                open={viewModalOpen}
                onClose={() => setViewModalOpen(false)}
                aria-labelledby="view-test-modal"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                }}>
                    <IconButton
                        aria-label="close"
                        onClick={() => setViewModalOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography id="view-test-modal" variant="h6" component="h2">
                        Test Details
                    </Typography>
                    {selectedTest && (
                        <Box>
                            <Typography><strong>Title:</strong> {selectedTest.testTitle}</Typography>
                            <Typography><strong>Description:</strong> {selectedTest.testDescription}</Typography>
                            <Typography><strong>Duration:</strong> {selectedTest.duration} minutes</Typography>
                            <Typography><strong>Start Date:</strong> {selectedTest.testDate}</Typography>
                            <Typography><strong>End Date:</strong> {selectedTest.testEndDate}</Typography>
                            <Typography><strong>Start Time:</strong> {selectedTest.startTime}</Typography>
                            <Typography><strong>End Time:</strong> {selectedTest.endTime}</Typography>
                            <Typography><strong>Number of Questions:</strong> {selectedTest.questions.length}</Typography>
                            <Typography>
    <strong>Test Link:</strong> 
    <a 
        href={`https://www.cirruscypher.com/attempt-test-page?testId=${selectedTestId}`} 
        target="_blank" 
        rel="noopener noreferrer"
        style={{ color: '#1976d2', textDecoration: 'none', marginLeft: '5px' }}
    >
        {`https://www.cirruscypher.com/attempt-test-page?testId=${selectedTestId}`}
    </a>
</Typography>
                        </Box>
                    )}
                </Box>
            </Modal>

            {/* Delete Confirmation Dialog */}
            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Confirm Deletion"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this MCQ test? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
                    <Button onClick={handleDeleteConfirm} color="error" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default MCQTests;