import React from "react";
import Select from "react-select";
// import monacoThemes from "monaco-themes/themes/themelist";
import monacoThemes from '@/lib/monacoThemes'
import { customStyles } from "../constants/customStyles";

const ThemeDropdown = ({ handleThemeChange, theme, styles }) => {
  const options = Object.entries(monacoThemes).map(([themeId, themeName]) => ({
    label: themeName,
    value: themeId,
  }));

  return (
    <Select
      placeholder="Select Theme"
      options={options}
      value={theme}
      styles={styles}
      onChange={handleThemeChange}
    />
  );
};

export default ThemeDropdown;