import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Button,
} from "@mui/material";
import DOMPurify from "dompurify";
import { toast } from "react-toastify";
import axios from "axios";

const MCQView = ({
  question,
  currentQuestionNumber,
  totalQuestions,
  onAnswerSubmit,
  onNavigate,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    loadPreviousAnswer();
  }, [question?.mcq_question_id]);

  const loadPreviousAnswer = async () => {
    try {
      const email = localStorage.getItem("email");
      const testId = localStorage.getItem("testId");

      const response = await axios.get(
        `${
          import.meta.env.VITE_BASE_URL
        }/api/participant/attempt-mcq-test/fetch-options/${email}/${testId}`
      );

      if (response.data.success) {
        const previousAnswer = response.data.data.find(
          (item) => item.question_id === question.mcq_question_id
        );

        if (previousAnswer) {
          const selectedOptions =
            typeof previousAnswer.selected_options === "string"
              ? JSON.parse(previousAnswer.selected_options)
              : previousAnswer.selected_options;

          setSelectedOptions(selectedOptions);

          // Mark question as answered if it was previously attempted
          if (selectedOptions && selectedOptions.length > 0) {
            onAnswerSubmit(selectedOptions);
          }
        }
      }
    } catch (error) {
      if (error.response?.status !== 404) {
        console.error("Error loading previous answer:", error);
      }
    }
  };

  const handleOptionSelect = (optionId) => {
    if (question.question_type === 0) {
      // Single choice
      setSelectedOptions([optionId]);
    } else {
      // Multiple choice
      const newSelected = selectedOptions.includes(optionId)
        ? selectedOptions.filter((id) => id !== optionId)
        : [...selectedOptions, optionId];
      setSelectedOptions(newSelected);
    }
  };

  const handleSaveAndNext = async () => {
    if (selectedOptions.length === 0) {
      toast.warning("Please select at least one option before saving.");
      return;
    }

    try {
      setProcessing(true);
      const testInfo = JSON.parse(localStorage.getItem("testInfo"));

      // Create execution entry
      const createExecutionResponse = await axios.post(
        `${
          import.meta.env.VITE_BASE_URL
        }/api/mcq-test/execution/create-execution-entry`,
        {
          participantId: testInfo.participantId,
          testId: localStorage.getItem("testId"),
          questionId: question.mcq_question_id,
          selectedOptions: selectedOptions,
          sectionId: question.sectionId,
        }
      );

      if (createExecutionResponse.data.success) {
        await axios.post(
          `${
            import.meta.env.VITE_BASE_URL
          }/api/mcq-test/execution/validate-and-update-points`,
          {
            mcqExecutionId: createExecutionResponse.data.data.mcqExecutionId,
          }
        );

        // Mark question as answered
        onAnswerSubmit(selectedOptions);

        // Add navigation to next question after successful save
        if (currentQuestionNumber < totalQuestions) {
          onNavigate("next");
        }
      }
    } catch (error) {
      console.error("Error saving answer:", error);
      toast.error("Failed to save answer");
    } finally {
      setProcessing(false);
    }
  };

  if (!question || !question.options) {
    return <div>Loading question...</div>;
  }

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <div className="w-[70%] bg-white p-6 rounded-lg shadow mx-auto">
        {/* Question Header */}
        <div className="mb-8">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold">
              Question {currentQuestionNumber} of {totalQuestions}
            </h2>
            <div className="flex items-center gap-4">
              <span className="text-sm text-gray-500">
                Type:{" "}
                {question.question_type === 0
                  ? "Single Choice"
                  : "Multiple Choice"}
              </span>
              <span className="text-sm text-gray-500">
                Points: {question.points}
              </span>
            </div>
          </div>

          {/* Question Content */}
          <div className="space-y-4 mb-6">
            <div
              className="text-lg font-medium"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(question.question_text),
              }}
            />
            {question.question_description && (
              <div
                className="text-gray-700"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(question.question_description),
                }}
              />
            )}
          </div>

          {/* Options */}
          <div className="space-y-4">
            {question.options.map((option) => (
              <div
                key={option.option_id}
                className={`p-4 border-2 rounded-lg transition-colors cursor-pointer
                  ${
                    selectedOptions.includes(option.option_id)
                      ? "border-blue-500 bg-blue-50"
                      : "border-gray-200 hover:bg-gray-50"
                  }`}
                onClick={() => handleOptionSelect(option.option_id)}
              >
                <div className="flex items-center gap-3">
                  {question.question_type === 0 ? (
                    <Radio
                      checked={selectedOptions.includes(option.option_id)}
                      disabled={processing}
                    />
                  ) : (
                    <Checkbox
                      checked={selectedOptions.includes(option.option_id)}
                      disabled={processing}
                    />
                  )}
                  <div
                    className="flex-1"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(option.option_text),
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Navigation Buttons */}
        <div className="flex justify-between mt-6">
          <Button
            variant="contained"
            color="primary"
            onClick={() => onNavigate("previous")}
            disabled={currentQuestionNumber === 1 || processing}
          >
            Previous
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveAndNext}
            disabled={processing}
          >
            {processing ? <span>Saving...</span> : "Save & Next"}
          </Button>
        </div>

        {/* Processing Indicator */}
        {processing && (
          <div className="text-center text-gray-500 mt-4">
            <span className="animate-spin inline-block mr-2">⌛</span>
            Processing...
          </div>
        )}
      </div>
    </div>
  );
};

export default MCQView;
