import React, { useState, useEffect } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography,
  IconButton,
  FormHelperText,
  Switch,
  FormControlLabel,
  Stack
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const WorkExperienceModal = ({ open, onClose, onSave, experience }) => {
    const [formData, setFormData] = useState({
      title: '',
      company: '',
      location: '',
      employmentType: '',
      startMonth: '',
      startYear: '',
      endMonth: '',
      endYear: '',
      isCurrentlyWorking: false,
      description: '',
      id: null,
    });
  
    const [errors, setErrors] = useState({});
  
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December',
    ];
    const years = Array.from(
      { length: new Date().getFullYear() - 1990 + 1 },
      (_, i) => new Date().getFullYear() - i
    );
    useEffect(() => {
        if (experience) {
          setFormData({
            title: experience.job_title || '',
            company: experience.company_name || '',
            location: experience.location || '',
            employmentType: experience.employment_type || '',
            startMonth: experience.start_month || '',
            startYear: experience.start_year || '',
            endMonth: experience.end_month || '',
            endYear: experience.end_year || '',
            isCurrentlyWorking: experience.is_currently_working === 1 || false,
            description: experience.description || '',
            id: experience.id || null,
          });
        }
      }, [experience]);
      
    
      const handleChange = (field) => (event) => {
        setFormData({
          ...formData,
          [field]: event.target.value,
        });
      };
      const handleCurrentlyWorkingChange = (event) => {
        setFormData({
          ...formData,
          isCurrentlyWorking: event.target.checked,
          endMonth: event.target.checked ? '' : formData.endMonth,
          endYear: event.target.checked ? '' : formData.endYear,
        });
      };
    
      const validateForm = () => {
        const newErrors = {};
        if (!formData.title.trim()) newErrors.title = 'Job title is required';
        if (!formData.company.trim()) newErrors.company = 'Company name is required';
        if (!formData.location.trim()) newErrors.location = 'Location is required';
        if (!formData.employmentType) newErrors.employmentType = 'Employment type is required';
        if (!formData.startMonth) newErrors.startMonth = 'Start month is required';
        if (!formData.startYear) newErrors.startYear = 'Start year is required';
    
        if (!formData.isCurrentlyWorking) {
          if (!formData.endMonth) newErrors.endMonth = 'End month is required';
          if (!formData.endYear) newErrors.endYear = 'End year is required';
    
          // Additional validation for year consistency
          if (parseInt(formData.startYear) > parseInt(formData.endYear)) {
            newErrors.endYear = 'End year must be after start year';
          }
        }
    
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
      };

      const handleSubmit = () => {
        onSave(formData);
      };
  

  const customSelectProps = {
    IconComponent: KeyboardArrowDownIcon,
    sx: {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#E0E0E0'
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#BDBDBD'
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#2196F3'
      }
    }
  };

//   const handleChange = (field) => (event) => {
//     setFormData({
//       ...formData,
//       [field]: event.target.value,
//     });
    
//     if (errors[field]) {
//       setErrors({
//         ...errors,
//         [field]: '',
//       });
//     }
//   };

//   const handleSubmit = () => {
//     if (validateForm()) {
//       const submissionData = {
//         ...formData,
//         startDate: `${formData.startMonth} ${formData.startYear}`,
//         endDate: formData.currentlyWorking 
//           ? 'Present' 
//           : `${formData.endMonth} ${formData.endYear}`
//       };
      
//       onSave(submissionData);
//       onClose();
//     }
//   };

  const handleAddAnother = () => {
    // Reset form data while keeping the modal open
    setFormData({
      title: '',
      company: '',
      location: '',
      employmentType: '',
      startMonth: '',
      startYear: '',
      endMonth: '',
      endYear: '',
      currentlyWorking: false,
      description: '',
    });
    // Optional: You might want to clear errors as well
    setErrors({});
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      maxWidth="sm" 
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '12px',
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)'
        }
      }}
    >
      <DialogTitle sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        padding: '20px 24px',
        borderBottom: '1px solid #E0E0E0',
        backgroundColor: '#fff'
      }}>
        <Typography variant="h6" sx={{ fontWeight: 600, color: 'text.primary' }}>
          Add Work Experience
        </Typography>
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ padding: '24px' }}>
        <Stack spacing={3}>
          <Typography variant="subtitle1" sx={{ color: '#666', fontWeight: 500 }}>
            Add your work experience details
          </Typography>

          <TextField
            fullWidth
            label="Job Title *"
            value={formData.title}
            onChange={handleChange('title')}
            placeholder="What was your job title?"
            error={!!errors.title}
            helperText={errors.title}
            variant="outlined"
            InputProps={{
              sx: {
                backgroundColor: '#fff'
              }
            }}
          />

          <TextField
            fullWidth
            label="Company *"
            value={formData.company}
            onChange={handleChange('company')}
            placeholder="Which company did you work at?"
            error={!!errors.company}
            helperText={errors.company}
            variant="outlined"
            InputProps={{
              sx: {
                backgroundColor: '#fff'
              }
            }}
          />

          <TextField
            fullWidth
            label="Location *"
            value={formData.location}
            onChange={handleChange('location')}
            placeholder="City, State, Country"
            error={!!errors.location}
            helperText={errors.location}
            variant="outlined"
            InputProps={{
              sx: {
                backgroundColor: '#fff'
              }
            }}
          />

          <FormControl fullWidth variant="outlined" error={!!errors.employmentType}>
            <InputLabel>Employment Type *</InputLabel>
            <Select
              value={formData.employmentType}
              onChange={handleChange('employmentType')}
              label="Employment Type *"
              {...customSelectProps}
            >
              <MenuItem value="full-time">Full-time</MenuItem>
              <MenuItem value="part-time">Part-time</MenuItem>
              <MenuItem value="contract">Contract</MenuItem>
              <MenuItem value="internship">Internship</MenuItem>
              <MenuItem value="freelance">Freelance</MenuItem>
            </Select>
            {errors.employmentType && (
              <FormHelperText>{errors.employmentType}</FormHelperText>
            )}
          </FormControl>

          <FormControlLabel
            control={
              <Switch
                checked={formData.currentlyWorking}
                onChange={handleCurrentlyWorkingChange}
                sx={{
                  '& .MuiSwitch-switchBase.Mui-checked': {
                    color: '#2196F3'
                  },
                  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                    backgroundColor: '#2196F3'
                  }
                }}
              />
            }
            label="I currently work here"
          />

          <Box>
            <Typography variant="subtitle2" sx={{ mb: 1, color: '#666' }}>
              Starting from *
            </Typography>
            <Stack direction="row" spacing={2}>
              <FormControl fullWidth variant="outlined" error={!!errors.startMonth}>
                <InputLabel>Month</InputLabel>
                <Select
                  value={formData.startMonth}
                  onChange={handleChange('startMonth')}
                  label="Month"
                  {...customSelectProps}
                >
                  {months.map(month => (
                    <MenuItem key={month} value={month}>{month}</MenuItem>
                  ))}
                </Select>
                {errors.startMonth && <FormHelperText>{errors.startMonth}</FormHelperText>}
              </FormControl>
              <FormControl fullWidth variant="outlined" error={!!errors.startYear}>
                <InputLabel>Year</InputLabel>
                <Select
                  value={formData.startYear}
                  onChange={handleChange('startYear')}
                  label="Year"
                  {...customSelectProps}
                >
                  {years.map(year => (
                    <MenuItem key={year} value={year}>{year}</MenuItem>
                  ))}
                </Select>
                {errors.startYear && <FormHelperText>{errors.startYear}</FormHelperText>}
              </FormControl>
            </Stack>
          </Box>

          {!formData.currentlyWorking && (
            <Box>
              <Typography variant="subtitle2" sx={{ mb: 1, color: '#666' }}>
                Ending in *
              </Typography>
              <Stack direction="row" spacing={2}>
                <FormControl fullWidth variant="outlined" error={!!errors.endMonth}>
                  <InputLabel>Month</InputLabel>
                  <Select
                    value={formData.endMonth}
                    onChange={handleChange('endMonth')}
                    label="Month"
                    {...customSelectProps}
                  >
                    {months.map(month => (
                      <MenuItem key={month} value={month}>{month}</MenuItem>
                    ))}
                  </Select>
                  {errors.endMonth && <FormHelperText>{errors.endMonth}</FormHelperText>}
                </FormControl>
                <FormControl fullWidth variant="outlined" error={!!errors.endYear}>
                  <InputLabel>Year</InputLabel>
                  <Select
                    value={formData.endYear}
                    onChange={handleChange('endYear')}
                    label="Year"
                    {...customSelectProps}
                  >
                    {years.map(year => (
                      <MenuItem key={year} value={year}>{year}</MenuItem>
                    ))}
                  </Select>
                  {errors.endYear && <FormHelperText>{errors.endYear}</FormHelperText>}
                </FormControl>
              </Stack>
            </Box>
          )}

          <TextField
            fullWidth
            label="Description"
            multiline
            rows={4}
            value={formData.description}
            onChange={handleChange('description')}
            placeholder="Describe your responsibilities and achievements..."
            error={!!errors.description}
            helperText={errors.description}
            variant="outlined"
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: '#fff'
              }
            }}
          />

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button
              color="primary"
              onClick={handleAddAnother}
              sx={{
                textTransform: 'none',
                color: '#2196F3'
              }}
            >
              Add another
            </Button>
            <Button
              variant="contained"
              onClick={handleSubmit}
              sx={{
                textTransform: 'none',
                backgroundColor: '#2196F3',
                '&:hover': {
                  backgroundColor: '#1976D2'
                },
                borderRadius: '8px',
                paddingX: '24px'
              }}
            >
              Save
            </Button>
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default WorkExperienceModal;