// TestViewModal.jsx
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Paper,
  Divider,
  Chip,
} from "@mui/material";
import dayjs from "dayjs";

const TestViewModal = ({ open, onClose, test }) => {
  if (!test) return null;

  const calculateQuestionCounts = (section) => {
    let mcqCount = 0;
    let codingCount = 0;

    // Count questions from direct arrays
    mcqCount += section.mcqQuestionIds?.length || 0;
    codingCount += section.codingQuestionIds?.length || 0;

    // Count questions from groups
    section.groupIds?.forEach((group) => {
      group.questions?.forEach((question) => {
        if (question.type === "mcq") {
          mcqCount++;
        } else if (question.type === "coding") {
          codingCount++;
        }
      });
    });

    return {
      mcqCount,
      codingCount,
      totalCount: mcqCount + codingCount,
    };
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
        },
      }}
    >
      <DialogTitle
        sx={{
          bgcolor: "primary.main",
          color: "primary.contrastText",
          py: 2,
        }}
      >
        Test Details
      </DialogTitle>
      <DialogContent sx={{ mt: 2 }}>
        <Box sx={{ p: 2 }}>
          {/* Basic Test Information */}
          <Typography variant="h6" gutterBottom>
            Basic Information
          </Typography>
          <Paper sx={{ p: 2, mb: 3 }} elevation={1}>
            <Typography>
              <strong>Test ID:</strong> {test.testId}
            </Typography>
            <Typography>
              <strong>Title:</strong> {test.testTitle}
            </Typography>
            <Typography>
              <strong>Description:</strong> {test.testDescription}
            </Typography>
            <Typography>
              <strong>Duration:</strong> {test.duration} minutes
            </Typography>
          </Paper>

          {/* Test Schedule */}
          <Typography variant="h6" gutterBottom>
            Schedule
          </Typography>
          <Paper sx={{ p: 2, mb: 3 }} elevation={1}>
            <Typography>
              <strong>Start Time:</strong>{" "}
              {dayjs(test.testStartDateTime).format("YYYY-MM-DD HH:mm:ss")}
            </Typography>
            <Typography>
              <strong>End Time:</strong>{" "}
              {dayjs(test.testEndDateTime).format("YYYY-MM-DD HH:mm:ss")}
            </Typography>
          </Paper>

          {/* Test Settings */}
          <Typography variant="h6" gutterBottom>
            Settings
          </Typography>
          <Paper sx={{ p: 2, mb: 3 }} elevation={1}>
            <Typography>
              <strong>Advanced Proctor:</strong>{" "}
              {test.allowAdvancedProctor ? "Enabled" : "Disabled"}
            </Typography>
            <Typography>
              <strong>Section Selection:</strong>{" "}
              {test.allowCandidateSectionSelection ? "Allowed" : "Not Allowed"}
            </Typography>
          </Paper>

          {/* Sections */}
          <Typography variant="h6" gutterBottom>
            Sections
          </Typography>
          {test.sections?.map((section, index) => {
            const questionCounts = calculateQuestionCounts(section);

            return (
              <Paper key={section.sectionId} sx={{ p: 2, mb: 2 }} elevation={1}>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "bold", mb: 1 }}
                >
                  Section {index + 1}: {section.sectionName}
                </Typography>
                <Divider sx={{ my: 1 }} />
                <Typography>
                  <strong>Duration:</strong> {section.sectionDuration} minutes
                </Typography>
                <Typography>
                  <strong>Total Questions:</strong> {questionCounts.totalCount}
                </Typography>
                <Typography>
                  <strong>MCQ Questions:</strong> {questionCounts.mcqCount}
                </Typography>
                <Typography>
                  <strong>Coding Questions:</strong>{" "}
                  {questionCounts.codingCount}
                </Typography>
                <Typography>
                  <strong>Shuffle Questions:</strong>{" "}
                  {section.allowShuffleQuestion ? "Yes" : "No"}
                </Typography>
                <Typography>
                  <strong>Question Navigation:</strong>{" "}
                  {section.allowQuestionNavigation ? "Allowed" : "Not Allowed"}
                </Typography>
                <Box sx={{ mt: 1 }}>
                  <strong>Allowed Languages:</strong>
                  <Box
                    sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 1 }}
                  >
                    {section.allowedLanguages.map((lang) => (
                      <Chip
                        key={typeof lang === "string" ? lang : lang.languageId}
                        label={typeof lang === "string" ? lang : lang.language}
                        size="small"
                      />
                    ))}
                  </Box>
                </Box>
              </Paper>
            );
          })}
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={onClose} variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TestViewModal;
