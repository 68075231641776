import React from "react";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import logo from "/logos/cirruscypher-name-multi-alpha.png";
import axios from "axios";

const InvoiceGenerator = {
  generateInvoicePDF: async (paymentDetails) => {
    // Initialize PDF document with professional settings
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
      compress: true,
    });

    // Professional Color Scheme
    const colors = {
      primary: {
        main: [0, 67, 124],      // Deep Professional Blue
        light: [241, 246, 252],  // Light Blue for Highlights
        dark: [0, 45, 83]        // Darker Blue for Emphasis
      },
      secondary: {
        main: [33, 43, 54],      // Rich Dark Gray
        light: [128, 128, 128],  // Medium Gray
        dark: [51, 51, 51]       // Darker Gray for Text
      },
      accent: {
        blue: [0, 112, 192],     // Bright Blue for Highlights
        gray: [230, 230, 230]    // Light Gray for Borders
      },
      table: {
        header: [0, 67, 124],    // Matching Primary Blue
        zebra: [247, 250, 252],  // Very Light Blue for Alternating Rows
        border: [230, 230, 230]  // Table Border Color
      },
      white: [255, 255, 255]     // Pure White
    };

    // Typography System
    const typography = {
      sizes: {
        title: 24,       // Main Invoice Title
        subtitle: 16,    // Section Headers
        header: 12,      // Sub-headers
        body: 10,        // Main Content
        small: 8,        // Footer and Details
        mini: 7         // Fine Print
      },
      lineHeight: {
        tight: 4,
        normal: 5,
        relaxed: 6,
        loose: 8
      },
      margins: {
        page: 15,        // Page Margin
        section: 10,     // Section Spacing
        element: 5       // Element Spacing
      }
    };

    // Utility Functions
    const formatCurrency = (amount) => {
      const numAmount = Number(amount);
      return `Rs. ${numAmount.toLocaleString("en-IN", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`;
    };

    const formatDate = (date) => {
      return new Date(date).toLocaleDateString("en-IN", {
        year: "numeric",
        month: "long",
        day: "numeric"
      });
    };

    const drawHorizontalLine = (y, width = 180) => {
      doc.setDrawColor(...colors.accent.gray);
      doc.setLineWidth(0.5);
      doc.line(typography.margins.page, y, typography.margins.page + width, y);
    };

    // Fetch Organization Profile
    const userId = localStorage.getItem("user_id");
    let customerProfile = {};

    try {
      const response = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/api/org/org-profile/get-profile/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.success) {
        const profileData = response.data.data;
        customerProfile = {
          name: profileData.contact_person_name,
          organization: profileData.company_name,
          gstNumber: profileData.gst_number,
          address: profileData.street_address,
          city: profileData.city,
          state: profileData.state,
          country: profileData.country,
          pincode: profileData.pincode,
          email: profileData.contact_email,
          phone: profileData.contact_number
        };
      }
    } catch (error) {
      console.error("Error fetching profile:", error);
    }

    // Document Generation
    let currentY = typography.margins.page;

    // Header Section
    doc.addImage(logo, "PNG", typography.margins.page, currentY, 60, 20);
    
    // Invoice Title
    doc.setFontSize(typography.sizes.title);
    doc.setTextColor(...colors.primary.main);
    doc.setFont("helvetica", "bold");
    doc.text("TAX INVOICE", 195, currentY + 10, { align: "right" });
    
    currentY += 30; // Space after header

    // Company Information
    doc.setFontSize(typography.sizes.subtitle);
    doc.setTextColor(...colors.secondary.main);
    doc.setFont("helvetica", "bold");
    doc.text("TECHNOCIRRUS SOLUTIONS PRIVATE LIMITED", typography.margins.page, currentY);
    
    currentY += typography.lineHeight.normal;
    
    doc.setFontSize(typography.sizes.body);
    doc.setFont("helvetica", "normal");
    doc.text("B-908, YashOne, Maan-Hinjewadi Phase-1, Pune", typography.margins.page, currentY);
    
    currentY += typography.lineHeight.normal;
    doc.text("Phone: +91 9175046101 | Email: info@technocirrus.com", typography.margins.page, currentY);

    // Legal Information
    currentY += typography.lineHeight.normal;
    doc.setFontSize(typography.sizes.small);
    doc.text("CIN: U72900TG2020PTC144557 | PAN: AABCZ6040K", typography.margins.page, currentY);
    
    currentY += typography.lineHeight.normal;
    doc.text("GSTIN: 36AABCZ6040K1Z4 | SAC: 998319", typography.margins.page, currentY);

    // Divider
    currentY += typography.lineHeight.normal;
    doc.setLineWidth(0.75);
    doc.setDrawColor(...colors.primary.main);
    doc.line(typography.margins.page, currentY, 195, currentY);

    // Invoice Details (Right-aligned)
    currentY += typography.lineHeight.loose;
    doc.setFontSize(typography.sizes.body);
    doc.setTextColor(...colors.secondary.dark);
    
    const invoiceDetails = [
      { label: "Invoice Number:", value: `#${paymentDetails.paymentId}` },
      { label: "Invoice Date:", value: formatDate(paymentDetails.createdAt) },
      { label: "Transaction ID:", value: paymentDetails.transactionId || "N/A" }
    ];
    
    invoiceDetails.forEach((detail, index) => {
      // Calculate the Y position for the current line
      const yPosition = currentY + (index * typography.lineHeight.normal);
    
      // Set the font for the label
      doc.setFont("helvetica", "bold");
      doc.setFontSize(typography.sizes.body);
      doc.text(detail.label, 99, yPosition);
    
      // Measure the width of the label
      const labelWidth = doc.getTextWidth(detail.label);
    
      // Set the font for the value
      doc.setFont("helvetica", "normal");
      doc.text(detail.value, 99 + labelWidth + 5, yPosition); // Adjust spacing if needed
    });
    
    // Bill To Section
    currentY += typography.lineHeight.loose * 3;
    doc.setFontSize(typography.sizes.header);
    doc.setTextColor(...colors.primary.main);
    doc.setFont("helvetica", "bold");
    doc.text("BILL TO", typography.margins.page, currentY);

    // Customer Details
    currentY += typography.lineHeight.normal;
    doc.setFontSize(typography.sizes.body);
    doc.setTextColor(...colors.secondary.dark);
    doc.setFont("helvetica", "normal");

    const customerDetails = [
      customerProfile.name || "Not Provided",
      customerProfile.organization || "Organization Name",
      customerProfile.address || "Address",
      `${customerProfile.city || ""}, ${customerProfile.state || ""} ${customerProfile.pincode || ""}`,
      customerProfile.country || "",
      `GSTIN: ${customerProfile.gstNumber || "Not Provided"}`,
      `Email: ${customerProfile.email || "Not Provided"}`,
      `Phone: ${customerProfile.phone || "Not Provided"}`
    ];

    customerDetails.forEach((detail) => {
      currentY += typography.lineHeight.normal;
      doc.text(detail, typography.margins.page, currentY);
    });

    // Items Table
    currentY += typography.lineHeight.loose * 2;
    
    const tableHeaders = [["Description", "Quantity", "Rate", "Amount"]];
    const tableBody = [
      [
        `${paymentDetails.planName} - ${paymentDetails.billingCycle || "Monthly"} Subscription`,
        "1",
        formatCurrency(paymentDetails.amount),
        formatCurrency(paymentDetails.amount)
      ]
    ];

    doc.autoTable({
      startY: currentY,
      head: tableHeaders,
      body: tableBody,
      theme: "grid",
      headStyles: {
        fillColor: colors.table.header,
        textColor: colors.white,
        fontStyle: "bold",
        halign: "center",
        fontSize: typography.sizes.body,
        cellPadding: { top: 5, right: 4, bottom: 5, left: 4 },
        lineWidth: 0.1
      },
      columnStyles: {
        0: { cellWidth: 90 },
        1: { cellWidth: 25, halign: "center" },
        2: { cellWidth: 35, halign: "right" },
        3: { cellWidth: 35, halign: "right" }
      },
      styles: {
        fontSize: typography.sizes.body,
        cellPadding: 4,
        textColor: colors.secondary.dark,
        lineColor: colors.table.border,
        lineWidth: 0.1
      },
      alternateRowStyles: {
        fillColor: colors.table.zebra
      }
    });

    // Total Amount Section
    const totalY = doc.autoTable.previous.finalY + 10;
    doc.setFontSize(typography.sizes.subtitle);
    doc.setTextColor(...colors.secondary.main);
    doc.setFont("helvetica", "bold");
    doc.text("Total Amount:", 130, totalY);
    doc.setTextColor(...colors.primary.main);
    doc.text(formatCurrency(paymentDetails.amount), 200, totalY, { align: "right" });

    
    // Payment Terms and Notes Section
    const termsY = totalY + 20;
    doc.setFontSize(typography.sizes.header);
    doc.setTextColor(...colors.primary.main);
    doc.setFont("helvetica", "bold");
    doc.text("Payment Terms & Notes", typography.margins.page, termsY);

    doc.setFontSize(typography.sizes.small);
    doc.setTextColor(...colors.secondary.main);
    doc.setFont("helvetica", "normal");
    
    const terms = [
      "1. Payment is due within 30 days from the invoice date.",
      "2. This is a computer-generated invoice and doesn't require physical signature.",
      "3. All banking charges are to be borne by the remitter.",
      "4. Late payments may be subject to a 1.5% monthly finance charge."
    ];

    let currentTermsY = termsY + 8;
    terms.forEach(term => {
      doc.text(term, typography.margins.page, currentTermsY);
      currentTermsY += typography.lineHeight.normal;
    });


   // Footer Section
   const footerY = 270;
   drawHorizontalLine(footerY - 5);

   // Footer Text
   doc.setFontSize(typography.sizes.small);
   doc.setTextColor(...colors.secondary.main);
   doc.setFont("helvetica", "bold");
   doc.text(
     "Thank you for your business!",
     doc.internal.pageSize.getWidth() / 2,
     footerY,
     { align: "center" }
   );

   // Contact Information in Footer
   doc.setFontSize(typography.sizes.mini);
   doc.setFont("helvetica", "normal");
   doc.text(
     "For any queries, please contact us at: info@technocirrus.com | +91 9175046101",
     doc.internal.pageSize.getWidth() / 2,
     footerY + 5,
     { align: "center" }
   );

   doc.text(
     "www.technocirrus.com",
     doc.internal.pageSize.getWidth() / 2,
     footerY + 10,
     { align: "center" }
   );

    // Save the PDF
    doc.save(`Invoice_${paymentDetails.paymentId}.pdf`);
  },
};

export default InvoiceGenerator;