import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Home } from "lucide-react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { showNotification } from "../../utils/notification";
import "../../css/CandidateLogin.css"; // Ensure you have this file

const CandidateLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [openForgotPasswordModal, setOpenForgotPasswordModal] = useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [forgotPasswordStep, setForgotPasswordStep] = useState("initial");
  const [passwordError, setPasswordError] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    localStorage.clear();
  });

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/candidate-login`,
        { email, password }
      );
      if (response.data.error === null) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("email", email);
        localStorage.setItem("candidateId", response.data.candidateId); // Store candidateId
        navigate("/profilepage");
      }
    } catch (error) {
      console.error("Error logging in:", error);
      showNotification('error', "Invalid Username or Password");
    }
  };

  const handleForgotPassword = async () => {
    if (!resetEmail) {
      showNotification('error', "Please enter your email address");
      return;
    }

    try {
      const queryResponse = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/check-candidate-email`,
        { email: resetEmail }
      );

      if (queryResponse.status === 200) {
        const response = await axios.post(
          `${import.meta.env.VITE_BASE_URL}/send-otp`,
          { email: resetEmail }
        );
        if (response.status === 200) {
          showNotification('success', "OTP sent to your email");
          setForgotPasswordStep("otpSent");
        } else {
          console.error("Failed to send OTP:", response.data.error);
          showNotification('error', "Failed to send OTP. Please try again.");
        }
      } else {
        console.error("Email is not registered");
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      showNotification('error', "Failed to send OTP. Please check if email is correct and try again.");
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/verify-otp`,
        { email: resetEmail, otp }
      );
      if (response.status === 200) {
        setForgotPasswordStep("otpVerified");
      } else {
        console.error("Invalid OTP:", response.data.error);
        showNotification('error', "Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      showNotification('error', "Invalid OTP. Please try again.");
    }
  };

  const handleResetPassword = async () => {
    if (newPassword !== confirmPassword) {
      setPasswordError("Passwords do not match");
      return;
    }

    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/reset-candidate-password`,
        { email: resetEmail, password: newPassword }
      );
      if (response.status === 200) {
        showNotification('success', "Password reset successfully!");
        handleCloseForgotPasswordModal();
      } else {
        console.error("Failed to reset password:", response.data.error);
        showNotification('error', "Failed to reset password. Please try again.");
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      showNotification('error', "Failed to reset password. Please try again.");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleOpenForgotPasswordModal = () => {
    setOpenForgotPasswordModal(true);
  };

  const handleCloseForgotPasswordModal = () => {
    setOpenForgotPasswordModal(false);
    setResetEmail("");
    setOtp("");
    setNewPassword("");
    setConfirmPassword("");
    setShowNewPassword(false);
    setForgotPasswordStep("initial");
  };

  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <div className="candidate-login-page">
      <div className="candidate-login-left">
      <div 
          className="home-icon-container" 
          onClick={handleGoHome}
          title="Go to Home"
        >
          <Home 
            color="white" 
            size={32} 
            className="home-icon" 
          />
        </div>
        <div className="background-animation"></div>
        <div className="candidate-login-welcome-text">
          <h1>Welcome to Cirruscypher Community</h1>
        </div>
      </div>
      <div className="candidate-login-right">
        <div className="candidate-login-container">
          <h2 className="candidate-login-title">Welcome Back!</h2>
          <h1 className="candidate-login-header">Login to Your Account</h1>
          <form onSubmit={handleLogin} className="candidate-login-form">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="candidate-login-input-field"
              required
            />
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="candidate-login-input-field"
              required
            />
            <button
              type="submit"
              className="candidate-login-submit-button candidate-login-button"
            >
              Login
            </button>
            <p
              className="candidate-login-forgot-password-link"
              onClick={handleOpenForgotPasswordModal}
            >
              Forgot Password?
            </p>
          </form>

          {/* Forgot Password Modal */}
          <Dialog
            open={openForgotPasswordModal}
            onClose={handleCloseForgotPasswordModal}
          >
            <DialogTitle>Reset Password</DialogTitle>
            <DialogContent>
              {forgotPasswordStep === "initial" && (
                <>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="resetEmail"
                    label="Email"
                    type="email"
                    fullWidth
                    variant="standard"
                    value={resetEmail}
                    onChange={(e) => setResetEmail(e.target.value)}
                  />
                  <Button onClick={handleForgotPassword}>Verify Email</Button>
                </>
              )}
              {forgotPasswordStep === "otpSent" && (
                <>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="otp"
                    label="Enter OTP"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                  />
                  <Button onClick={handleVerifyOtp}>Verify OTP</Button>
                </>
              )}
              {forgotPasswordStep === "otpVerified" && (
                <>
                  <TextField
                    margin="dense"
                    id="newPassword"
                    label="New Password"
                    type={showNewPassword ? "text" : "password"}
                    fullWidth
                    variant="standard"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle new password visibility"
                            onClick={toggleNewPasswordVisibility}
                          >
                            {showNewPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    margin="dense"
                    id="confirmPassword"
                    label="Confirm Password"
                    type={showNewPassword ? "text" : "password"}
                    fullWidth
                    variant="standard"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  {passwordError && (
                    <p style={{ color: "red" }}>{passwordError}</p>
                  )}
                  <Button onClick={handleResetPassword}>Reset Password</Button>
                </>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseForgotPasswordModal}>Cancel</Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default CandidateLogin;
