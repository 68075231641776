import React,{ useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Paper,
  Typography,
  useTheme,
  Chip,
  CircularProgress,
  Container
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SettingsIcon from '@mui/icons-material/Settings';
import QuizIcon from '@mui/icons-material/Quiz';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CodeIcon from '@mui/icons-material/Code';
import dayjs from 'dayjs';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all 0.3s ease',
  borderRadius: theme.spacing(3),
  boxShadow: '0 8px 15px rgba(0,0,0,0.1)',
  cursor: 'pointer',
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: '0 12px 20px rgba(0,0,0,0.15)',
    '& .card-title': {
      color: '#1a73e8'
    }
  }
}));

const StatBox = ({ icon: Icon, label, value, theme }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      px: 3,
      py: 2,
      borderRight: `1px solid ${theme.palette.divider}`,
      '&:last-child': {
        borderRight: 'none'
      }
    }}
  >
    <Icon
      sx={{
        color: theme.palette.primary.main,
        fontSize: 24,
        mr: 1.5
      }}
    />
    <Box>
      <Typography
        variant="body2"
        color="text.secondary"
        sx={{
          fontSize: '0.75rem',
          fontWeight: 500,
          textTransform: 'uppercase',
          letterSpacing: '0.1em',
          mb: 0.5
        }}
      >
        {label}
      </Typography>
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: '1.1rem',
          color: theme.palette.text.primary
        }}
      >
        {value}
      </Typography>
    </Box>
  </Box>
);

const CandidateTestDetail = ({ 
    test, 
    onBack, 
    isGuestMode = false,
    onAttemptTestRedirect 
  }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [testId, setTestId] = useState("");
    const [error, setError] = useState("");
  

  // Fetch email and test ID
  useEffect(() => {
    const fetchCandidateDetails = () => {
      try {
        const storedEmail = localStorage.getItem("email");
        if (storedEmail) {
          setEmail(storedEmail);
        } else {
          setError("Email not found in localStorage");
        }
  
        if (test && test.testId) {
          setTestId(test.testId); // Use testId from the passed prop
        } else {
          setError("Test ID not found in props");
        }
      } catch (err) {
        console.error("Error fetching candidate details:", err);
        setError("Failed to fetch candidate details");
      }
    };
  
    fetchCandidateDetails();
  }, [test]); // Depend on the test prop

  const handleAttemptTest = async () => {
    if (isGuestMode) {
      // Trigger login modal/redirect
      onAttemptTestRedirect?.(test);
      return;
    }

    try {
      if (!test) {
        setError("Test data is not available. Please try again.");
        return;
      }
  
      console.log("Validating test timing...");
      const now = new Date();
      const testStart = new Date(test.testStartDateTime);
      const testEnd = new Date(test.testEndDateTime);
  
      if (now < testStart) {
        setError(`Test will start on ${testStart.toLocaleString()}`);
        return;
      }
  
      if (now > testEnd) {
        setError("Test has ended");
        return;
      }
  
      // Navigate to attempt test page with email and testId as query parameters
      navigate(`/attempt-test-page?email=${encodeURIComponent(email)}&testId=${testId}`);
    } catch (error) {
      console.error("Error attempting test:", error);
      setError("An unexpected error occurred while attempting the test");
    }
  };

    console.log('Received Test Data:', test);
  
    // Error Handling
    if (!test) {
      return (
        <Container maxWidth="md">
          <Box 
            display="flex" 
            flexDirection="column" 
            justifyContent="center" 
            alignItems="center" 
            minHeight="400px"
          >
            <Typography color="error" variant="h6" sx={{ mb: 2 }}>
              No test data received
            </Typography>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={onBack}
            >
              Back to Tests
            </Button>
          </Box>
        </Container>
      );
    }
  
    // Add more detailed error checking
    console.log('Test Title:', test.testTitle);
    console.log('Test Sections:', test.sections);
  
    if (!test.testTitle) {
      return (
        <Container maxWidth="md">
          <Box sx={{ px: 0 }}>
            <Button
              startIcon={<ArrowBackIcon />}
              onClick={onBack}
              sx={{ mb: 3 }}
              variant="outlined"
              color="primary"
            >
              Back to Tests
            </Button>
            <Typography color="error" variant="h6">
              Error: Test title is missing
            </Typography>
            <pre>{JSON.stringify(test, null, 2)}</pre>
          </Box>
        </Container>
      );
    }
  
  const calculateQuestionCounts = (section) => {
    if (!section) return { mcqCount: 0, codingCount: 0, totalCount: 0 };
    
    let mcqCount = 0;
    let codingCount = 0;

    mcqCount += section.mcqQuestionIds?.length || 0;
    codingCount += section.codingQuestionIds?.length || 0;

    section.groupIds?.forEach((group) => {
      if (group && group.questions) {
        group.questions.forEach((question) => {
          if (question.type === "mcq") mcqCount++;
          else if (question.type === "coding") codingCount++;
        });
      }
    });

    return { mcqCount, codingCount, totalCount: mcqCount + codingCount };
  };

  // Error handling for invalid test data
  if (!test.testTitle || !test.sections) {
    return (
      <Container maxWidth="md">
        <Box sx={{ px: 0 }}>
          <Button
            startIcon={<ArrowBackIcon />}
            onClick={onBack}
            sx={{ mb: 3 }}
            variant="outlined"
            color="primary"
          >
            Back to Tests
          </Button>
          <Typography color="error" variant="h6">
            Error: Invalid test data structure
          </Typography>
        </Box>
      </Container>
    );
  }

  // Sort sections
  const sortedSections = [...test.sections].sort((a, b) => {
    if (a.order !== undefined && b.order !== undefined) {
      return a.order - b.order;
    }
    return 0;
  });

 // Calculate total test duration from section durations
 const calculateTotalTestDuration = () => {
    if (!test || !test.sections || test.sections.length === 0) {
      return 0;
    }

    return test.sections.reduce((total, section) => {
      // Ensure sectionDuration is a number, defaulting to 0 if not
      const sectionDuration = Number(section.sectionDuration) || 0;
      return total + sectionDuration;
    }, 0);
  };

  // Calculate total test duration
  const totalTestDuration = calculateTotalTestDuration();

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box sx={{ px: 0 }}>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={onBack}
          sx={{ 
            mb: 3, 
            ml: 0, // Ensures left alignment
            '&:hover': {
              backgroundColor: 'rgba(25,118,210,0.1)'
            }
          }}
          variant="outlined"
          color="primary"
        >
          Back to Tests
        </Button>

        <Paper 
          elevation={4}
          sx={{
            borderRadius: 3,
            overflow: 'hidden',
            boxShadow: '0 10px 20px rgba(0,0,0,0.08)',
            transition: 'all 0.3s ease',
            '&:hover': {
              boxShadow: '0 15px 25px rgba(0,0,0,0.12)'
            }
          }}
        >
          <Box 
            sx={{ 
              backgroundColor: 'rgba(26,115,232,0.1)', 
              p: 4,
              borderBottom: '1px solid rgba(0,0,0,0.1)'
            }}
          >
            <Typography
              variant="h5"
              sx={{ 
                fontWeight: 700, 
                color: 'primary.main',
                borderBottom: '3px solid',
                pb: 1,
                mb: 2
              }}
            >
              {test.testTitle}
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ 
                lineHeight: 1.6 
              }}
            >
              {test.testDescription || 'No description available'}
            </Typography>
          </Box>

          <Box 
            sx={{ 
              px: 4, 
              py: 3, 
              display: 'flex', 
              borderBottom: `1px solid ${theme.palette.divider}` 
            }}
          >
             <StatBox
            icon={AccessTimeIcon}
            label="Duration"
            value={`${totalTestDuration || 'N/A'} min`}
            theme={theme}
          />
            <StatBox
              icon={SettingsIcon}
              label="Start Time"
              value={test.testStartDateTime ? dayjs(test.testStartDateTime).format('MMM D, YYYY h:mm A') : 'N/A'}
              theme={theme}
            />
            <StatBox
              icon={SettingsIcon}
              label="End Time"
              value={test.testEndDateTime ? dayjs(test.testEndDateTime).format('MMM D, YYYY h:mm A') : 'N/A'}
              theme={theme}
            />
          </Box>

          <Box sx={{ p: 4 }}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: 700,
                mb: 3,
                display: 'flex',
                alignItems: 'center',
                color: 'primary.main',
                borderBottom: '3px solid',
                pb: 1
              }}
            >
              <QuizIcon sx={{ mr: 2 }} />
              Test Sections
            </Typography>

            {sortedSections.map((section, index) => {
              const counts = calculateQuestionCounts(section);
              return (
                <StyledCard 
                  key={section.sectionId || index}
                  sx={{ 
                    mb: 3,
                    border: '1px solid rgba(0,0,0,0.1)'
                  }}
                >
                  <CardContent sx={{ p: 0 }}>
                    <Box 
                      sx={{ 
                        p: 3, 
                        borderBottom: `1px solid ${theme.palette.divider}`,
                        backgroundColor: 'rgba(26,115,232,0.05)'
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 700,
                          color: 'primary.main'
                        }}
                      >
                        {section.sectionName}
                      </Typography>
                    </Box>

                    <Box 
                      sx={{ 
                        display: 'flex', 
                        borderBottom: `1px solid ${theme.palette.divider}` 
                      }}
                    >
                      <StatBox
                        icon={AccessTimeIcon}
                        label="Duration"
                        value={`${section.sectionDuration || 'N/A'} min`}
                        theme={theme}
                      />
                      <StatBox
                        icon={AssignmentIcon}
                        label="Total Questions"
                        value={counts.totalCount}
                        theme={theme}
                      />
                      <StatBox
                        icon={QuizIcon}
                        label="MCQ Questions"
                        value={counts.mcqCount}
                        theme={theme}
                      />
                      <StatBox
                        icon={CodeIcon}
                        label="Coding Questions"
                        value={counts.codingCount}
                        theme={theme}
                      />
                    </Box>

                    {section.allowedLanguages && section.allowedLanguages.length > 0 && (
  <Box 
    sx={{ 
      p: 2, 
      display: 'flex', 
      justifyContent: 'flex-end', 
      alignItems: 'center' 
    }}
  >
    <Box sx={{ display: 'flex', gap: 1 }}>
      {section.allowedLanguages.map((lang, i) => (
        <Chip
          key={`${lang.languageId}-${i}`}
          label={lang.language}  // Change this to use lang.language
          size="small"
          color="primary"
          variant="outlined"
          sx={{
            fontWeight: 500
          }}
        />
      ))}
    </Box>
  </Box>
)}
                  </CardContent>
                </StyledCard>
              );
            })}

            <Box
              sx={{
                mt: 4,
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={handleAttemptTest}
                sx={{
                  minWidth: 200,
                  py: 1.5,
                  fontSize: '1.1rem',
                  fontWeight: 600,
                  borderRadius: 2,
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-3px)',
                    boxShadow: theme.shadows[6]
                  }
                }}
              >
                Attempt Test
              </Button>
             
            </Box>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};

export default CandidateTestDetail;