import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Menu, X } from 'lucide-react';
import '../css/Navbar.css';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    const storedUsername = localStorage.getItem('username');
    if (token && storedUsername) {
      setIsLoggedIn(true);
      setUsername(storedUsername || '');
    } else {
      setIsLoggedIn(false);
      setUsername('');
    }
  }, []);

  // Close menu when changing routes
  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const handleDashboardRedirect = () => {
    const role = localStorage.getItem('role');
    const token = localStorage.getItem('token');
  
    if (token) {
      if (role === '1') {
        // Admin dashboard
        navigate('/admin/dashboard');
      } else if (role === '2') {
        // Regular user dashboard
        navigate('/user/dashboard');
      } else {
        // For candidates or other roles
        navigate('/profilepage');
      }
    } else {
      // If no token, redirect to login
      navigate('/login');
    }
    
    closeMenu();
  };

  const isActive = (path) => {
    return location.pathname === path ? 'active-link' : '';
  };

  return (
    <div className="navbar-container">
      <nav className="navbar">
        <Link to="/" className="navbar-logo">
          <img src="/logos/cirruscypher-logo-alpha.png" alt="CirrusCypher Favicon Logo" className="favicon-logo" />
          <img src="/logos/cirruscypher-name-multi-alpha.png" alt="CirrusCypher Logo" className="logo-img" />
        </Link>

        <button className="menu-icon" onClick={toggleMenu} aria-label="Toggle menu">
          {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
        </button>

        <div className={`nav-overlay ${isMenuOpen ? 'show' : ''}`} onClick={closeMenu} />

        <ul className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
          <li><Link to="/" className={isActive('/')} onClick={closeMenu}>Home</Link></li>
          <li><Link to="/feature" className={isActive('/feature')} onClick={closeMenu}>Features</Link></li>
          <li><Link to="/subscription" className={isActive('/subscription')} onClick={closeMenu}>Pricing</Link></li>
          <li><Link to="/contact-info" className={isActive('/contact-info')} onClick={closeMenu}>Contact Us</Link></li>

          {isLoggedIn ? (
            <div className="welcome-strip">
            <span>
            <button onClick={handleDashboardRedirect} className="cta-button">
        Go to Dashboard
      </button>
            </span>
          </div>
        ) : (
          <li><Link to="/login" className={isActive('/login')} onClick={closeMenu}>Login</Link></li>
        )}
        </ul>
      </nav>
    </div>
  );
};

export const NavBar = Navbar;
export default Navbar;