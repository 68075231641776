import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Container,
  Paper,
  Stepper,
  Step,
  StepLabel,
  TextField,
  Grid,
  Switch,
  FormControlLabel,
  Divider,
  IconButton,
  RadioGroup,
  Radio,
} from "@mui/material";
import {
  ArrowBack as ArrowBackIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import TestSection from "./TestSection";

const steps = ["Test Details", "Sections & Questions"];

const CreateCombineTest = ({ onClose, onTestCreated, editTest }) => {
  const userId = localStorage.getItem("user_id");
  const token = localStorage.getItem("token");
  const BASE_URL = import.meta.env.VITE_BASE_URL;
  const [activeStep, setActiveStep] = useState(0);

  const [mcqQuestions, setMcqQuestions] = useState([]);
  const [codingQuestions, setCodingQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [testData, setTestData] = useState({
    title: "",
    description: "",
    duration: "",
    startDateTime: null,
    endDateTime: null,
    useProctor: false,
    allowSectionSelection: false,
    typeOfTest: "private", // New field with default value
  });

  const [sections, setSections] = useState([
    {
      id: 1,
      title: "Section 1",
      duration: "",
      shuffleQuestions: true,
      allowNavigation: true,
      allowedLanguages: [],
      selectedQuestions: {
        coding: [],
        mcq: [],
      },
      selectedGroups: [],
    },
  ]);

  // Track which questions are used across all sections
  const [usedQuestions, setUsedQuestions] = useState({
    coding: new Set(),
    mcq: new Set(),
  });

  const [isEditMode] = useState(!!editTest);

  useEffect(() => {
    if (editTest && !isLoading) {
      setTestData({
        title: editTest.testTitle,
        description: editTest.testDescription,
        duration: editTest.duration,
        startDateTime: dayjs(editTest.testStartDateTime),
        endDateTime: dayjs(editTest.testEndDateTime),
        useProctor: editTest.allowAdvancedProctor,
        allowSectionSelection: editTest.allowCandidateSectionSelection,
        typeOfTest: editTest.typeOfTest || 'private', // Add this line
      });

      const transformedSections = editTest.sections.map((section, index) => ({
        id: section.sectionId,
        title: section.sectionName,
        duration: section.sectionDuration,
        shuffleQuestions: section.allowShuffleQuestion,
        allowNavigation: section.allowQuestionNavigation,
        allowedLanguages: section.allowedLanguages.map((lang) => ({
          languageId: lang.languageId,
          language: lang.language,
        })),
        selectedQuestions: {
          coding: [],
          mcq: [],
        },
        selectedGroups: section.groupIds || [],
      }));

      // Populate selected questions
      transformedSections.forEach((section, index) => {
        const originalSection = editTest.sections[index];

        if (originalSection.mcqQuestionIds) {
          section.selectedQuestions.mcq = mcqQuestions.filter((q) =>
            originalSection.mcqQuestionIds.includes(q.mcq_question_id)
          );
        }

        if (originalSection.codingQuestionIds) {
          section.selectedQuestions.coding = codingQuestions.filter((q) =>
            originalSection.codingQuestionIds.includes(q.id)
          );
        }
      });

      setSections(transformedSections);
    }
  }, [editTest, isLoading, mcqQuestions, codingQuestions]);

  // function to fetch questions
  const fetchQuestions = async () => {
    setIsLoading(true);
    try {
      // Fetch MCQ questions
      const mcqResponse = await axios.get(
        `${BASE_URL}/api/org/question/fetch-questions/${userId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (mcqResponse.data.data.questions) {
        setMcqQuestions(
          mcqResponse.data.data.questions.map((q) => ({
            ...q,
            type: "mcq",
          }))
        );
      }

      // Fetch coding questions
      const codingResponse = await axios.post(
        `${BASE_URL}/get-all-questions-content-ud`,
        { created_by: userId }
      );
      setCodingQuestions(
        codingResponse.data.map((q) => ({
          ...q,
          type: "coding",
        }))
      );
    } catch (error) {
      console.error("Error fetching questions:", error);
      toast.error("Failed to fetch questions");
    } finally {
      setIsLoading(false);
    }
  };

  // Initial fetch of questions
  useEffect(() => {
    fetchQuestions();
  }, []);

  const isQuestionFromSelectedGroup = (question, sectionGroups = []) => {
    const questionId =
      question.type === "coding" ? question.id : question.mcq_question_id;
    return sectionGroups.some((group) =>
      group.questions?.some(
        (gq) => gq.type === question.type && gq.questionId === questionId
      )
    );
  };

  const handleSubmit = async () => {
    try {
      const formatDateForMySQL = (date) => {
        if (!date) return null;
        return dayjs(date).format("YYYY-MM-DD HH:mm:ss");
      };
  
      // Base request data structure
      const baseRequestData = {
        testTitle: testData.title,
        testDescription: testData.description,
        testStartDateTime: formatDateForMySQL(testData.startDateTime),
        testEndDateTime: formatDateForMySQL(testData.endDateTime),
        allowAdvancedProctor: Boolean(testData.useProctor),
        allowCandidateSectionSelection: Boolean(testData.allowSectionSelection),
        typeOfTest: testData.typeOfTest,
        sections: sections.map((section) => ({
          sectionName: section.title,
          sectionDuration: parseInt(section.duration),
          allowShuffleQuestion: Boolean(section.shuffleQuestions),
          allowQuestionNavigation: Boolean(section.allowNavigation),
          allowedLanguages: section.allowedLanguages.map((lang) =>
            typeof lang === "string" ? lang : lang.languageId
          ), // Map to only include language IDs
          groupIds: (section.selectedGroups || []).map((g) => ({
            groupId: g.groupId,
            questions: g.questions || [],
          })),
          mcqQuestionIds: section.selectedQuestions.mcq
            .filter(
              (q) =>
                !isQuestionFromSelectedGroup(q, section.selectedGroups || [])
            )
            .map((q) => q.mcq_question_id),
          codingQuestionIds: section.selectedQuestions.coding
            .filter(
              (q) =>
                !isQuestionFromSelectedGroup(q, section.selectedGroups || [])
            )
            .map((q) => q.id),
        })),
      };
  
      let requestData;
      if (isEditMode) {
        // For edit mode, include testId and sectionIds
        requestData = {
          ...baseRequestData,
          testId: editTest.testId,
          deletedSectionIds: getDeletedSectionIds(),
          sections: baseRequestData.sections.map((section, index) => ({
            ...section,
            sectionId: sections[index].id, // Include sectionId for existing sections
          })),
        };
      } else {
        // For create mode, include createdBy
        requestData = {
          ...baseRequestData,
          createdBy: userId,
        };
      }
  
      // Log the payload for debugging
      console.log("Request Payload:", requestData);
  
      const url = isEditMode
        ? `${BASE_URL}/api/org/tests/edit-test`
        : `${BASE_URL}/api/org/tests/create-test`;
  
      const response = await axios[isEditMode ? "put" : "post"](
        url,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      if (response.data.success) {
        toast.success(
          `Test ${isEditMode ? "updated" : "created"} successfully`
        );
        onTestCreated(response.data);
      }
    } catch (error) {
      console.error(
        `Error ${isEditMode ? "updating" : "creating"} test:`,
        error
      );
      toast.error(
        error.response?.data?.message ||
          error.response?.data?.error ||
          `Failed to ${isEditMode ? "update" : "create"} test`
      );
    }
  };
  
  // Helper function to get deleted section IDs
  const getDeletedSectionIds = () => {
    if (!editTest) return [];
    const currentSectionIds = new Set(sections.map((s) => s.id));
    return editTest.sections
      .map((s) => s.sectionId)
      .filter((id) => !currentSectionIds.has(id));
  };

  const handleNext = () => {
    if (activeStep === 0) {
      // Validate first step
      if (
        !testData.title ||
        !testData.startDateTime ||
        !testData.endDateTime
      ) {
        toast.error("Please fill all required fields");
        return;
      }
    }
    setActiveStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const handleAddSection = () => {
    setSections((prev) => [
      ...prev,
      {
        id: prev.length + 1,
        title: `Section ${prev.length + 1}`,
        duration: "",
        shuffleQuestions: false,
        allowNavigation: true,
        allowedLanguages: [],
        selectedQuestions: {
          coding: [],
          mcq: [],
        },
      },
    ]);
  };

  const handleDeleteSection = (sectionId) => {
    setSections((prev) => {
      const newSections = prev.filter((section) => section.id !== sectionId);
      // Update section titles
      return newSections.map((section, index) => ({
        ...section,
        title: `Section ${index + 1}`,
      }));
    });
  };

  const handleUpdateSection = (sectionId, updatedData) => {
    setSections((prev) =>
      prev.map((section) =>
        section.id === sectionId ? { ...section, ...updatedData } : section
      )
    );
  };

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <Paper sx={{ p: 3, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Test Title"
                  value={testData.title}
                  onChange={(e) =>
                    setTestData({ ...testData, title: e.target.value })
                  }
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Test Description"
                  multiline
                  rows={4}
                  value={testData.description}
                  onChange={(e) =>
                    setTestData({ ...testData, description: e.target.value })
                  }
                  required
                />
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Total Duration (minutes)"
                  type="number"
                  value={testData.duration}
                  onChange={(e) =>
                    setTestData({ ...testData, duration: e.target.value })
                  }
                  required
                />
              </Grid> */}
              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label="Start Date & Time"
                    value={testData.startDateTime}
                    onChange={(newValue) =>
                      setTestData({ ...testData, startDateTime: newValue })
                    }
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        required: true,
                      },
                    }}
                    format="YYYY-MM-DD HH:mm:ss"
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label="End Date & Time"
                    value={testData.endDateTime}
                    onChange={(newValue) =>
                      setTestData({ ...testData, endDateTime: newValue })
                    }
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        required: true,
                      },
                    }}
                    format="YYYY-MM-DD HH:mm:ss"
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ my: 2 }} />
                <Typography variant="h6" gutterBottom>
                  Additional Settings
                </Typography>
                
                <Grid item xs={12}>
    <Typography variant="subtitle1" gutterBottom>
      Test Visibility
    </Typography>
    <RadioGroup
      row
      value={testData.typeOfTest}
      onChange={(e) =>
        setTestData({
          ...testData,
          typeOfTest: e.target.value,
        })
      }
    >
      <FormControlLabel
        value="private"
        control={<Radio />}
        label="Private (Invite Only)"
        sx={{
          '& .MuiSvgIcon-root': {
            color: testData.typeOfTest === 'private' ? 'primary.main' : 'grey.500',
          },
        }}
      />
      <FormControlLabel
        value="public"
        control={<Radio />}
        label="Public (Discoverable)"
        sx={{
          '& .MuiSvgIcon-root': {
            color: testData.typeOfTest === 'public' ? 'primary.main' : 'grey.500',
          },
        }}
      />
    </RadioGroup>
    <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
      {testData.typeOfTest === 'private'
        ? 'Only invited candidates can access this test.'
        : 'This test will be discoverable to all candidates.'}
    </Typography>
  </Grid>
                
 
                <FormControlLabel
                  control={
                    <Switch
                      checked={testData.useProctor}
                      onChange={(e) =>
                        setTestData({
                          ...testData,
                          useProctor: e.target.checked,
                        })
                      }
                    />
                  }
                  label="Add Advanced Proctor"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={testData.allowSectionSelection}
                      onChange={(e) =>
                        setTestData({
                          ...testData,
                          allowSectionSelection: e.target.checked,
                        })
                      }
                    />
                  }
                  label="Allow candidate to select section"
                />
              </Grid>
            </Grid>
          </Paper>
        );
      case 1:
        return (
          <Box>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleAddSection}
              sx={{ mb: 3 }}
            >
              Add Section
            </Button>

            {sections.map((section, index) => (
              <TestSection
                key={section.id}
                section={section}
                onUpdate={(data) => handleUpdateSection(section.id, data)}
                onDelete={() => handleDeleteSection(section.id)}
                usedQuestions={usedQuestions}
                setUsedQuestions={setUsedQuestions}
                isLastSection={index === sections.length - 1}
                canDelete={sections.length > 1}
                allSections={sections}
              />
            ))}
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Container maxWidth="xl">
      <Box sx={{ py: 4 }}>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={onClose}
          sx={{ mb: 4, display: "flex", justifyContent: "flex-start" }}
        >
          Back to Tests
        </Button>

        <Typography variant="h4" gutterBottom>
          {isEditMode ? "Edit Test" : "Create New Test"}
        </Typography>

        <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {renderStepContent()}

        <Box
          sx={{ display: "flex", justifyContent: "flex-end", mt: 3, gap: 2 }}
        >
          {activeStep > 0 && <Button onClick={handleBack}>Back</Button>}
          {activeStep === steps.length - 1 ? (
            <Button variant="contained" onClick={handleSubmit}>
              {isEditMode ? "Update Test" : "Create Test"}
            </Button>
          ) : (
            <Button variant="contained" onClick={handleNext}>
              Next
            </Button>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default CreateCombineTest;
