// QuestionGroupViewModal.jsx
import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Box,
  Chip,
} from '@mui/material';

const QuestionGroupViewModal = ({ open, onClose, group }) => {
  if (!group || !group.groupData) return null;

  const mcqCount = group.questions?.filter(q => q.type === 'mcq').length || 0;
  const codingCount = group.questions?.filter(q => q.type === 'coding').length || 0;
  const totalQuestions = group.questions?.length || 0;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ borderBottom: 1, borderColor: 'divider' }}>
        Group Details
      </DialogTitle>
      <DialogContent sx={{ mt: 2 }}>
        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            {group.groupData.group_name}
          </Typography>
          <Typography variant="body1" color="text.secondary" paragraph>
            {group.groupData.group_description}
          </Typography>
        </Box>
        
        <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
          <Chip
            label={`Total Questions: ${totalQuestions}`}
            color="primary"
            variant="outlined"
          />
          <Chip
            label={`MCQ Questions: ${mcqCount}`}
            color="secondary"
            variant="outlined"
          />
          <Chip
            label={`Coding Questions: ${codingCount}`}
            color="info"
            variant="outlined"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QuestionGroupViewModal;