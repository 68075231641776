import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  Grid,
  styled,
  InputAdornment,
  IconButton,
  Card,
  CardContent,
  Container,
  Alert,
  Fade,
  CircularProgress,
  Divider,
  Avatar,
  Tooltip
} from '@mui/material';
import {
  Save as SaveIcon,
  Visibility,
  VisibilityOff,
  Email as EmailIcon,
  Lock as LockIcon,
  Person as PersonIcon,
  CloudUpload as CloudUploadIcon,
  AccountCircle as AccountIcon,
  Security as SecurityIcon,
  Notifications as NotificationIcon,
  Help as HelpIcon
} from '@mui/icons-material';
import axios from 'axios';

// Styled components for consistency and professional look
const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  borderRadius: 16,
  boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 6px 16px rgba(0,0,0,0.15)',
  },
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(4),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: 25,
  textTransform: 'none',
  padding: theme.spacing(1, 3),
  fontWeight: 600,
  boxShadow: 'none',
  '&:hover': {
    boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
  },
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.primary.main,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const VisualDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(3, 0),
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 120,
  height: 120,
  border: `4px solid ${theme.palette.primary.main}`,
  boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
  margin: '0 auto',
  cursor: 'pointer',
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'scale(1.05)',
  },
}));

const CandidateSetting = ({ currentUsername, onUsernameChange }) => {
  const [username, setUsername] = useState(currentUsername || '');
  const [currentEmail, setCurrentEmail] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState({ type: '', message: '' });
  const [avatarUrl, setAvatarUrl] = useState('/default-avatar.png');

  const BASE_URL = import.meta.env.VITE_BASE_URL;
  const token = localStorage.getItem('token');

  // Password strength indicator
  const getPasswordStrength = (password) => {
    if (!password) return { strength: 0, label: '' };
    const strength = 
      (password.length >= 8) +
      /[A-Z]/.test(password) +
      /[a-z]/.test(password) +
      /[0-9]/.test(password) +
      /[^A-Za-z0-9]/.test(password);
    
    const labels = ['Very Weak', 'Weak', 'Fair', 'Good', 'Strong'];
    return {
      strength: (strength / 5) * 100,
      label: labels[strength - 1] || ''
    };
  };

  const passwordStrength = getPasswordStrength(newPassword);

  const showFeedback = (type, message) => {
    setFeedback({ type, message });
    setTimeout(() => setFeedback({ type: '', message: '' }), 5000);
  };

  const handleUsernameSave = async () => {
    setLoading(true);
    try {
      await axios.post(
        `${BASE_URL}/update-username`,
        { username },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      showFeedback('success', 'Username updated successfully!');
      onUsernameChange(username);
    } catch (error) {
      showFeedback('error', error.response?.data?.message || 'Failed to update username');
    } finally {
      setLoading(false);
    }
  };

  const handleEmailVerification = async () => {
    setLoading(true);
    try {
      await axios.post(
        `${BASE_URL}/verify-new-email`,
        { newEmail },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      showFeedback('success', 'Verification email sent! Please check your inbox.');
      setEmailVerified(true);
    } catch (error) {
      showFeedback('error', 'Failed to send verification email. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordChange = async () => {
    if (newPassword !== confirmPassword) {
      showFeedback('error', 'New passwords do not match!');
      return;
    }
    
    setLoading(true);
    try {
      await axios.post(
        `${BASE_URL}/change-password`,
        { currentPassword, newPassword },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      showFeedback('success', 'Password changed successfully!');
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
    } catch (error) {
      showFeedback('error', error.response?.data?.message || 'Failed to change password');
    } finally {
      setLoading(false);
    }
  };

  const handleAvatarUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => setAvatarUrl(e.target.result);
      reader.readAsDataURL(file);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper 
        elevation={0}
        sx={{
          p: 4,
          borderRadius: 3,
          backgroundColor: 'background.default'
        }}
      >
        <Box sx={{ textAlign: 'center', mb: 4 }}>
          <Typography variant="h4" sx={{ fontWeight: 700, mb: 1 }}>
            Account Settings
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Manage your account settings and preferences
          </Typography>
        </Box>

        {feedback.message && (
          <Fade in={!!feedback.message}>
            <Alert 
              severity={feedback.type} 
              sx={{ mb: 3, borderRadius: 2 }}
              onClose={() => setFeedback({ type: '', message: '' })}
            >
              {feedback.message}
            </Alert>
          </Fade>
        )}

        <Grid container spacing={4}>
          {/* Profile Picture Section */}
          <Grid item xs={12} md={4}>
            <StyledCard>
              <StyledCardContent sx={{ textAlign: 'center' }}>
                <input
                  type="file"
                  accept="image/*"
                  id="avatar-upload"
                  style={{ display: 'none' }}
                  onChange={handleAvatarUpload}
                />
                <label htmlFor="avatar-upload">
                  <StyledAvatar src={avatarUrl} />
                </label>
                <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                  {username || 'Your Name'}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                  {currentEmail || 'your.email@example.com'}
                </Typography>
                <Button
                  component="label"
                  htmlFor="avatar-upload"
                  variant="outlined"
                  startIcon={<CloudUploadIcon />}
                  sx={{ borderRadius: 20 }}
                >
                  Update Photo
                </Button>
              </StyledCardContent>
            </StyledCard>
          </Grid>

          <Grid item xs={12} md={8}>
            {/* Username Section */}
            <StyledCard>
              <StyledCardContent>
                <SectionTitle variant="h6">
                  <AccountIcon />
                  Profile Information
                </SectionTitle>
                <VisualDivider />
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <StyledButton
                              variant="contained"
                              color="primary"
                              onClick={handleUsernameSave}
                              disabled={loading}
                              startIcon={loading ? <CircularProgress size={20} /> : <SaveIcon />}
                            >
                              Save Changes
                            </StyledButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </StyledCardContent>
            </StyledCard>

            {/* Email Section */}
            <StyledCard>
              <StyledCardContent>
                <SectionTitle variant="h6">
                  <EmailIcon />
                  Email Settings
                </SectionTitle>
                <VisualDivider />
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Current Email"
                      value={currentEmail}
                      disabled
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailIcon color="action" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="New Email"
                      value={newEmail}
                      onChange={(e) => setNewEmail(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <StyledButton
                              variant="contained"
                              color="primary"
                              onClick={handleEmailVerification}
                              disabled={loading || emailVerified}
                            >
                              {emailVerified ? 'Verified' : 'Verify'}
                            </StyledButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </StyledCardContent>
            </StyledCard>

            {/* Password Section */}
            <StyledCard>
              <StyledCardContent>
                <SectionTitle variant="h6">
                  <SecurityIcon />
                  Security Settings
                </SectionTitle>
                <VisualDivider />
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Current Password"
                      type={showPassword ? 'text' : 'password'}
                      value={currentPassword}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setShowPassword(!showPassword)}>
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="New Password"
                      type={showPassword ? 'text' : 'password'}
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      helperText={`Password strength: ${passwordStrength.label}`}
                      FormHelperTextProps={{
                        sx: { color: passwordStrength.strength > 60 ? 'success.main' : 'warning.main' }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Confirm New Password"
                      type={showPassword ? 'text' : 'password'}
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      error={confirmPassword && newPassword !== confirmPassword}
                      helperText={confirmPassword && newPassword !== confirmPassword ? 'Passwords do not match' : ' '}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ mt: 2 }}>
                      <StyledButton
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handlePasswordChange}
                        disabled={loading || !currentPassword || !newPassword || !confirmPassword}
                        startIcon={loading ? <CircularProgress size={20} /> : <LockIcon />}
                      >
                        Update Password
                      </StyledButton>
                    </Box>
                  </Grid>
                </Grid>
              </StyledCardContent>
            </StyledCard>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default CandidateSetting;