import React from "react";
import "./App.css";
import Landing from "./components/TestEnvironment/Landing";
import ContactInfo from "./components/ContactInfo";
// import ContactQueryForm from "./components/ContactQueryForm";
import Instruction from "./components/TestEnvironment/Instruction";
import AdminDashboard from "./components/AdminDashboard/AdminDashboard";
// import QuestionUi from "./components/QuestionUI";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify"; // Import the ToastContainer
import "react-toastify/dist/ReactToastify.css"; // Import toastify CSS

import UserDashboard from "./components/OrgDashboard/UserDashboard";
import CreateTest from "./components/OrgDashboard/CreateTest";
import TestView from "./components/OrgDashboard/TestView";
import EditTest from "./components/OrgDashboard/EditTest";
import ManageParticipants from "./components/OrgDashboard/ManageParticipants";
import AttemptTest from "./components/TestEnvironment/AttemptTest";
import ThankYou from "./components/ThankYou";
import HomePage from "./pages/HomePage";
import WhyCodeEditor from "./components/WhyCodeEditor";
import Feature from "./components/Feature";
import LoginPage from "./pages/LoginPage";
import SignupPage from "./pages/SignupPage";
import FeaturePage from "./pages/FeaturePage";
import GetStarted from "./components/GetStarted";
import Scorecard from "./components/OrgDashboard/Scorecard";
import PaymentComponent from "./components/PaymentComponent";
import ProtectedRoute from "./components/ProtectedRoute";
import ProtectedTestRoute from "./components/ProtectedTestRoute";
import FAQ from "./components/FAQ";
import OurWorkDomain from "./components/OurWorkDomain";
import EditQuestionAndTestcases from "./components/OrgDashboard/EditQuestionAndTestcases";
import ContactUsPage from "./pages/ContactUsPage";
import MultipartUpload from "./components/TestEnvironment/MultipartUpload ";
import A_Practice from "./components/A_Practice";
import CandidateSignup from "./components/CandidateProfile/CandidateSignup";
import CandidateLogin from "./components/CandidateProfile/CandidateLogin";
import AdminCreateTest from "./components/AdminDashboard/AdminCreateTest";
import AdminQuestions from "./components/AdminDashboard/AdminQuestions";
import AdminEditQuestionAndTestcases from "./components/AdminDashboard/AdminEditQuestionAndTestcases";
import AdminEditTest from "./components/AdminDashboard/AdminEditTest";
import AdminViewTest from "./components/AdminDashboard/AdminViewTest";
import AdminBulkCreateTests from "./components/AdminDashboard/AdminBulkCreateTests";
import PoliciesPage from "./pages/PoliciesPage";
import RazorpaySubscription from "./components/RazorpayTestComponent";
import BlogsPage from "./pages/BlogsPage";
import BlogsRendering from "./components/Blogs/BlogsRendering";
import Guides from "./components/Guides/Guides"; // Import the Guides component
import GuideDetail from "./components/Guides/GuideDetail"; // Import the GuideDetail component
import Documentation from "./components/Documentation/Documentation";
import CompanyPreparation from "./components/Preparation/CompanyPreparation";
import VideoPlayer from "./components/VideoTutorial/VideoPlayer"; // Adjust the path as needed
import MCQTestScreen from "./components/TestEnvironment/MCQTestView/MCQTestScreen";
import MCQTestSubmitted from "./components/TestEnvironment/MCQTestView/MCQTestSubmitted";
import CandidateTestDetail from "./components/CandidateProfile/CandidateTestDetail";
import CombineSectionSelection from "./components/TestEnvironment/CombineTest/CombineSectionSelection";
import CombineTestPage from "./components/TestEnvironment/CombineTest/CombineTestPage";
import GuestCandidateDashboard from './components/CandidateProfile/GuestCandidateDashboard';
import CandidateDashboard from "./components/CandidateProfile/CandidateDashboard";

function App() {
  return (
    <Router>
      <div className="App">
        {/* ToastContainer component to render toast notifications */}
        <ToastContainer />

        <Routes>
          {/* <Route path="/component1" element={<PROCTOR_1 />} /> */}
          {/* <Route path="/component2" element={<PROCTOR_2 />} /> */}

          {/* <Route path="/practice" element={<A_Practice />} /> */}
          {/* <Route path="/trial" element={<MultipartUpload />} /> */}

          {/* <Route path="/subscription" element={<RazorpaySubscription />} /> */}

          <Route path="/" element={<HomePage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/candidate-signup" element={<CandidateSignup />} />

          <Route path="/login" element={<LoginPage />} />
          <Route path="/candidate-login" element={<CandidateLogin />} />
          <Route path="/profilepage" element={<CandidateDashboard/>} />
          <Route path= "/guest-candidate-dashboard" element={<GuestCandidateDashboard />} />

         
          <Route path="/documentation" element={<Documentation />} />
          <Route path="/preparation" element={<CompanyPreparation />} />
          <Route path="/tutorials" element={<VideoPlayer />} />

          <Route
            path="/instruction"
            element={<ProtectedTestRoute Component={Instruction} />}
          />
          <Route path="/contact-us" element={<ContactUsPage />} />
          <Route path="/contact-info" element={<ContactInfo />} />
          {/* <Route path="/contact-query-form" element={<ContactQueryForm  />} /> */}
          <Route path="/feature" element={<FeaturePage />} />
          <Route path="/get-started" element={<GetStarted />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/scorecard/:testId" element={<Scorecard />} />
          <Route path="/subscription" element={<PaymentComponent />} />
          <Route path="/our-work" element={<OurWorkDomain />} />

          <Route
            path="/test-details/:testId"
            element={<CandidateTestDetail />}
          />

          <Route path="/policy-page/:policy" element={<PoliciesPage />} />
          {/* Default route */}
          <Route path="/policy-page" element={<PoliciesPage />} />

          <Route
            path="/landing/:questionId"
            element={<ProtectedTestRoute Component={Landing} />}
          />
          <Route
            path="/admin/dashboard"
            element={<ProtectedRoute Component={AdminDashboard} />}
          />

          <Route path="/admin-questions" element={<AdminQuestions />} />

          <Route path="/blogs" element={<BlogsPage />} />
          <Route path="/blog/:title" element={<BlogsRendering />} />

          {/* Define the route for the main Guides list */}
          <Route path="/guides" element={<Guides />} />

          {/* Define the route for individual Guide details */}
          <Route path="/guides/:id" element={<GuideDetail />} />

          <Route
            path="/admincreatetest"
            element={<ProtectedRoute Component={AdminCreateTest} />}
          />
          <Route
            path="/admin-edit-test"
            element={<ProtectedRoute Component={AdminEditTest} />}
          />

          <Route path="/admin-view-test/:testId" element={<AdminViewTest />} />

          {/* <Route path="/questions" element={<QuestionUi />} /> */}

          <Route
            path="/user/dashboard"
            element={<ProtectedRoute Component={UserDashboard} />}
          />

          <Route
            path="/create-test/:test_id"
            element={<ProtectedRoute Component={CreateTest} />}
          />
          <Route
            path="/test/:testId/view-questions"
            element={<ProtectedRoute Component={TestView} />}
          />
          <Route
            path="/edit-test/:testId"
            element={<ProtectedRoute Component={EditTest} />}
          />
          <Route
            path="/manage-participants/:testId"
            element={<ProtectedRoute Component={ManageParticipants} />}
          />
          <Route path="/attempt-test-page" element={<AttemptTest />} />
          <Route
            path="/thank-you"
            element={<ProtectedTestRoute Component={ThankYou} />}
          />
          <Route path="/WhyCodeEditor" element={<WhyCodeEditor />} />
          <Route path="/Feature" element={<Feature />} />
          <Route
            path="/edit-question/:questionId"
            element={<EditQuestionAndTestcases />}
          />
          <Route
            path="/admin-edit-question/:questionId"
            element={<AdminEditQuestionAndTestcases />}
          />
          <Route
            path="/admin/create-bulk-tests"
            element={<AdminBulkCreateTests />}
          />

          <Route path="/mcq-test" element={<MCQTestScreen />} />
          {/* <Route path="/mcq-test-submitted" element={<MCQTestSubmitted />} /> */}
          <Route path="/test-submitted" element={<MCQTestSubmitted />} />
          <Route
            path="/section-selection"
            element={<CombineSectionSelection />}
          />
          <Route path="/test-section" element={<CombineTestPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
