import React, { useState, useRef, useEffect } from 'react';
import { FaUserCircle, FaSignOutAlt, FaEdit } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const UserDashboardHeader = ({ organizationName, userName, onEditProfile }) => {
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleLogout = () => {
    setShowDropdown(false);
    navigate('/login');
  };

  const handleProfileClick = () => {
    setShowDropdown(!showDropdown);
  };

  const handleEditProfile = () => {
    setShowDropdown(false);
    if (onEditProfile) {
      onEditProfile();
    }
  };

  return (
    <div className="w-full flex justify-between items-center bg-gray-800 text-white px-8 py-4 shadow-md h-16">
      <div>
        <h1 className="text-xl font-semibold m-0">{organizationName} | TechnoCirrus Solutions</h1>
      </div>
      
      <div className="relative" ref={dropdownRef}>
        <div 
          className="flex items-center cursor-pointer"
          onClick={handleProfileClick}
        >
          <FaUserCircle className="text-2xl hover:text-gray-300 transition-colors" />
        </div>
        
        {showDropdown && (
          <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-50">
            <button
              onClick={handleEditProfile}
              className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition-colors"
            >
              <FaEdit className="mr-2" />
              Edit Profile
            </button>
            <button
              onClick={handleLogout}
              className="flex items-center w-full px-4 py-2 text-sm text-red-600 hover:bg-gray-100 transition-colors"
            >
              <FaSignOutAlt className="mr-2" />
              Logout
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserDashboardHeader;